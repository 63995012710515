/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";
import {
	type FC,
} from "react";

import {
	ReactComponent as noResultFavoriteIcon,
} from "images/project-navigation-no-results-favorite-placeholder.svg";
import {
	projectStructureApi,
} from "models/project-structure/api";
import {
	type ProjectName,
} from "models/projects/types";

import {
	NoResultsBlock,
} from "../no-results-block/no-results-block";
import {
	ProjectStructureBlocker,
} from "../project-structure-blocker/project-structure-blocker";
import {
	ProjectNavigationFavorite,
} from "./project-navigation-favorite";

interface ProjectNavigationFavoriteContainerProps {
	projectName: ProjectName;
	closeNavigationSidebar: () => void;
}

const ProjectNavigationFavoriteContainer: FC<ProjectNavigationFavoriteContainerProps> = ({
	projectName,
	closeNavigationSidebar,
}) => {
	const {
		data: favoritesData = [],
		isFetching,
	} = projectStructureApi.useGetFavoritesProjectStructureQuery(undefined);

	if (isFetching) {
		return <ProjectStructureBlocker/>;
	}

	if (isEmpty(favoritesData)) {
		return (
			<NoResultsBlock
				content={(
					<>
						Elements you mark as favorite on the
						{" "}
						<strong>
							Structure
						</strong>
						{" "}
						tab are shown here
					</>
				)}
				icon={noResultFavoriteIcon}
			/>
		);
	}

	return (
		<ProjectNavigationFavorite
			units={favoritesData}
			projectName={projectName}
			closeNavigationSidebar={closeNavigationSidebar}
		/>
	);
};

export {
	ProjectNavigationFavoriteContainer,
};
