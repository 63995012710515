/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	VirtualList,
} from "@epam/loveship";
import {
	uuiScrollShadows,
} from "@epam/uui-core";
import classNames from "classnames";
import map from "lodash/map";
import {
	type ComponentProps,
	type FC,
	useCallback,
} from "react";

import {
	type NavigationDataColumns,
	type NavigationDataRow,
	type NavigationDataSourceState,
} from "components/projects-billing/components/header/project-navigation/types";
import {
	ReactComponent as NoResultsIcon,
} from "images/project-navigation-no-results-structure-placeholder.svg";
import {
	type ProjectStructureUnitId,
} from "models/project-structure/types";
import {
	type ProjectName,
} from "models/projects/types";

import {
	TABLE_STRUCTURE_ELEMENT_ID,
} from "../../constants";
import {
	NoResultsBlock,
} from "../no-results-block/no-results-block";
import {
	ProjectRow,
} from "./components/project-row/project-row";

import styles from "./project-list.module.css";

interface ProjectListProps {
	projectName: ProjectName;
	dataSourceState: NavigationDataSourceState;
	onDataSourceStateChange: (value: NavigationDataSourceState) => void;
	columns: NavigationDataColumns;
	getRows: () => NavigationDataRow[];
	exactRowsCount?: number;
	rowsCount?: number;
	favoriteIds?: ProjectStructureUnitId[];
}

const ProjectList: FC<ProjectListProps> = ({
	projectName,
	dataSourceState,
	onDataSourceStateChange,
	columns,
	getRows,
	exactRowsCount,
	rowsCount,
	favoriteIds,
}) => {
	const rows = map(getRows(), (row) => {
		return (
			<ProjectRow
				key={row.id}
				row={row}
				columns={columns}
				projectName={projectName}
				favoriteIds={favoriteIds}
			/>
		);
	});

	const hasRows = exactRowsCount !== 0;

	const renderRowsContainer = useCallback<Required<ComponentProps<typeof VirtualList>>["renderRows"]>(
		({
			listContainerRef,
			estimatedHeight,
			offsetY,
			scrollShadows,
		}) => {
			return (
				<>
					<div>
						<div
							id="rows-container"
							className={
								classNames(
									uuiScrollShadows.top,
									{
										[uuiScrollShadows.topVisible]: scrollShadows.verticalTop,
									},
								)
							}
						/>
					</div>
					{
						hasRows
							? (
								<div
									className={styles.container}
									style={{
										minHeight: `${estimatedHeight - offsetY}px`,
									}}
								>
									<div
										ref={listContainerRef}
										role="rowgroup"
										style={{
											marginTop: offsetY,
										}}
									>
										{rows}
									</div>
								</div>
							)
							: (
								<NoResultsBlock
									content="No results found"
									icon={NoResultsIcon}
								/>
							)
					}
				</>
			);
		},
		[
			hasRows,
			rows,
		],
	);

	return (
		<VirtualList
			value={dataSourceState}
			onValueChange={onDataSourceStateChange}
			rows={rows}
			rowsCount={rowsCount}
			renderRows={renderRowsContainer}
			rawProps={{
				role: "table",
				id: TABLE_STRUCTURE_ELEMENT_ID,
				"aria-colcount": columns.length,
				"aria-rowcount": rowsCount,
			}}
		/>
	);
};

export {
	ProjectList,
};
