/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import styles from "./vacation-tooltip-body.module.css";

const VacationTooltipBody: FC = () => {
	return (
		<div className={styles.vacationTooltipBodyContainer}>
			<div className={styles.vacationTooltipBodyRow}>
				<div className={styles.vacationTooltipBodyText}>
					Hours of taken leave are colored orange.
				</div>
				<div className={styles.actualLeaveCell}>
					8
				</div>
			</div>
			<div className={styles.vacationTooltipBodyRow}>
				<div className={styles.vacationTooltipBodyText}>
					Day cells of requested leave are colored orange.
				</div>
				<div className={styles.plannedLeaveCell}/>
			</div>
		</div>
	);
};

export {
	VacationTooltipBody,
};
