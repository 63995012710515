/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type ProjectStructureUnitIds,
	type ProjectStructureUnits,
} from "models/project-structure/types";

const getFavoriteUnitIds = (
	units: ProjectStructureUnits,
): ProjectStructureUnitIds => {
	const favoriteUnits = units.filter((unit) => {
		return unit.favorite;
	});

	return favoriteUnits.map((unit) => {
		return unit.unitId;
	});
};

export {
	getFavoriteUnitIds,
};
