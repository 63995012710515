/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Checkbox,
	FlexRow,
	Spinner,
} from "@epam/loveship";
import {
	useCallback,
	useEffect,
	useState,
} from "react";
import {
	useDispatch,
	useSelector,
} from "react-redux";
import {
	useParams,
} from "react-router-dom";

import {
	FilterControlRow,
} from "components/common-components/filter-wrapper/filter-panel/filter-control-row";
import {
	FilterWrapper,
} from "components/common-components/filter-wrapper/FilterWrapper";
import {
	setValue,
	taggedDropdownValueChanged,
} from "components/common-components/filter-wrapper/utils";
import {
	getFiltersData,
} from "components/projects-billing/actionCreators";
import {
	DEFAULT_PROJECT_EMPLOYEES_FILTER_VALUES,
} from "components/projects-billing/constants";
import {
	getHasProjectEmployeesFilterBeenChanged,
	getProjectEmployeesFilterValuesFromLocalStorage,
	getProjectEmployeesFilterValuesLocalStorageKey,
	updateProjectEmployeesFilterValuesInLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	FiltersButton,
} from "pages/components/action-panel-components/filters-button/filters-button";
import {
	Combobox,
} from "pages/components/combobox/combobox";

import {
	ProjectsCommonFilter,
} from "../../projects-common-filter/projects-common-filter";

import styles from "./dashboard-employees-filter.module.css";

/**
 * @typedef {import("models/projects/types").ProjectId} ProjectId
 */

/**
 * @typedef {import("models/dates-and-time/types").DateString} DateString
 */

/**
 * @typedef {import("components/projects-billing/types").ProjectEmployeesFilterValues} ProjectEmployeesFilterValues
 */

/**
 * @typedef {Object} EmployeesFilterBodyProps
 * @property {boolean} isNewPermissionSchemeEnabled
 * @property {ProjectId} projectId
 * @property {DateString} fromDate
 * @property {DateString} toDate
 * @property {ProjectEmployeesFilterValues} filterValues
 * @property {import("components/common-components/filter-wrapper/filter-panel/filters-panel").SetFilterValue<ProjectEmployeesFilterValues>} setFilterValue
 */

/** @type {import("react").FC<EmployeesFilterBodyProps>} */
const EmployeesFilterBody = ({
	isNewPermissionSchemeEnabled,
	projectId,
	fromDate,
	toDate,
	filterValues,
	setFilterValue,
}) => {
	const dispatch = useDispatch();
	const [
		isLoading,
		setIsLoading,
	] = useState(true);
	const filterData = useSelector((state) => {
		return state.projectsDashboard.filtersData;
	});

	useEffect(
		() => {
			Promise.resolve(
				dispatch(
					getFiltersData(
						isNewPermissionSchemeEnabled,
						projectId,
						fromDate,
						toDate,
					),
				),
			)
				.then(() => {
					setIsLoading(false);
				});
		},
		[
			isNewPermissionSchemeEnabled,
			dispatch,
			fromDate,
			projectId,
			toDate,
		],
	);

	if (isLoading) {
		return (
			<Spinner
				cx={styles.spinner}
			/>
		);
	}

	return (
		<>
			<FilterControlRow
				title="Employees/Positions"
			>
				<Combobox
					items={filterData.employees}
					value={filterValues.employees}
					onValueChange={
						taggedDropdownValueChanged(
							"employees",
							setFilterValue,
						)
					}
					entityName="employees/position"
				/>
			</FilterControlRow>

			<FilterControlRow
				title="Engagement type"
			>
				<Combobox
					items={filterData.engagementPackages}
					value={filterValues.engagementPackages}
					onValueChange={
						taggedDropdownValueChanged(
							"engagementPackages",
							setFilterValue,
						)
					}
					entityName="engagement type"
				/>
			</FilterControlRow>

			<FilterControlRow
				title="Location"
			>
				<Combobox
					items={filterData.countries}
					value={filterValues.countries}
					onValueChange={
						taggedDropdownValueChanged(
							"countries",
							setFilterValue,
						)
					}
					entityName="location"
				/>
			</FilterControlRow>

			<FilterControlRow
				title="Time reporting issues"
			>
				<FlexRow
					vPadding="12"
					padding="6"
				>
					<Checkbox
						value={filterValues.gaps}
						label="Show employees with gaps"
						onValueChange={setValue(setFilterValue, "gaps", !filterValues.gaps)}
					/>
				</FlexRow>

				<FlexRow
					vPadding="12"
					size="24"
					padding="6"
				>
					<Checkbox
						label="Show employees with over-reporting"
						value={filterValues.overReporting}
						onValueChange={
							setValue(setFilterValue, "overReporting", !filterValues.overReporting)
						}
					/>
				</FlexRow>
			</FilterControlRow>

			<FilterControlRow
				title="Additional information"
			>
				<ProjectsCommonFilter
					setFilterProperty={setFilterValue}
					showWithVacation={filterValues.showWithVacation}
					showWithNpa={filterValues.showWithNpa}
					showWithExcludedHours={filterValues.showWithExcludedHours}
				/>
			</FilterControlRow>
		</>
	);
};

/**
 * @typedef {Object} DashboardEmployeesFilterProps
 * @property {boolean} isNewPermissionSchemeEnabled
 * @property {ProjectId} projectId
 * @property {import("components/common-components/filter-wrapper/filter-panel/filters-panel").ApplyFilter<ProjectEmployeesFilterValues>} applyFilter
 */

/** @type {import("react").FC<DashboardEmployeesFilterProps>} */
const DashboardEmployeesFilter = ({
	isNewPermissionSchemeEnabled,
	projectId,
	applyFilter,
}) => {
	const {
		fromDate,
		toDate,
	} = useParams();

	const localStorageKey = getProjectEmployeesFilterValuesLocalStorageKey(projectId);

	const getFilterValuesFromLocalStorage = useCallback(
		() => {
			return getProjectEmployeesFilterValuesFromLocalStorage(projectId);
		},
		[
			projectId,
		],
	);

	const updateFilterValuesInLocalStorage = useCallback(
		/**
		 * @param {ProjectEmployeesFilterValues} filterValues
		 * @returns {void}
		 */
		(filterValues) => {
			return updateProjectEmployeesFilterValuesInLocalStorage(
				filterValues,
				projectId,
			);
		},
		[
			projectId,
		],
	);

	return (
		<FilterWrapper
			filterChanged={getHasProjectEmployeesFilterBeenChanged}
			filterValuesDefault={DEFAULT_PROJECT_EMPLOYEES_FILTER_VALUES}
			localStorageKey={localStorageKey}
			applyFilter={applyFilter}
			getFilterValuesFromLocalStorage={getFilterValuesFromLocalStorage}
			updateFilterValuesInLocalStorage={updateFilterValuesInLocalStorage}
			render={(filterValues, setFilterValue) => {
				return (
					<EmployeesFilterBody
						isNewPermissionSchemeEnabled={isNewPermissionSchemeEnabled}
						projectId={projectId}
						fromDate={fromDate}
						toDate={toDate}
						filterValues={filterValues}
						setFilterValue={setFilterValue}
					/>
				);
			}}
			renderButton={FiltersButton}
		/>
	);
};

export default DashboardEmployeesFilter;
