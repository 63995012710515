/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as CloseIcon,
} from "@epam/assets/icons/common/navigation-close-36.svg";
import {
	Button,
	FlexCell,
	FlexRow,
	IconButton,
	SearchInput,
	TabButton,
	Tooltip,
} from "@epam/loveship";
import {
	useUuiContext,
} from "@epam/uui-core";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import {
	createPortal,
} from "react-dom";
import {
	useParams,
} from "react-router-dom";

import {
	ReactComponent as NavigationBackIcon,
} from "icons/navigation-back-24.svg";
import {
	ReactComponent as EmptySearchResultsIcon,
} from "images/project-navigation-empty-search-results.svg";
import {
	projectStructureApi,
} from "models/project-structure/api";
import {
	type ProjectName,
} from "models/projects/types";
import {
	PROJECTS_BASE_URL,
} from "pages/constants/base-routes";

import {
	NoResultsBlock,
} from "./components/no-results-block/no-results-block";
import {
	ProjectNavigationFavoriteContainer as ProjectNavigationFavorite,
} from "./components/project-navigation-favorite/project-navigation-favorite-container";
import {
	ProjectNavigationSearch,
} from "./components/project-navigation-search/project-navigation-search";
import {
	ProjectNavigationStructure,
} from "./components/project-navigation-structure/project-navigation-structure";
import {
	ProjectStructureBlocker,
} from "./components/project-structure-blocker/project-structure-blocker";
import {
	SearchResultsWarning,
} from "./components/search-results-warning/search-results-warning";
import {
	PROJECT_NAVIGATION_TAB_NAME,
} from "./constants";
import {
	useNavigationDataStructure,
} from "./hooks/use-navigation-data-structure";
import {
	type UseProjectNavigationParams,
} from "./types";

import styles from "./project-navigation-sidebar.module.css";

const SEARCH_STRING_MINIMAL_CHARACTERS_COUNT = 3;

const SEARCH_STRING_CHANGE_DELAY = 500;

enum CURRENT_VIEW {
	STRUCTURE = "STRUCTURE",
	SEARCH_RESULTS = "SEARCH_RESULTS",
}

interface ProjectNavigationTab {
	id: number;
	name: PROJECT_NAVIGATION_TAB_NAME;
	tooltipText: string;
	dataAttribute: string;
}

type ProjectNavigationTabs = ProjectNavigationTab[];

const PROJECT_NAVIGATION_TABS: ProjectNavigationTabs = [
	{
		id: 0,
		name: PROJECT_NAVIGATION_TAB_NAME.STRUCTURE,
		tooltipText: "",
		dataAttribute: "GBU_structure",
	},
	{
		id: 1,
		name: PROJECT_NAVIGATION_TAB_NAME.FAVORITES,
		tooltipText: "Item added to favorites",
		dataAttribute: "GBU_favorites",
	},
];

interface ProjectNavigationSidebarProps {
	projectName: ProjectName;
	isOpen: boolean;
	onCloseNavigationSidebar: () => void;
	tabValue: PROJECT_NAVIGATION_TAB_NAME;
	setTabValue: (tabName: PROJECT_NAVIGATION_TAB_NAME) => void;
}

const ProjectNavigationSidebar: FC<ProjectNavigationSidebarProps> = ({
	projectName,
	isOpen,
	tabValue,
	setTabValue,
	onCloseNavigationSidebar,
}) => {
	const {
		fromDate: periodStartDate,
		toDate: periodEndDate,
		projectId,
	} = useParams<UseProjectNavigationParams>();

	const navigationDataStructure = useNavigationDataStructure({
		closeNavigationSidebar: onCloseNavigationSidebar,
	});

	const {
		resetProjectStructure,
	} = navigationDataStructure;

	const [
		searchString,
		setSearchString,
	] = useState<string | undefined>();

	const isSearchStringEmpty = (
		isUndefined(searchString)
		|| isEmpty(searchString)
	);

	const isSearchRequestAllowed = (
		!isUndefined(searchString)
		&& searchString.length >= SEARCH_STRING_MINIMAL_CHARACTERS_COUNT
	);

	const [
		currentView,
		setCurrentView,
	] = useState<CURRENT_VIEW>(() => {
		return (
			isSearchRequestAllowed
				? CURRENT_VIEW.SEARCH_RESULTS
				: CURRENT_VIEW.STRUCTURE
		);
	});

	/*
		We have two breakpoints when the view should change from the tree structure
		to the search results - empty search string and search string with
		`SEARCH_STRING_MINIMAL_CHARACTERS_COUNT`+ characters. When the search
		string's value lies between the breakpoints, the view from the last met
		breakpoint should be visible: if it was the structure, it should remain
		until the search string's length is
		`SEARCH_STRING_MINIMAL_CHARACTERS_COUNT`+ characters, and if it was the
		search results, it should remain until the search string becomes empty.
		Implementing logic for keeping the state between the breakpoints is
		impossible without tracking the previous value (using `usePrevious` hook,
		for example) and writing complex conditions, which could make the logic hard
		to understand. That's why this event-based logic is used instead.
	*/
	useEffect(
		() => {
			if (isSearchRequestAllowed) {
				setCurrentView(CURRENT_VIEW.SEARCH_RESULTS);
			}

			if (isSearchStringEmpty) {
				setCurrentView(CURRENT_VIEW.STRUCTURE);
			}
		},
		[
			isSearchRequestAllowed,
			isSearchStringEmpty,
		],
	);

	const {
		data: searchResults = {
			content: [],
			totalElements: 0,
		},
		isFetching,
	} = projectStructureApi.useGetProjectStructureSearchResultsQuery(
		{
			/*
				`isSearchRequestAllowed`, which enables the query
				(see `skip` property below) includes a check for
				`searchString` non-emptiness.
			*/
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			searchString: searchString!,
			periodStartDate,
			periodEndDate,
		},
		{
			skip: !isSearchRequestAllowed,
		},
	);

	const clearSearchString = useCallback(
		(): void => {
			setSearchString(undefined);
		},
		[],
	);

	const navigationPanelRef = useRef<HTMLDivElement>(null);

	useEffect(
		() => {
			const handleClickOutside = (event: MouseEvent): void => {
				const navigationPanelElement = navigationPanelRef.current;

				if (
					!isNull(navigationPanelElement)
					&& !navigationPanelElement.contains(event.target as Node | null)
					&& isOpen
				) {
					onCloseNavigationSidebar();

					/*
						In case user types something to the input and closes the panel
						before the input delay time is over, there can be race condition
						between the value's set via the component's `onValueChange` handler
						and via the "click" event handler. That's why it is necessary to add
						here a delay too, so that it always wins in the race condition
						(it is expected that reaction on the "click" event always happens
						a bit later than the timer in the search component starts counting
						the delay time).
					*/
					setTimeout(
						() => {
							clearSearchString();
						},
						SEARCH_STRING_CHANGE_DELAY,
					);
				}
			};

			document.addEventListener(
				"click",
				handleClickOutside,
				{
					capture: true,
				},
			);

			return () => {
				document.removeEventListener(
					"click",
					handleClickOutside,
					{
						capture: true,
					},
				);
			};
		},
		[
			clearSearchString,
			isOpen,
			onCloseNavigationSidebar,
		],
	);

	const navigationSidebarRef = useRef<HTMLDivElement>(null);

	const {
		uuiLayout,
	} = useUuiContext();
	const layer = useRef(uuiLayout.getLayer());

	useEffect(
		() => {
			const layerValue = layer.current;

			return () => {
				uuiLayout.releaseLayer(layerValue);
			};
		},
		[
			uuiLayout,
		],
	);

	useEffect(
		() => {
			const handleWindowScroll = (): void => {
				const navigationSidebarElement = navigationSidebarRef.current;
				const headerHeight = 60;

				if (!isNull(navigationSidebarElement)) {
					const {
						scrollY,
						innerHeight,
					} = window;

					let navigationHeight: number;

					if (scrollY <= headerHeight) {
						navigationHeight = innerHeight - headerHeight + Math.round(scrollY);
					} else {
						navigationHeight = innerHeight;
					}

					navigationSidebarElement.style.setProperty("--height", `${navigationHeight}px`);
				}
			};

			window.addEventListener("scroll", handleWindowScroll);

			return () => {
				window.removeEventListener("scroll", handleWindowScroll);
			};
		},
		[],
	);

	const unitsFromSearchResults = searchResults.content;

	const portalContainer = document.getElementById("app");

	if (isNull(portalContainer)) {
		return null;
	}

	return createPortal(
		<div
			ref={navigationPanelRef}
			className={
				classNames(
					styles.container,
					{
						[styles.open]: isOpen,
					},
				)
			}
			style={{
				zIndex: layer.current.zIndex,
			}}
		>
			<div
				className={styles.navigationSidebar}
				ref={navigationSidebarRef}
			>
				<FlexRow
					spacing={null}
					cx={styles.navigationRow}
				>
					{
						!isUndefined(projectId)
							? (
								<IconButton
									icon={NavigationBackIcon}
									link={{
										pathname: PROJECTS_BASE_URL,
									}}
									cx={styles.backLink}
								/>
							)
							: null
					}
					<Button
						caption={projectName}
						isDropdown={true}
						isOpen={true}
						color="gray"
						fill="white"
					/>
					<FlexCell grow={1}/>
					<IconButton
						icon={CloseIcon}
						onClick={() => {
							onCloseNavigationSidebar();

							clearSearchString();
						}}
					/>
				</FlexRow>
				<FlexRow
					borderBottom={true}
					cx={styles.tabs}
				>
					{
						PROJECT_NAVIGATION_TABS.map(({
							id,
							name,
							tooltipText,
							dataAttribute,
						}) => {
							return (
								<Tooltip
									key={id}
									content={tooltipText}
									color="white"
								>
									<TabButton
										rawProps={{
											"data-name": dataAttribute,
										}}
										caption={name}
										isLinkActive={tabValue === name}
										onClick={() => {
											setTabValue(name);

											clearSearchString();
										}}
										cx={styles.tab}
									/>
								</Tooltip>
							);
						})
					}
				</FlexRow>
				<FlexRow cx={styles.dataListStructure}>
					{
						tabValue === PROJECT_NAVIGATION_TAB_NAME.STRUCTURE
							? (
								<>
									<SearchInput
										value={searchString}
										onValueChange={setSearchString}
										placeholder="Search"
										debounceDelay={SEARCH_STRING_CHANGE_DELAY}
										cx={styles.searchInput}
									/>
									{
										// TODO: refactor the component to make it simpler.
										isFetching
											? <ProjectStructureBlocker/>
											: currentView === CURRENT_VIEW.STRUCTURE
												? (
													<ProjectNavigationStructure
														projectName={projectName}
														navigationData={navigationDataStructure}
													/>
												)
												: isEmpty(unitsFromSearchResults)
													? (
														<NoResultsBlock
															content="No results found"
															icon={EmptySearchResultsIcon}
															className={styles.emptySearchResults}
															textParams={{
																lineHeight: "18",
															}}
														/>
													)
													: (
														<>
															{
																searchResults.totalElements > unitsFromSearchResults.length
																	? <SearchResultsWarning/>
																	: null
															}
															<ProjectNavigationSearch
																projectName={projectName}
																units={unitsFromSearchResults}
																closeNavigationSidebar={() => {
																	onCloseNavigationSidebar();

																	resetProjectStructure();

																	clearSearchString();
																}}
															/>
														</>
													)
									}
								</>
							)
							: (
								<ProjectNavigationFavorite
									projectName={projectName}
									closeNavigationSidebar={() => {
										onCloseNavigationSidebar();

										resetProjectStructure();
									}}
								/>
							)
					}
				</FlexRow>
			</div>
		</div>,
		portalContainer,
	);
};

export {
	ProjectNavigationSidebar,
};
