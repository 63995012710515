/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	Dropdown,
	DropdownContainer,
	FlexRow,
	RadioInput,
} from "@epam/loveship";

import styles from "./BreakdownSelector.module.css";

const BreakdownSelector = ({
	project,
	value,
	changeBreakdown,
}) => {
	return (
		<Dropdown
			placement="bottom-end"
			renderTarget={(props) => {
				return (
					<p title={project.breakdownsData[value].name}>
						<Button
							{...props}
							color="sky"
							caption={project.breakdownsData[value].name}
							size="24"
							cx={styles["breakdown-selector"]}
						/>
					</p>
				);
			}}
			renderBody={() => {
				return (
					<DropdownContainer
						cx={styles["dropdown-container"]}
					>
						{
							project.breakdownIds.map((breakdownId) => {
								return (
									<FlexRow
										padding="12"
										onClick={() => {
											return changeBreakdown(breakdownId);
										}}
										key={breakdownId}
									>
										<RadioInput
											size="12"
											label={project.breakdownsData[breakdownId].name}
											value={value === project.breakdownsData[breakdownId].uid}
											onValueChange={() => {
												return changeBreakdown(breakdownId);
											}}
										/>
									</FlexRow>
								);
							})
						}
					</DropdownContainer>
				);
			}}
		/>
	);
};

export default BreakdownSelector;
