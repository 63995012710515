/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type DatePickerProps as UUIDatePickerProps,
} from "@epam/uui";
import {
	isAfter,
	isEqual,
	parseISO,
} from "date-fns";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import {
	type FC,
	useCallback,
	useState,
} from "react";

import {
	type DateString,
} from "models/dates-and-time/types";
import {
	toFancyDate,
} from "models/dates-and-time/utils/to-fancy-date";
import {
	DatePicker,
	type DatePickerProps,
	type DatePickerValidationErrors,
} from "pages/components/date-picker/date-picker";

type LockDate = DatePickerProps["value"];

interface GetLockDateErrorsParams {
	dateToLock: LockDate;
	reportingLockDate: Date;
}

const getLockDateErrors = ({
	dateToLock: dateToLockString,
	reportingLockDate,
}: GetLockDateErrorsParams): DatePickerValidationErrors => {
	const errors: DatePickerValidationErrors = [];

	if (isNull(dateToLockString)) {
		errors.push("Date is required");
	}

	if (!isNull(dateToLockString)) {
		const dateToLock = parseISO(dateToLockString);

		if (
			isEqual(dateToLock, reportingLockDate)
			|| isAfter(dateToLock, reportingLockDate)
		) {
			errors.push(`Project can't be locked on or after ${toFancyDate(reportingLockDate)}`);
		}
	}

	return errors;
};

interface UseDatePickerDataParams {
	selectedPeriodEndDateString: DateString;
	billingLockDate: Date;
	reportingLockDate: Date;
}

interface UseDatePickerDataResult {
	Component: FC;
	dateToLock: LockDate;
	resetDateToLock: () => void;
	hasErrors: boolean;
}

const useDatePickerData = ({
	selectedPeriodEndDateString,
	billingLockDate,
	reportingLockDate,
}: UseDatePickerDataParams): UseDatePickerDataResult => {
	const [
		dateToLock,
		setDateToLock,
	] = useState<LockDate>(selectedPeriodEndDateString);

	const resetDateToLock = useCallback(
		() => {
			setDateToLock(selectedPeriodEndDateString);
		},
		[
			selectedPeriodEndDateString,
		],
	);

	const filterDatePickerDates = useCallback<NonNullable<UUIDatePickerProps["filter"]>>(
		(dayJsDate) => {
			const dateToCheck = dayJsDate.toDate();

			return (
				isEqual(dateToCheck, billingLockDate)
				|| isAfter(dateToCheck, billingLockDate)
			);
		},
		[
			billingLockDate,
		],
	);

	const errors = getLockDateErrors({
		dateToLock,
		reportingLockDate,
	});

	const DatePickerComponent = useCallback<FC>(
		() => {
			return (
				<DatePicker
					value={dateToLock}
					onValueChange={setDateToLock}
					placeholder="Date"
					filterDates={filterDatePickerDates}
					errors={errors}
				/>
			);
		},
		[
			dateToLock,
			filterDatePickerDates,
			errors,
		],
	);

	return {
		dateToLock,
		Component: DatePickerComponent,
		resetDateToLock,
		hasErrors: !isEmpty(errors),
	};
};

export {
	useDatePickerData,
};
