/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Badge,
	DataPickerRow,
	FlexRow,
	PickerInput,
	PickerItem,
	PickerToggler,
	Text,
} from "@epam/loveship";
import {
	useArrayDataSource,
} from "@epam/uui-core";
import constant from "lodash/constant";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useCallback,
	useMemo,
} from "react";

import {
	EVENT_DELAY,
} from "components/consts";
import {
	DataPickerNotFoundPlaceholder,
} from "pages/components/data-picker-not-found-placeholder/data-picker-not-found-placeholder";

type StreamId = string;

type StreamName = string;

interface Stream {
	uid: StreamId;
	name: StreamName;
	parent: StreamId | undefined;
}

type Streams = Stream[];

type SelectedStreams = Record<StreamId, true>;

const PROJECT_LEVEL_STREAM_ID: StreamId = "PROJECT_LEVEL";

const PROJECT_LEVEL_STREAM_OPTION: Stream = {
	uid: PROJECT_LEVEL_STREAM_ID,
	name: "Project level",
	parent: undefined,
};

interface StreamDropdownProps {
	data: Record<StreamId, Stream>;
	selectedValues: SelectedStreams;
	onChange: (nextSelectedStreams: SelectedStreams) => void;
}

const StreamDropdown: FC<StreamDropdownProps> = ({
	data: streams,
	selectedValues: selectedStreams,
	onChange,
}) => {
	const streamOptions = useMemo<Streams>(
		() => {
			return [
				PROJECT_LEVEL_STREAM_OPTION,
				...Object.values(streams).toSorted((stream1, stream2) => {
					if (stream1.name === "Root positions") {
						return -1;
					}

					if (stream2.name === "Root positions") {
						return 1;
					}

					return stream1.name.trim().localeCompare(stream2.name.trim());
				}),
			];
		},
		[
			streams,
		],
	);

	const streamOptionsData = useArrayDataSource<
		Stream,
		StreamId,
		unknown
	>(
		{
			items: streamOptions,
			getId: (option) => {
				return option.uid;
			},
			getParentId: (option) => {
				return option.parent;
			},
			cascadeSelection: "explicit",
		},
		[],
	);

	const selectedStreamOptions = useMemo(
		() => {
			const selectedStreamIds = Object.keys(selectedStreams);

			return selectedStreamIds.reduce<Streams>(
				(currentSelectedStreams, streamId) => {
					const stream = streams[streamId];

					if (!isUndefined(stream)) {
						currentSelectedStreams.push(stream);
					}

					return currentSelectedStreams;
				},
				[],
			);
		},
		[
			streams,
			selectedStreams,
		],
	);

	const handleSelectionChange = (nextSelectedStreamOptions: Streams): void => {
		const nextSelectedStreams = nextSelectedStreamOptions.reduce<SelectedStreams>(
			(currentSelectedStreams, selectedStreamOption) => {
				// eslint-disable-next-line no-param-reassign
				currentSelectedStreams[selectedStreamOption.uid] = true;

				return currentSelectedStreams;
			},
			{},
		);

		onChange(nextSelectedStreams);
	};

	const getIsParent = useCallback(
		(streamId: StreamId): boolean => {
			return streamOptions.some((streamOption) => {
				return streamOption.parent === streamId;
			});
		},
		[
			streamOptions,
		],
	);

	const selectedChildStreamsCount = useMemo(
		() => {
			const selectedChildStreams = selectedStreamOptions.filter((option) => {
				return !getIsParent(option.uid);
			});

			return selectedChildStreams.length;
		},
		[
			selectedStreamOptions,
			getIsParent,
		],
	);

	return (
		<PickerInput
			dataSource={streamOptionsData}
			value={selectedStreamOptions}
			onValueChange={handleSelectionChange}
			getName={(option) => {
				return option.name;
			}}
			selectionMode="multi"
			valueType="entity"
			maxItems={5}
			emptyValue={[]}
			// It allows to do dropdown width equal to input width.
			minBodyWidth={340}
			renderNotFound={DataPickerNotFoundPlaceholder}
			isFoldedByDefault={constant(false)}
			searchDebounceDelay={EVENT_DELAY}
			getRowOptions={(option) => {
				return {
					checkbox: {
						isVisible: option.uid !== PROJECT_LEVEL_STREAM_ID,
					},
				};
			}}
			cascadeSelection="explicit"
			renderToggler={(props) => {
				return (
					<PickerToggler
						{...props}
						getName={(option) => {
							const {
								uid,
								name,
								parent,
							} = option;

							if (
								name !== "Root positions"
								|| uid === "ROOT"
								|| isUndefined(parent)
							) {
								return name;
							}

							return `Root positions - ${streams[parent].name}`;
						}}
					/>
				);
			}}
			renderRow={(props, dataSourceState) => {
				const {
					value: option,
				} = props;

				if (
					!isUndefined(option)
					&& option.uid === PROJECT_LEVEL_STREAM_ID
				) {
					const {
						name,
					} = option;

					return (
						<FlexRow
							key={props.rowKey}
							spacing={null}
							columnGap="6"
							padding="12"
						>
							<Text>
								{name}
							</Text>

							{
								selectedChildStreamsCount > 0
									? (
										<Badge
											caption={`+${selectedChildStreamsCount}`}
											shape="round"
											color="night300"
										/>
									)
									: null
							}
						</FlexRow>
					);
				}

				return (
					<DataPickerRow
						{...props}
						key={props.rowKey}
						renderItem={(item) => {
							return (
								<PickerItem
									{...props}
									dataSourceState={dataSourceState}
									title={item.name}
								/>
							);
						}}
					/>
				);
			}}
		/>
	);
};

export {
	StreamDropdown,
};
