/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	useLazyDataSource,
} from "@epam/uui-core";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
	useCallback,
	useEffect,
	useState,
} from "react";
import {
	useParams,
} from "react-router-dom";

import {
	projectStructureApi,
} from "models/project-structure/api";
import {
	ProjectStructureUnitType,
} from "models/project-structure/constants";
import {
	type ProjectStructureUnit,
	type ProjectStructureUnitId,
} from "models/project-structure/types";
import {
	useDispatch,
} from "store";

import {
	INITIAL_DATA_SOURCE_STATE,
} from "../constants";
import {
	addUnitsToFavorites,
} from "../store/reducer";
import {
	type Filter,
	type NavigationDataSourceState,
	type UseNavigationDataParams,
	type UseNavigationDataResult,
	type UseProjectNavigationParams,
} from "../types";
import {
	getFavoriteUnitIds,
} from "../utils/get-favorite-unit-ids";
import {
	setScrollTopInLocalStorage,
} from "../utils/set-scroll-top-in-local-storage";

type UseNavigationDataStructureParams = Omit<UseNavigationDataParams, "units">;

interface UseNavigationDataStructureResult extends UseNavigationDataResult {
	resetProjectStructure: () => void;
}

const useNavigationDataStructure = ({
	closeNavigationSidebar,
}: UseNavigationDataStructureParams): UseNavigationDataStructureResult => {
	const {
		projectId: currentProjectId,
		fromDate: periodStartDate,
		toDate: periodEndDate,
	} = useParams<UseProjectNavigationParams>();

	const {
		data: defaultParentIds = [],
	} = projectStructureApi.useGetProjectStructureParentIdsQuery(
		// @ts-expect-error Is non-nullable when value for `skip` field is `false`.
		currentProjectId,
		{
			skip: isUndefined(currentProjectId),
		},
	);

	const [
		dataSourceState,
		setDataSourceState,
	] = useState<NavigationDataSourceState>(INITIAL_DATA_SOURCE_STATE);

	useEffect(
		() => {
			setDataSourceState(INITIAL_DATA_SOURCE_STATE);
		},
		[
			periodStartDate,
			periodEndDate,
		],
	);

	const dispatch = useDispatch();

	const dataSource = useLazyDataSource<
		ProjectStructureUnit,
		ProjectStructureUnitId,
		Filter
	>(
		{
			api: async (request, context) => {
				const parentId = context?.parentId;

				try {
					if (!isNil(parentId)) {
						const getProjectStructureChildUnitsPromise = dispatch(
							projectStructureApi.endpoints.getProjectStructureChildUnits.initiate(
								{
									parentId,
									periodStartDate,
									periodEndDate,
								},
							),
						);

						const childUnits = await getProjectStructureChildUnitsPromise.unwrap();

						getProjectStructureChildUnitsPromise.unsubscribe();

						const favoriteUnitIds = getFavoriteUnitIds(childUnits);

						dispatch(addUnitsToFavorites(favoriteUnitIds));

						return {
							items: childUnits,
							count: childUnits.length,
						};
					}

					const getProjectStructureRootPromise = dispatch(
						projectStructureApi.endpoints.getProjectStructureRoot.initiate(
							undefined,
						),
					);

					const rootData = await getProjectStructureRootPromise.unwrap();

					getProjectStructureRootPromise.unsubscribe();

					const getProjectStructureChildUnitsPromise = dispatch(
						projectStructureApi.endpoints.getProjectStructureChildUnits.initiate(
							{
								parentId: rootData.unitId,
								periodStartDate,
								periodEndDate,
							},
						),
					);

					const childUnits = await getProjectStructureChildUnitsPromise.unwrap();

					getProjectStructureChildUnitsPromise.unsubscribe();

					const childUnitsWithNullifiedParentIds = childUnits.map<
						ProjectStructureUnit
					>((child) => {
						return {
							...child,
							parentId: null,
						};
					});

					const favoriteUnitIds = getFavoriteUnitIds(childUnits);

					dispatch(addUnitsToFavorites(favoriteUnitIds));

					return {
						items: childUnitsWithNullifiedParentIds,
						count: childUnitsWithNullifiedParentIds.length,
					};
				} catch {
					return {
						items: [],
						count: 0,
					};
				}
			},
			/*
				Projects don't have children,
				so there shouldn't be the expanding/collapsing functionality.
			*/
			getChildCount: (item) => {
				return item.type === ProjectStructureUnitType.PROJECT
					? 0
					: 1;
			},
			getId: (item) => {
				return item.unitId;
			},
			getParentId: (item) => {
				const {
					parentId,
				} = item;

				if (isNull(parentId)) {
					return undefined;
				}

				return parentId;
			},
		},
		[
			periodStartDate,
			periodEndDate,
			dispatch,
		],
	);

	const resetProjectStructure = useCallback(
		() => {
			dataSource.clearCache();
		},
		[
			dataSource,
		],
	);

	const view = dataSource.useView(
		dataSourceState,
		setDataSourceState,
		{
			getRowOptions: () => {
				return {
					onClick: (item) => {
						if (item.value?.type === ProjectStructureUnitType.PROJECT) {
							closeNavigationSidebar();

							setScrollTopInLocalStorage(item.value.name);
						}
					},
					checkbox: {
						isVisible: true,
					},
				};
			},
			isFoldedByDefault: (item) => {
				if (!isUndefined(currentProjectId)) {
					const isDefaultParentId = defaultParentIds.includes(item.unitId);

					return !isDefaultParentId;
				}

				return true;
			},
		},
	);

	return {
		view,
		dataSourceState,
		setDataSourceState,
		resetProjectStructure,
	};
};

export {
	useNavigationDataStructure,
};
