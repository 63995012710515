/*
 * Copyright © 2020 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Dropdown,
	DropdownContainer,
	LinkButton,
} from "@epam/loveship";
import {
	Fragment,
} from "react";

import styles from "./ShowMoreStatusesDropdown.module.css";

// TODO: check with mocks.
const ShowMoreStatusesDropdown = ({
	markupStatusesIds,
	markupStatuses,
	markupSummaries,
}) => {
	return (
		<Dropdown
			placement="bottom"
			renderTarget={(props) => {
				return (
					<LinkButton
						{...props}
						siz="24"
						cx={styles["dropdown-target"]}
						caption={`Show more (${markupStatusesIds.length})`}
					/>
				);
			}}
			renderBody={() => {
				return (
					<DropdownContainer
						width="auto"
						cx={styles["dropdown-container"]}
					>
						<div className={styles["status-container"]}>
							{
								markupStatusesIds.map((statusId) => {
									return (
										<Fragment key={statusId}>

											<div>
												{markupStatuses[statusId].name}
											</div>

											<div className={styles["status-duration"]}>
												{`${markupSummaries[statusId]}h`}
											</div>
										</Fragment>
									);
								})
							}
						</div>
					</DropdownContainer>
				);
			}}
		/>
	);
};

export default ShowMoreStatusesDropdown;
