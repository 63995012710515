/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Text,
} from "@epam/loveship";
import isNull from "lodash/isNull";
import {
	type FC,
} from "react";

import {
	type ProjectStructureUnit,
} from "models/project-structure/types";

import styles from "./unit-path.module.css";

interface UnitPathProps {
	path: ProjectStructureUnit["path"];
}

const UnitPath: FC<UnitPathProps> = ({
	path,
}) => {
	if (isNull(path)) {
		return null;
	}

	return (
		<div className={styles.path}>
			<Text
				fontSize="12"
				color="night500"
				fontWeight="600"
			>
				{path}
			</Text>
		</div>
	);
};

export {
	UnitPath,
};
