/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as ThreeDotsIcon,
} from "@epam/assets/icons/common/navigation-more_vert-24.svg";
import {
	IconButton,
} from "@epam/loveship";
import {
	bool,
	string,
} from "prop-types";
import {
	useSelector,
} from "react-redux";
import {
	useParams,
} from "react-router-dom";

import {
	Menu,
} from "pages/components/menu/menu";
import {
	MenuOption,
} from "pages/components/menu/option";
import {
	SubMenu,
} from "pages/components/menu/sub-menu";
import {
	generatePackagesPageUrl,
} from "pages/utils/generate-packages-page-url";
import {
	generateProjectTMSLogPageUrl,
} from "pages/utils/generate-project-tms-log-page-url";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import SendMailMenu from "../../send-mail-menu";

import styles from "./three-dots-menu.module.css";

/**
 * @typedef {Object} ThreeDotsMenuProps
 * @property {import("models/projects/types").ProjectId} projectId
 * @property {boolean} isFirst
 */

/** @type {import("react").FC<ThreeDotsMenuProps>} */
const ThreeDotsMenu = ({
	projectId,
	isFirst,
}) => {
	const {
		fromDate,
		toDate,
	} = useParams();

	const project = useSelector((state) => {
		return state.projectsDashboard.projects[projectId];
	});

	const selector = isFirst
		? "three-dots-menu-0"
		: "";

	return (
		<Menu
			placement="bottom-end"
			rawProps={{
				"data-name": selector,
			}}
			renderOptions={() => {
				const menuOptions = [];

				const canViewPackagesPage = project.hasTimePackagePermission;

				if (canViewPackagesPage) {
					menuOptions.push(
						<MenuOption
							key="Go to packages"
							caption="Go to packages"
							href={
								generatePackagesPageUrl({
									projectId: project.id,
									periodStartDate: fromDate,
									periodEndDate: toDate,
								})
							}
							target="_blank"
							onClick={() => {
								window.dataLayer.push({
									event_name: "3dot_tab",
									event_action: "go to packages",
									event: "autoevent",
								});
							}}
						/>,
					);
				}

				menuOptions.push(
					<SubMenu
						key="Send email"
						renderOptions={() => {
							return (
								<SendMailMenu
									kpsTeam={project.kpsTeam}
									employees={project.employees}
									eventLocation="3dot_tab"
									className={styles.sendMailMenuWidget}
								/>
							);
						}}
						cx={styles.sendMailMenu}
					>
						{(props) => {
							return (
								<MenuOption
									caption="Send email"
									isSubMenuButton={true}
									{...props}
								/>
							);
						}}
					</SubMenu>,
				);

				const canViewTMSLogPage = project.hasTmsLogButtonPermission;

				if (canViewTMSLogPage) {
					menuOptions.push(
						<MenuOption
							key="Go to TMS Log"
							caption="Go to TMS Log"
							href={
								generateProjectTMSLogPageUrl({
									projectId: project.id,
								})
							}
							target="_blank"
							onClick={() => {
								window.dataLayer.push({
									event_name: "3dot_tab",
									event_action: "go to tms log",
									event: "autoevent",
								});
							}}
						/>,
					);
				}

				return menuOptions;
			}}
		>
			{(props) => {
				return (
					<IconButton
						icon={ThreeDotsIcon}
						rawProps={{
							"data-name": toDataAttribute(`${selector} button`),
						}}
						{...props}
					/>
				);
			}}
		</Menu>
	);
};

ThreeDotsMenu.propTypes = {
	isFirst: bool.isRequired,
	projectId: string.isRequired,
};

export default ThreeDotsMenu;
