/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Text,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Component,
} from "react";

import styles from "./SliderItem.module.css";

class SliderItem extends Component {
	onClick = () => {
		const {
			onItemClick,
			index,
			close,
		} = this.props;

		if (onItemClick) {
			onItemClick(index);
		}

		if (close) {
			close();
		}
	};

	render() {
		const {
			index,
			selectedIndex,
			caption,
			isLast,
		} = this.props;

		return (
			<div
				className={styles["slider-item"]}
				onClick={this.onClick}
			>
				<div className={styles["slider-element"]}>
					{
						!isLast
							? (
								<div
									className={
										classNames(
											styles.line,
											(
												index >= 0
												&& index < selectedIndex
											)
												? styles.internal
												: "",
										)
									}
								/>
							)
							: null
					}

					<div
						className={
							classNames(
								styles.dot,
								(
									index === 0
									|| index === selectedIndex
								)
									? styles.bound
									: "",
								(
									index > 0
									&& index < selectedIndex
								)
									? styles.internal
									: "",
							)
						}
					/>
				</div>
				<Text>
					{caption}
				</Text>
			</div>
		);
	}
}

export default SliderItem;
