/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
	type ReactNode,
} from "react";

import {
	type SetFilterValue,
} from "components/common-components/filter-wrapper/filter-panel/filters-panel";
import {
	SwitchersWithTooltip,
} from "components/common-components/SwitchersWithTooltip/SwitchersWithTooltip";
import {
	type ProjectsCommonFilterValues,
} from "components/projects-billing/types";

import {
	VacationTooltipBody,
} from "./vacation-tooltip-body";

interface ProjectsCommonFilterProps extends ProjectsCommonFilterValues {
	setFilterProperty: SetFilterValue<ProjectsCommonFilterValues>;
}

interface SwitcherOption {
	value: boolean;
	label: string;
	onValueChange: (value: boolean) => void;
	tooltip?: ReactNode;
}

type SwitcherOptions = SwitcherOption[];

const ProjectsCommonFilter: FC<ProjectsCommonFilterProps> = ({
	setFilterProperty,
	showWithVacation,
	showWithNpa,
	showWithExcludedHours,
}) => {
	const switcherOptions: SwitcherOptions = [
		{
			value: showWithVacation,
			label: "Show requested and taken leave",
			onValueChange: (nextShowWithVacation) => {
				setFilterProperty("showWithVacation", nextShowWithVacation);
			},
			tooltip: <VacationTooltipBody/>,
		},
		{
			value: showWithNpa,
			label: "Show with NPA",
			onValueChange: (nextShowWithNpa) => {
				setFilterProperty("showWithNpa", nextShowWithNpa);
			},
		},
		{
			value: showWithExcludedHours,
			label: "Show with On-duty hours",
			onValueChange: (nextShowWithExcludedHours) => {
				setFilterProperty("showWithExcludedHours", nextShowWithExcludedHours);
			},
			tooltip: `Time reported to given time package will not be included to the Project Reporting sum and
						will be collected in the separate columns on Time reports and summaries`,
		},
	];

	return (
		<SwitchersWithTooltip
			switchers={switcherOptions}
		/>
	);
};

export {
	ProjectsCommonFilter,
};
