/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as InfoIcon,
} from "@epam/assets/icons/common/notification-info-outline-18.svg";
import {
	Button,
	FlexRow,
	FlexSpacer,
	IconContainer,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	RadioGroup,
	Text,
	Tooltip,
} from "@epam/loveship";
import {
	type RadioGroupItem,
} from "@epam/uui";
import {
	type IModal,
} from "@epam/uui-core";
import {
	parseISO,
} from "date-fns";
import isNull from "lodash/isNull";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useState,
} from "react";
import {
	useDispatch,
} from "react-redux";

import {
	displayErrorNotification,
} from "components/common-components/notification/Notifications";
import {
	lockReporting as lockReportingAction,
} from "components/projects-billing/actionCreators";
import {
	getProjectsCommonFilterValuesFromLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	REPORTING_LOCK_ERROR_NOTIFICATION_MESSAGE,
} from "constants/text/locks";
import {
	type DateString,
	type WithPeriodDates,
} from "models/dates-and-time/types";
import {
	locksApi,
} from "models/locks/api";
import {
	type ProjectId,
} from "models/projects/types";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	useDatePickerData,
} from "./hooks/use-date-picker-data";
import {
	usePeriodPickerData,
} from "./hooks/use-period-picker-data";

import styles from "./lock-reporting-modal-window.module.css";

enum LockType {
	AUTOMATED = "AUTOMATED",
	MANUAL = "MANUAL",
}

const LOCK_TYPE_RADIO_GROUP_OPTIONS: RadioGroupItem<LockType>[] = [
	{
		id: LockType.AUTOMATED,
		renderName: () => {
			return (
				<FlexRow
					spacing={null}
					columnGap="6"
					size={null}
					cx={styles.noMinHeight}
				>
					<Text
						fontSize="14"
						lineHeight="18"
						size="18"
					>
						Automated
					</Text>
					<Tooltip
						content={(
							<Text
								fontSize="12"
								lineHeight="18"
								size="18"
							>
								Days <strong>with the position workload</strong> will be locked if the following conditions are met:

								<ul
									className={styles.list}
								>
									<li>
										Full daily workload is reported as regular hours, leave (if taken), or a combination of both.
									</li>
									<li>
										Regular hours are reported in accordance with the position type (billable or non-billable).
									</li>
								</ul>

								Days <strong>without the position workload</strong> (days off, weekends, and public holidays)
								will be locked only if there is no project regular reporting.
							</Text>
						)}
						color="white"
						placement="bottom"
					>
						<IconContainer
							icon={InfoIcon}
						/>
					</Tooltip>
				</FlexRow>
			);
		},
	},
	{
		id: LockType.MANUAL,
		name: "Entire selected period.",
	},
];

interface LockReportingModalWindowSuccessResult {
	lockType: LockType;
	lockDate?: DateString;
}

interface LockReportingModalWindowProps extends
	WithPeriodDates,
	IModal<LockReportingModalWindowSuccessResult> {
	projectId: ProjectId;
	billingLockDate: DateString;
	reportingLockDate: DateString;
	isNewPermissionSchemeEnabled: boolean;
}

const LockReportingModalWindow: FC<LockReportingModalWindowProps> = ({
	projectId,
	periodStartDate: periodStartDateString,
	periodEndDate: periodEndDateString,
	billingLockDate: billingLockDateString,
	reportingLockDate: reportingLockDateString,
	isNewPermissionSchemeEnabled,
	...modalWindowProps
}) => {
	const dispatch = useDispatch();

	const billingLockDate = parseISO(billingLockDateString);
	const reportingLockDate = parseISO(reportingLockDateString);

	const {
		periodToLock,
		Component: PeriodToLockComponent,
		hasErrors: hasPeriodToLockErrors,
		resetPeriodToLock,
	} = usePeriodPickerData({
		selectedPeriodStartDateString: periodStartDateString,
		billingLockDate,
		reportingLockDate,
	});

	const {
		dateToLock,
		Component: DateToLockComponent,
		hasErrors: hasDateToLockErrors,
		resetDateToLock,
	} = useDatePickerData({
		selectedPeriodEndDateString: periodEndDateString,
		billingLockDate,
		reportingLockDate,
	});

	const [
		lockType,
		setLockType,
	] = useState<LockType>(LockType.AUTOMATED);

	const isManualLockTypeSelected = lockType === LockType.MANUAL;

	const closeModalWindow = (): void => {
		modalWindowProps.abort();
	};

	const [
		isLockingPeriod,
		setIsLockingPeriod,
	] = useState(false);

	const useLockReportingLockForProjectsIntelligentMutationHook = (
		isNewPermissionSchemeEnabled
			? locksApi.useLockReportingLockForProjectsIntelligentV3Mutation
			: locksApi.useLockReportingLockForProjectsIntelligentMutation
	);

	const [
		lockReportingLockForProjectsIntelligent,
	] = useLockReportingLockForProjectsIntelligentMutationHook();

	const lockReporting = async (): Promise<void> => {
		try {
			dispatch(showSpinner());

			setIsLockingPeriod(true);

			if (lockType === LockType.AUTOMATED) {
				const {
					from: lockFromDateString,
					to: lockToDateString,
				} = periodToLock;

				if (
					!isNull(lockFromDateString)
					&& !isNull(lockToDateString)
				) {
					await lockReportingLockForProjectsIntelligent({
						projectIds: [
							projectId,
						],
						periodStartDate: lockFromDateString,
						periodEndDate: lockToDateString,
					})
						.unwrap();
				}
			} else if (!isNull(dateToLock)) {
				const {
					showWithNpa,
					showWithVacation,
				} = getProjectsCommonFilterValuesFromLocalStorage();

				interface ErrorResponse {
					error: boolean;
					message: string;
				}

				// @ts-expect-error The type is correct.
				// eslint-disable-next-line @typescript-eslint/await-thenable
				const response: ErrorResponse | undefined = await dispatch(
					// @ts-expect-error The returned `promise` method is handled by `promise` middleware.
					lockReportingAction(
						isNewPermissionSchemeEnabled,
						[
							projectId,
						],
						dateToLock,
						periodStartDateString,
						periodEndDateString,
						showWithNpa,
						showWithVacation,
					),
				);

				if (
					!isUndefined(response)
					&& response.error
				) {
					throw new Error(response.message);
				}
			}

			setIsLockingPeriod(false);

			modalWindowProps.success({
				lockType,
				lockDate: (
					lockType === LockType.MANUAL
					&& !isNull(dateToLock)
						? dateToLock
						: undefined
				),
			});

			dispatch(hideSpinner());
		} catch {
			setIsLockingPeriod(false);

			dispatch(hideSpinner());

			displayErrorNotification(REPORTING_LOCK_ERROR_NOTIFICATION_MESSAGE);
		}
	};

	const hasErrors = (
		isManualLockTypeSelected
			? hasDateToLockErrors
			: hasPeriodToLockErrors
	);

	const isLockButtonDisabled = (
		hasErrors
		|| isLockingPeriod
	);

	return (
		<ModalBlocker
			{...modalWindowProps}
		>
			<ModalWindow
				rawProps={{
					style: {
						width: 540,
					},
				}}
			>
				<ModalHeader
					title="Validate and lock"
					rawProps={{
						"data-name": toDataAttribute("Modal window header"),
					}}
					onClose={closeModalWindow}
				/>

				<div className={styles.modalWindowBody}>
					<div
						className={styles.infoTextSection}
					>
						<Text
							fontSize="14"
							lineHeight="18"
							size="18"
							cx={styles.infoText}
						>
							Select the period and method for validation and lock:
						</Text>
						<Text
							fontSize="14"
							lineHeight="18"
							size="18"
							cx={styles.infoText}
						>
							Note that after validation, the employees won’t be able to adjust their time within the period.
						</Text>
					</div>
					{
						isManualLockTypeSelected
							? <DateToLockComponent/>
							: <PeriodToLockComponent/>
					}
					<RadioGroup
						items={LOCK_TYPE_RADIO_GROUP_OPTIONS}
						value={lockType}
						onValueChange={(nextLockType: LockType) => {
							setLockType(nextLockType);

							resetPeriodToLock();

							resetDateToLock();
						}}
						cx={styles.lockTypeRadioGroup}
					/>
					<Text
						fontSize="14"
						lineHeight="18"
						size="18"
					>
						Find out more about the validation in
						{" "}
						<a
							href="https://kb.epam.com/pages/viewpage.action?pageId=370345308#Manager-Autoreportinglock"
							target="_blank"
							rel="noreferrer"
						>
							our guide
						</a>.
					</Text>
				</div>

				<ModalFooter
					borderTop={true}
					rawProps={{
						"data-name": toDataAttribute("Modal window footer"),
					}}
				>
					<FlexSpacer/>
					<FlexRow
						spacing={null}
						columnGap="12"
					>
						<Button
							caption="Cancel"
							color="gray"
							fill="white"
							onClick={closeModalWindow}
						/>
						<Button
							caption="Validate"
							color="grass"
							isDisabled={isLockButtonDisabled}
							onClick={lockReporting}
						/>
					</FlexRow>
				</ModalFooter>
			</ModalWindow>
		</ModalBlocker>
	);
};

export {
	LockReportingModalWindow,
	LockType,

	type LockReportingModalWindowSuccessResult,
};
