/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as NotificationWarningIcon,
} from "@epam/assets/icons/common/notification-warning-fill-18.svg";
import {
	Tooltip,
} from "@epam/loveship";

import styles from "./NoPositionIcon.module.css";

const NoPositionIcon = () => {
	return (
		<div className={styles["no-position-icon"]}>
			<a
				target="_blank"
				className="link"
				rel="noopener noreferrer"
				href="https://kb.epam.com/pages/viewpage.action?pageId=632884240#Release1.17.3(10July2018)-Incorrectworklogs"
			>
				<Tooltip
					content={(
						<span>
							There are worklogs that do not meet Time requirements anymore. Please correct the issues using Manage worklogs option.  Click on this icon to learn more.
						</span>
					)}
					color="white"
				>
					<NotificationWarningIcon
						width={16}
						height={16}
						data-name="notificationWarningIcon"
					/>
				</Tooltip>
			</a>
		</div>
	);
};

export default NoPositionIcon;
