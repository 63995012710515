/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	DataTableRow,
} from "@epam/loveship";
import classNames from "classnames";
import includes from "lodash/includes";
import isNil from "lodash/isNil";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
} from "react";

import {
	type NavigationDataColumns,
	type NavigationDataRow,
} from "components/projects-billing/components/header/project-navigation/types";
import {
	removePostfixFromUnitId,
} from "components/projects-billing/components/header/project-navigation/utils/remove-postfix-from-unit-id";
import {
	type ProjectStructureUnitIds,
} from "models/project-structure/types";
import {
	type ProjectName,
} from "models/projects/types";

import {
	UnitPath,
} from "./components/unit-path/unit-path";

import styles from "./project-row.module.css";

interface ProjectRowProps {
	row: NavigationDataRow;
	columns: NavigationDataColumns;
	projectName: ProjectName;
	favoriteIds?: ProjectStructureUnitIds;
}

const ProjectRow: FC<ProjectRowProps> = ({
	row,
	columns,
	projectName,
	favoriteIds,
}) => {
	const name = row.value?.name;
	const path = row.value?.path ?? null;
	const rootId = row.path?.at(0)?.id ?? "";
	const unitId = row.value?.unitId ?? "";
	const parentId = row.value?.parentId;

	const isCurrentProject = projectName === name;

	const isFavoriteSection = !isUndefined(favoriteIds);

	const isFavorite = !isNil(parentId)
		? includes(favoriteIds, removePostfixFromUnitId(rootId))
		: includes(favoriteIds, removePostfixFromUnitId(unitId));

	return (
		<div
			className={
				classNames(
					styles.row,
					{
						[styles.unfavorite]: (
							isFavoriteSection
							&& !isFavorite
						),
					},
				)
			}
		>
			<UnitPath path={path}/>
			<DataTableRow
				key={row.rowKey}
				borderBottom={null}
				columns={columns}
				cx={
					classNames({
						[styles.currentProject]: isCurrentProject,
					})
				}
				{...row}
			/>
		</div>
	);
};

export {
	ProjectRow,
};
