/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	addAllItemsToArray,
	deleteItemFromArray,
	removeAllItemsFromArray,
	updateWithFilteredValues,
} from "../../../common-components/multiple-choice/actionCreators";
import {
	MULTIPLE_CHOICE_SET,
} from "../../../common-components/multiple-choice/actions";
import {
	getArray,
} from "../../../common-components/multiple-choice/utils";
import {
	MULTIPLE_DASHBOARD_EMPLOYEES_NAME,
	MULTIPLE_DASHBOARD_PROJECTS_NAME,
} from "../../constants";

const addEmployeeToUnlock = (id) => {
	return {
		promise(dispatch, getState) {
			return Promise.resolve()
				.then(() => {
					const payload = {
						projects: [],
						employees: [
							...getArray(getState(), MULTIPLE_DASHBOARD_EMPLOYEES_NAME),
							id,
						],
					};

					dispatch({
						type: MULTIPLE_CHOICE_SET,
						payload,
					});
				});
		},
	};
};

const removeEmployeeToUnlock = (id) => {
	return deleteItemFromArray(id, MULTIPLE_DASHBOARD_EMPLOYEES_NAME);
};

const removeFilteredEmployeesToUnlock = (arr) => {
	return updateWithFilteredValues(arr, MULTIPLE_DASHBOARD_EMPLOYEES_NAME);
};

const selectAllEmployeesToUnlock = ({
	projectId,
	filteredEmployees,
	isEmployeeDailyView,
}) => {
	return {
		promise(dispatch, getState) {
			return Promise.resolve()
				.then(() => {
					const project = getState().projectsDashboard.projects[projectId];
					const checkedEmployees = isEmployeeDailyView
						? project.employees
						: filteredEmployees;
					const payload = {
						projects: [],
						employees: Object.keys(checkedEmployees),
					};

					dispatch({
						type: MULTIPLE_CHOICE_SET,
						payload,
					});
				});
		},
	};
};

const deselectAllEmployeesToUnlock = () => {
	return removeAllItemsFromArray(MULTIPLE_DASHBOARD_EMPLOYEES_NAME);
};

const addProjectToLock = (id) => {
	return {
		promise(dispatch, getState) {
			return Promise.resolve()
				.then(() => {
					const payload = {
						projects: [
							...getArray(getState(), MULTIPLE_DASHBOARD_PROJECTS_NAME),
							id,
						],
						employees: [],
					};

					dispatch({
						type: MULTIPLE_CHOICE_SET,
						payload,
					});
				});
		},
	};
};

const removeProjectToLock = (id) => {
	return deleteItemFromArray(id, MULTIPLE_DASHBOARD_PROJECTS_NAME);
};

const selectAllProjectsToLock = () => {
	return addAllItemsToArray(
		MULTIPLE_DASHBOARD_PROJECTS_NAME,
		(getState) => {
			const store = getState();

			return store.projectsDashboard.showedProjects.filter((uid) => {
				return (
					store.projectsDashboard.projects[uid].hasBillingLockPermission
					|| store.projectsDashboard.projects[uid].hasReportingLockPermission
				);
			});
		},
	);
};

const deselectProjectsToLock = () => {
	return removeAllItemsFromArray(MULTIPLE_DASHBOARD_PROJECTS_NAME);
};

export {
	addEmployeeToUnlock,
	removeEmployeeToUnlock,
	removeFilteredEmployeesToUnlock,
	selectAllEmployeesToUnlock,
	deselectAllEmployeesToUnlock,
	addProjectToLock,
	removeProjectToLock,
	selectAllProjectsToLock,
	deselectProjectsToLock,
};
