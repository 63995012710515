/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	useCallback,
} from "react";
import {
	useDispatch,
	useSelector,
} from "react-redux";
import {
	useParams,
} from "react-router-dom";

import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	PageActionsButton,
} from "pages/components/action-panel-components/page-actions-button/page-actions-button";
import {
	Menu,
} from "pages/components/menu/menu";
import {
	MenuOption,
} from "pages/components/menu/option";
import {
	SubMenu,
} from "pages/components/menu/sub-menu";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	generatePackagesPageUrl,
} from "pages/utils/generate-packages-page-url";
import {
	generateProjectTMSLogPageUrl,
} from "pages/utils/generate-project-tms-log-page-url";
import {
	useUserName,
} from "store/slices/application-info/selectors";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	MARKUP_ACTION_TYPE,
} from "../../../../consts";
import {
	lockBilling,
	lockReporting,
	removeMarkupStatus,
	setMarkupStatus,
	unlockBillingPeriod,
	unlockReportingPeriod,
	updateProjects,
} from "../../../actionCreators";
import {
	getFilteredEmployeesByPositionsObj,
} from "../../../utils";
import {
	withProjectDetails,
} from "../../header/with-project-details/with-project-details";
import SendMailMenu from "../../send-mail-menu";
import {
	LOCK_TYPE,
} from "./constants";
import {
	createRequestBillingUnlock,
	generateExcel,
	getReportingAndBillingLocksType,
	onBillingLock,
	onBillingUnlock,
	onMarkupStatusProject,
	onReportingLock,
	onReportingUnlock,
} from "./utils";

import styles from "./three-dots-menu.module.css";

/**
 * @typedef {import("store").RootState} RootState
*/

const ThreeDotsMenu = () => {
	const dispatch = useDispatch();

	const setIsLoading = useCallback(
		(value) => {
			if (value) {
				dispatch(showSpinner());
			} else {
				dispatch(hideSpinner());
			}
		},
		[
			dispatch,
		],
	);

	const {
		projectId,
		fromDate,
		toDate,
		viewMode,
	} = useParams();

	const isNewPermissionSchemeEnabledBase = useSelector((
		/** @type {RootState} */
		state,
	) => {
		return state.applicationInfo.cpeBoard.projectsEmployeeDaily;
	});

	const isNewPermissionSchemeEnabled = (
		isNewPermissionSchemeEnabledBase
		&& viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
	);

	const mainUserFullName = useUserName();

	const project = useSelector((
		/** @type {RootState} */
		state,
	) => {
		return state.projectsDashboard.projects[projectId];
	});

	const getEmployeesForSendMailMenu = () => {
		const {
			employeesFromHeader,
		} = project;

		if (viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY) {
			return employeesFromHeader;
		}

		const filteredEmployeesByPositionsObj = getFilteredEmployeesByPositionsObj(project);
		const filteredEmployees = Object.keys(filteredEmployeesByPositionsObj);

		return employeesFromHeader.filter((emp) => {
			return filteredEmployees.includes(emp.uid);
		});
	};

	const hasBillingLockPermission = project.hasBillingLockPermission;
	const hasReportingLockPermission = project.hasReportingLockPermission;
	const hasMarkupStatusesActions = Boolean(
		Object.keys(project?.markupStatusesActions ?? {}).length,
	);

	const summary = viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
		? project.employeesSummary
		: project.positionsSummary;
	const {
		billingLockType,
	} = getReportingAndBillingLocksType(summary);
	const changeBillingLockIsEnabled = (
		hasBillingLockPermission
		|| (
			hasReportingLockPermission
			&& billingLockType !== LOCK_TYPE.UNLOCKED
		)
	);

	const selector = "three-dots-menu-0";

	return (
		<Menu
			placement="bottom-end"
			rawProps={{
				"data-name": selector,
			}}
			renderOptions={() => {
				const menuOptions = [];

				const canViewPackagesPage = project.hasTimePackagePermission;

				if (canViewPackagesPage) {
					menuOptions.push(
						<MenuOption
							key="Go to packages"
							caption="Go to packages"
							href={
								generatePackagesPageUrl({
									projectId: project.id,
									periodStartDate: fromDate,
									periodEndDate: toDate,
								})
							}
							target="_blank"
							onClick={() => {
								window.dataLayer.push({
									event_name: "3dot_tab",
									event_action: "go to packages",
									event: "autoevent",
								});
							}}
						/>,
					);
				}

				menuOptions.push(
					<SubMenu
						key="Download report"
						renderOptions={() => {
							return [
								<MenuOption
									key="Worklogs report"
									caption="Worklogs report"
									onClick={
										generateExcel({
											baseUrl: `dashboard/excel/project/${project.id}/worklog`,
											showPeriod: true,
											project,
											fromDate,
											toDate,
											viewMode,
											sendDataGA: () => {
												window.dataLayer.push({
													event_name: "3dot_tab",
													event_action: "download report",
													event_text: "worklog",
													event: "autoevent",
												});
											},
										})
									}
								/>,
								<MenuOption
									key="Project report"
									caption="Project report"
									onClick={
										generateExcel({
											baseUrl: `dashboard/excel/project/${project.id}`,
											showPeriod: false,
											project,
											fromDate,
											toDate,
											viewMode,
											sendDataGA: () => {
												window.dataLayer.push({
													event_name: "3dot_tab",
													event_action: "download report",
													event_text: "project",
													event: "autoevent",
												});
											},
										})
									}
								/>,
							];
						}}
					>
						{(props) => {
							return (
								<MenuOption
									caption="Download report"
									isSubMenuButton={true}
									{...props}
								/>
							);
						}}
					</SubMenu>,
				);

				if (
					hasMarkupStatusesActions
					&& viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
				) {
					menuOptions.push(
						<SubMenu
							key="Manage statuses"
							renderOptions={() => {
								/** @type {JSX.Element[]} */
								const subMenuOptions = [];

								if (project.markupStatusesActions[MARKUP_ACTION_TYPE.APPLY]) {
									subMenuOptions.push(
										<MenuOption
											key="Set status"
											caption="Set status"
											onClick={
												onMarkupStatusProject(
													MARKUP_ACTION_TYPE.APPLY,
													project,
													fromDate,
													toDate,
													setIsLoading,
													(...props) => {
														window.dataLayer.push({
															event_name: "3dot_tab",
															event_action: "set status",
															event: "autoevent",
														});

														return dispatch(
															setMarkupStatus(
																isNewPermissionSchemeEnabled,
																...props,
															),
														);
													},
												)
											}
										/>,
									);
								}

								if (project.markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE]?.length) {
									subMenuOptions.push(
										<MenuOption
											key="Remove status"
											caption="Remove status"
											onClick={
												onMarkupStatusProject(
													MARKUP_ACTION_TYPE.REMOVE,
													project,
													fromDate,
													toDate,
													setIsLoading,
													(...props) => {
														window.dataLayer.push({
															event_name: "3dot_tab",
															event_action: "remove status",
															event: "autoevent",
														});

														return dispatch(
															removeMarkupStatus(
																isNewPermissionSchemeEnabled,
																...props,
															),
														);
													},
												)
											}
										/>,
									);
								}

								return subMenuOptions;
							}}
						>
							{(props) => {
								return (
									<MenuOption
										caption="Manage statuses"
										isSubMenuButton={true}
										{...props}
									/>
								);
							}}
						</SubMenu>,
					);
				}

				if (viewMode !== PROJECT_VIEW_MODE.EMPLOYEE_DAILY) {
					menuOptions.push(
						<SubMenu
							key="Change reporting lock"
							/*
									In order not to show the dropdown on hover we need to pass `false`.
									In order to show the content which is transmitted in renderOptions property
									we need to pass `undefined`.
									This is a way to enabled/disabled dropdown when the trigger is a hover.
								*/
							value={
								!hasReportingLockPermission
									? false
									: undefined
							}
							renderOptions={() => {
								return [
									<MenuOption
										key="Lock reporting"
										caption="Lock reporting"
										onClick={
											onReportingLock({
												project,
												summary,
												fromDate,
												toDate,
												setIsLoading,
												lockReportingAction: (...props) => {
													return dispatch(
														lockReporting(
															isNewPermissionSchemeEnabled,
															...props,
														),
													);
												},
												sendDataGA: () => {
													window.dataLayer.push({
														event_name: "3dot_tab",
														event_action: "lock reporting",
														event: "autoevent",
													});
												},
											})
										}
									/>,
									<MenuOption
										key="Unlock reporting"
										caption="Unlock reporting"
										onClick={
											onReportingUnlock({
												project,
												fromDate,
												toDate,
												setIsLoading,
												unlockReportingPeriodAction: (...props) => {
													return dispatch(
														unlockReportingPeriod(
															isNewPermissionSchemeEnabled,
															...props,
														),
													);
												},
												updateProjectsAction: (...props) => {
													return dispatch(
														updateProjects(
															isNewPermissionSchemeEnabled,
															...props,
														),
													);
												},
												sendDataGA: () => {
													window.dataLayer.push({
														event_name: "3dot_tab",
														event_action: "unlock reporting",
														event: "autoevent",
													});
												},
											})
										}
									/>,
								];
							}}
						>
							{(props) => {
								return (
									<MenuOption
										caption="Change reporting lock"
										isSubMenuButton={true}
										isDisabled={!hasReportingLockPermission}
										{...props}
									/>
								);
							}}
						</SubMenu>,
						<SubMenu
							key="Change billing lock"
							/*
									In order not to show the dropdown on hover we need to pass `false`.
									In order to show the content which is transmitted in renderOptions property
									we need to pass `undefined`.
									This is a way to enabled/disabled dropdown when the trigger is a hover.
								*/
							value={
								!changeBillingLockIsEnabled
									? false
									: undefined
							}
							renderOptions={() => {
								/** @type {JSX.Element[]} */
								const subMenuOptions = [];

								if (hasBillingLockPermission) {
									subMenuOptions.push(
										<MenuOption
											key="Lock billing"
											caption="Lock billing"
											onClick={
												onBillingLock({
													project,
													summary,
													fromDate,
													toDate,
													setIsLoading,
													lockBillingAction: (...props) => {
														return dispatch(
															lockBilling(
																isNewPermissionSchemeEnabled,
																...props,
															),
														);
													},
													sendDataGA: () => {
														window.dataLayer.push({
															event_name: "3dot_tab",
															event_action: "lock billing",
															event: "autoevent",
														});
													},
												})
											}
										/>,
										<MenuOption
											key="Unlock billing"
											caption="Unlock billing"
											onClick={
												onBillingUnlock({
													project,
													fromDate,
													toDate,
													setIsLoading,
													unlockBillingPeriodAction: (...props) => {
														return dispatch(
															unlockBillingPeriod(
																isNewPermissionSchemeEnabled,
																...props,
															),
														);
													},
													sendDataGA: () => {
														window.dataLayer.push({
															event_name: "3dot_tab",
															event_action: "unlock billing",
															event: "autoevent",
														});
													},
												})
											}
										/>,
									);
								}

								if (
									hasReportingLockPermission
									&& !hasBillingLockPermission
									&& billingLockType !== LOCK_TYPE.UNLOCKED
								) {
									subMenuOptions.push(
										<MenuOption
											key="Request billing unlock"
											caption="Request billing unlock"
											onClick={
												createRequestBillingUnlock(project, mainUserFullName, fromDate, toDate)
											}
										/>,
									);
								}

								return subMenuOptions;
							}}
						>
							{(props) => {
								return (
									<MenuOption
										caption="Change billing lock"
										isSubMenuButton={true}
										isDisabled={!changeBillingLockIsEnabled}
										{...props}
									/>
								);
							}}
						</SubMenu>,
					);
				}

				menuOptions.push(
					<SubMenu
						key="Send email"
						renderOptions={() => {
							return (
								<SendMailMenu
									kpsTeam={project.kpsTeam}
									employees={getEmployeesForSendMailMenu()}
									eventLocation="3dot_tab"
									className={styles.sendMailMenuWidget}
								/>
							);
						}}
						cx={styles.sendMailMenu}
					>
						{(props) => {
							return (
								<MenuOption
									caption="Send email"
									isSubMenuButton={true}
									{...props}
								/>
							);
						}}
					</SubMenu>,
				);

				const canViewTMSLogPage = project.hasTmsLogButtonPermission;

				if (canViewTMSLogPage) {
					menuOptions.push(
						<MenuOption
							key="Go to TMS Log"
							caption="Go to TMS Log"
							href={
								generateProjectTMSLogPageUrl({
									projectId: project.id,
								})
							}
							target="_blank"
							onClick={() => {
								window.dataLayer.push({
									event_name: "3dot_tab",
									event_action: "go to tms log",
									event: "autoevent",
								});
							}}
						/>,
					);
				}

				return menuOptions;
			}}
		>
			{(props) => {
				return (
					<PageActionsButton
						{...props}
						rawProps={{
							"data-name": toDataAttribute(`${selector} button`),
						}}
					/>
				);
			}}
		</Menu>
	);
};

export default withProjectDetails(ThreeDotsMenu);
