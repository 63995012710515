/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexRow,
	IconButton,
} from "@epam/loveship";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	memo,
	useCallback,
	useState,
} from "react";
import {
	useParams,
} from "react-router-dom";

import {
	ReactComponent as NavigationBackIcon,
} from "icons/navigation-back-24.svg";
import {
	type ProjectName,
} from "models/projects/types";
import {
	PROJECTS_BASE_URL,
} from "pages/constants/base-routes";
import {
	type WithClassName,
} from "types/common";

import {
	PROJECT_NAVIGATION_TAB_NAME_DEFAULT,
} from "./constants";
import {
	ProjectNavigationSidebar,
} from "./project-navigation-sidebar";
import {
	type UseProjectNavigationParams,
} from "./types";

import styles from "./project-navigation.module.css";

const DEFAULT_PROJECT_NAME = "My projects";

interface ProjectNavigationProps extends WithClassName {
	projectName?: ProjectName;
}

const ProjectNavigation = memo<ProjectNavigationProps>(
	({
		projectName: projectNameFromProps = "",
		className,
	}) => {
		const {
			projectId,
		} = useParams<UseProjectNavigationParams>();

		const isProjectIdDefined = !isUndefined(projectId);

		const projectName = isProjectIdDefined
			? projectNameFromProps
			: DEFAULT_PROJECT_NAME;

		const [
			isOpenNavigationSidebar,
			setIsOpenNavigationSidebar,
		] = useState(false);
		const [
			tabValue,
			setTabValue,
		] = useState(PROJECT_NAVIGATION_TAB_NAME_DEFAULT);

		const toggleProjectNavigationSidebar = useCallback(
			() => {
				setIsOpenNavigationSidebar((currentIsOpenNavigationSidebar) => {
					return !currentIsOpenNavigationSidebar;
				});

				setTabValue(PROJECT_NAVIGATION_TAB_NAME_DEFAULT);
			},
			[],
		);

		return (
			<FlexRow
				spacing={null}
				columnGap="18"
				cx={className}
			>
				{
					isProjectIdDefined
						? (
							<IconButton
								link={{
									pathname: PROJECTS_BASE_URL,
								}}
								icon={NavigationBackIcon}
								cx={styles.backLink}
							/>
						)
						: null
				}
				<Button
					rawProps={{
						"data-name": "GBU_hierarchy",
					}}
					caption={projectName}
					isDropdown={true}
					isOpen={isOpenNavigationSidebar}
					color="gray"
					fill="white"
					onClick={toggleProjectNavigationSidebar}
					cx={
						classNames(
							styles.button,
							{
								[styles.emptyProjectName]: (
									isProjectIdDefined
									&& isEmpty(projectName)
								),
							},
						)
					}
				/>
				<ProjectNavigationSidebar
					projectName={projectName}
					tabValue={tabValue}
					setTabValue={setTabValue}
					isOpen={isOpenNavigationSidebar}
					onCloseNavigationSidebar={toggleProjectNavigationSidebar}
				/>
			</FlexRow>
		);
	},
);

ProjectNavigation.displayName = "ProjectNavigation";

export {
	ProjectNavigation,
};
