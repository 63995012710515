/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	IconContainer,
	Text,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type ComponentProps,
	type FC,
} from "react";

import {
	type WithClassName,
} from "types/common";

import styles from "./no-results-block.module.css";

interface NoResultsBlockProps extends WithClassName {
	content: string | JSX.Element;
	icon: FC;
	textParams?: ComponentProps<typeof Text>;
}

const NoResultsBlock: FC<NoResultsBlockProps> = ({
	content,
	icon,
	className,
	textParams,
}) => {
	return (
		<div
			className={
				classNames(
					styles.container,
					className,
				)
			}
		>
			<Text {...textParams}>
				{content}
			</Text>
			<IconContainer
				cx={styles.icon}
				icon={icon}
			/>
		</div>
	);
};

export {
	NoResultsBlock,
};
