/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexRow,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	RadioInput,
	RangeDatePicker,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Component,
} from "react";

import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	UUI_PICKER_INPUT_WITH_DASHES_FORMAT,
} from "models/dates-and-time/constants";
import {
	formatDefaultDate,
	isDateAfter,
	isDateBefore,
} from "models/dates-and-time/utils/common";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./ExcelParamsModal.module.css";

class ExcelParamModals extends Component {
	state = {
		lockWithFilter: false,
		toDate: this.props.toDate,
		fromDate: this.props.fromDate,
		withFilter: this.props.filterChanged === undefined
			? true
			: this.props.filterChanged,
	};

	close = () => {
		this.props.success();
	};

	lockHandler = () => {
		this.props.success({
			fromDate: formatDefaultDate(this.state.fromDate),
			toDate: formatDefaultDate(this.state.toDate),
			withFilter: this.state.withFilter,
		});
	};

	onDatesChange = (dates) => {
		const {
			fromDate,
			toDate,
		} = this.props;

		let {
			withFilter,
			lockWithFilter,
		} = this.state;

		if (
			dates.from
			&& dates.to
			&& (
				isDateBefore({
					date: dates.to,
					dateToCompare: fromDate,
				})
				|| isDateAfter({
					date: dates.to,
					dateToCompare: toDate,
				})
				|| isDateBefore({
					date: dates.from,
					dateToCompare: fromDate,
				})
				|| isDateAfter({
					date: dates.from,
					dateToCompare: toDate,
				})
			)
		) {
			withFilter = false;

			lockWithFilter = true;
		} else {
			lockWithFilter = false;
		}

		this.setState({
			fromDate: dates.from,
			toDate: dates.to,
			withFilter,
			lockWithFilter,
		});
	};

	forWithFilterClick = () => {
		this.setState(() => {
			return {
				withFilter: true,
			};
		});
	};

	forWithoutFilterClick = () => {
		this.setState(() => {
			return {
				withFilter: false,
			};
		});
	};

	render() {
		const {
			header,
			viewMode,
			subheader,
			showPeriod,
			actionTitle,
			filterChanged,
			checkDateErrors,
			checkDateWarnings,
			filterVariantsExixts,
		} = this.props;
		const {
			fromDate,
			toDate,
			withFilter,
			lockWithFilter,
		} = this.state;
		const isEmployeeDailyView = (
			viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
			|| viewMode === PROJECT_VIEW_MODE.POSITION_BREAKDOWN
		);

		const errors = checkDateErrors(fromDate, toDate);
		const warnings = checkDateWarnings(fromDate, toDate);

		return (
			<ModalBlocker
				{...this.props}
			>
				<ModalWindow width="600">
					<ModalHeader
						rawProps={{
							"data-name": toDataAttribute("Modal window header"),
						}}
						onClose={this.close}
					>
						<span className={styles.header}>
							{header}
						</span>

						{
							subheader
								? (
									<p className={styles.subheader}>
										{subheader}
									</p>
								)
								: null
						}
					</ModalHeader>

					<div className={styles.body}>
						{
							showPeriod
								? (
									<RangeDatePicker
										format={UUI_PICKER_INPUT_WITH_DASHES_FORMAT}
										onValueChange={this.onDatesChange}
										value={{
											from: fromDate,
											to: toDate,
										}}
										isInvalid={
											errors
												? (
													errors.fromDateHasError
													|| errors.toDateHasError
												)
												: null
										}
									/>
								)
								: null
						}

						{
							errors
								? errors.descriptions.map((error, key) => {
									return (
										<p
											key={key}
											className={
												classNames(
													styles.tips,
													styles.error,
												)
											}
										>
											{error}
										</p>
									);
								})
								: null
						}

						{
							warnings
								? warnings.map((warning, key) => {
									return (
										<p
											key={key}
											className={styles.tips}
										>
											{warning}
										</p>
									);
								})
								: null
						}

						{
							filterVariantsExixts
								? (
									<>
										<FlexRow vPadding="12">
											<RadioInput
												value={withFilter}
												onValueChange={this.forWithFilterClick}
												isDisabled={
													!filterChanged
													|| lockWithFilter
												}
												label={
													`with applied ${
														isEmployeeDailyView
															? "employees"
															: "positions"
													} filters`
												}
											/>
										</FlexRow>
										<FlexRow vPadding="12">
											<RadioInput
												value={!withFilter}
												onValueChange={this.forWithoutFilterClick}
												label={
													`without applied ${
														isEmployeeDailyView
															? "employees"
															: "positions"
													} filters`
												}
											/>
										</FlexRow>
									</>
								)
								: (
									<FlexRow vPadding="12">
										{`Note that the report will be generated without applied ${
											isEmployeeDailyView
												? "employees"
												: "positions"
										} filters`}
									</FlexRow>
								)
						}
					</div>

					<ModalFooter
						rawProps={{
							"data-name": toDataAttribute("Modal window footer"),
						}}
					>
						<div className={styles["left-auto"]}/>

						<Button
							onClick={this.close}
							caption="Cancel"
							color="white"
							cx={
								classNames(
									styles.cancel,
									styles.button,
									styles.footer,
								)
							}
						/>

						<Button
							color="grass"
							caption={actionTitle}
							onClick={this.lockHandler}
							isDisabled={
								errors
									? (
										errors.fromDateHasError
										|| errors.toDateHasError
									)
									: null
							}
						/>
					</ModalFooter>
				</ModalWindow>
			</ModalBlocker>
		);
	}
}

export default ExcelParamModals;
