/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
*/
import {
	ReactComponent as favoriteIcon,
} from "@epam/assets/icons/common/fav-rates-star-18.svg";
import {
	ReactComponent as unpopularIcon,
} from "@epam/assets/icons/common/fav-rates-star-outline-18.svg";
import {
	ReactComponent as loaderIcon,
} from "@epam/assets/icons/loaders/circle_loader_30.svg";
import {
	FlexCell,
	FlexRow,
	IconContainer,
	Text,
} from "@epam/loveship";
import classNames from "classnames";
import {
	type FC,
	type SyntheticEvent,
	useState,
} from "react";
import {
	useSelector,
} from "react-redux";
import {
	Link,
} from "react-router-dom";

import {
	toggleFavoriteUnit,
} from "components/projects-billing/components/header/project-navigation/store/reducer";
import {
	removePostfixFromUnitId,
} from "components/projects-billing/components/header/project-navigation/utils/remove-postfix-from-unit-id";
import {
	ReactComponent as fileIcon,
} from "icons/file-12x16.svg";
import {
	ReactComponent as categoryIcon,
} from "icons/project-navigation-category-16x12.svg";
import {
	ReactComponent as companyIcon,
} from "icons/project-navigation-company-16x14.svg";
import {
	ReactComponent as globIcon,
} from "icons/project-navigation-globe-16.svg";
import {
	projectStructureApi,
} from "models/project-structure/api";
import {
	ProjectStructureUnitType,
} from "models/project-structure/constants";
import {
	type ProjectStructureUnit,
} from "models/project-structure/types";
import {
	generateProjectPageUrl,
} from "pages/utils/generate-project-page-url";
import {
	type RootState,
	useDispatch,
} from "store";

import {
	PROJECT_NAVIGATION_TAB_NAME,
} from "../../../../constants";

import styles from "./project-cell.module.css";

type NavigationTabIcon = Record<ProjectStructureUnitType, FC>;

const NAVIGATION_TYPE_ICON: NavigationTabIcon = {
	[ProjectStructureUnitType.ACCOUNT]: companyIcon,
	[ProjectStructureUnitType.GBU]: globIcon,
	[ProjectStructureUnitType.PROGRAM]: categoryIcon,
	[ProjectStructureUnitType.PROJECT]: fileIcon,
};

const getEventLocationGA = (
	currentTabName: PROJECT_NAVIGATION_TAB_NAME,
): string | undefined => {
	switch (currentTabName) {
		case PROJECT_NAVIGATION_TAB_NAME.STRUCTURE: {
			return "navigation_structure";
		}

		case PROJECT_NAVIGATION_TAB_NAME.FAVORITES: {
			return "navigation_favorites";
		}

		default: {
			return undefined;
		}
	}
};

interface ProjectCellProps {
	unitData: ProjectStructureUnit;
	tabName: PROJECT_NAVIGATION_TAB_NAME;
	shouldColorizeUnfavoriteUnits?: boolean;
}

const ProjectCell: FC<ProjectCellProps> = ({
	unitData,
	tabName,
	shouldColorizeUnfavoriteUnits,
}) => {
	const {
		type,
		name,
		unitId,
	} = unitData;

	const actualUnitId = removePostfixFromUnitId(unitId);

	const isFavoriteUnit = useSelector((state: RootState) => {
		return state.projectNavigation.favoriteUnitIds.includes(
			actualUnitId,
		);
	});

	const dispatch = useDispatch();

	const [
		isLoading,
		setIsLoading,
	] = useState(false);

	const [
		addProjectStructureUnitToFavorites,
	] = projectStructureApi.useAddProjectStructureUnitToFavoritesMutation();

	const [
		removeProjectStructureUnitFromFavorites,
	] = projectStructureApi.useRemoveProjectStructureUnitFromFavoritesMutation();

	const onCheckFavorite = async (
		event: SyntheticEvent<HTMLDivElement>,
	): Promise<void> => {
		event.preventDefault();

		event.stopPropagation();

		try {
			setIsLoading(true);

			if (isFavoriteUnit) {
				await removeProjectStructureUnitFromFavorites(actualUnitId)
					.unwrap();
			} else {
				await addProjectStructureUnitToFavorites(actualUnitId)
					.unwrap();
			}

			dispatch(toggleFavoriteUnit(unitId));

			setIsLoading(false);
		} catch {
			setIsLoading(false);
		}
	};

	const iconFavorite = isFavoriteUnit
		? favoriteIcon
		: unpopularIcon;
	const icon = isLoading
		? loaderIcon
		: iconFavorite;

	const isHiddenIcon = (
		!isFavoriteUnit
		&& !isLoading
	);

	const isUnfavoriteUnit = (
		!isFavoriteUnit
		&& shouldColorizeUnfavoriteUnits
	);

	const column = (
		<FlexCell width="100%">
			<FlexRow
				cx={
					classNames(
						styles.container,
						{
							[styles.unfavorited]: isUnfavoriteUnit,
						},
					)
				}
			>
				<IconContainer
					icon={NAVIGATION_TYPE_ICON[type]}
					cx={styles.projectNameIcon}
				/>
				<Text
					cx={styles.projectName}
					fontWeight="600"
				>
					{name}
				</Text>
				<IconContainer
					cx={
						classNames(
							{
								[styles.hiddenIcon]: isHiddenIcon,
							},
						)
					}
					icon={icon}
					onClick={onCheckFavorite}
				/>
			</FlexRow>
		</FlexCell>

	);

	return type === ProjectStructureUnitType.PROJECT
		? (
			<Link
				to={
					generateProjectPageUrl({
						projectId: actualUnitId,
					})
				}
				style={{
					display: "flex",
					width: "100%",
				}}
				onClick={() => {
					window.dataLayer.push({
						event_name: "project_selected",
						event_text: name,
						event_location: getEventLocationGA(tabName),
						event: "autoevent",
					});
				}}
			>
				{column}
			</Link>
		)
		: column;
};

export {
	ProjectCell,
};
