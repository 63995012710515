/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	IconContainer,
	Text,
} from "@epam/loveship";
import {
	type FC,
} from "react";

import {
	ReactComponent as warningIcon,
} from "icons/warning-18x15.svg";

import styles from "./search-results-warning.module.css";

const SearchResultsWarning: FC = () => {
	return (
		<div className={styles.container}>
			<IconContainer
				icon={warningIcon}
				cx={styles.icon}
			/>
			<Text cx={styles.text}>
				Showing the first 50 results. Try using different keywords
			</Text>
		</div>
	);
};

export {
	SearchResultsWarning,
};
