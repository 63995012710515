/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Checkbox,
	LinkButton,
	Text,
} from "@epam/loveship";
import {
	Component,
} from "react";
import {
	connect,
} from "react-redux";
import {
	withRouter,
} from "react-router-dom";

import {
	getProjectsCommonFilterValuesFromLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	svc,
} from "constants/services";
import {
	formatChooseDate,
	isDateAfter,
	isDateBefore,
	isEqualOrAfter,
	isEqualOrBefore,
} from "models/dates-and-time/utils/common";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import ChooseDate from "../../../common-components/choose-date-period/ChooseDate";
import {
	showMarkupActionsModal,
} from "../../../common-components/markups-actions/showMarkupActionsModal";
import MultipleChoicePanel from "../../../common-components/multiple-choice/MultipleChoicePanel";
import {
	getArray,
} from "../../../common-components/multiple-choice/utils";
import {
	displayErrorNotification,
	displaySuccessNotification,
} from "../../../common-components/notification/Notifications";
import {
	MARKUP_ACTION_TYPE,
	MARKUP_APPLIED_LEVEL,
	MARKUP_PERIOD_TYPE,
} from "../../../consts";
import {
	lockBilling,
	lockReporting,
	removeMarkupStatus,
	setMarkupStatus,
} from "../../actionCreators";
import {
	MULTIPLE_DASHBOARD_PROJECTS_NAME,
} from "../../constants";
import {
	mergeMarkupStatuses,
} from "../../utils";
import {
	deselectProjectsToLock,
	selectAllProjectsToLock,
} from "../multiple-choices/actionCreators";

import styles from "./LockPanel.module.css";

class SeveralProjectsLockPanel extends Component {
	onBillingLock = () => {
		const {
			projects,
			lockBilling,
			projectsData,
			match: {
				params: {
					fromDate: from,
					toDate: to,
				},
			},
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		svc.uuiModals.show((props) => {
			return (
				<ChooseDate
					{...props}
					header={`Lock billing for all (${projects.length}) projects`}
					subheader={
						projects
							.map((prId) => {
								return projectsData[prId].name;
							})
							.join("; ")
					}
					actionTitle="Lock"
					date={to}
					checkDate={this.checkDate}
				/>
			);
		})
			.then((data) => {
				if (
					data
					&& data.date
				) {
					window.dataLayer.push({
						event_name: "sticky_popup",
						event_action: "billing lock",
						event: "autoevent",
					});

					showSpinner();

					lockBilling(
						isNewPermissionSchemeEnabled,
						projects,
						data.date,
						from,
						to,
						showWithNpa,
						showWithVacation,
					)
						.then((res) => {
							if (
								!(
									res
									&& res.error
								)
							) {
								const message = `${projects.length} project(s) locked`;

								hideSpinner();

								displaySuccessNotification(message);
							} else {
								const message = res.errorMessage;

								hideSpinner();

								displayErrorNotification(message);
							}
						});
				}
			});
	};

	onReportingLock = () => {
		const {
			projects,
			projectsData,
			lockReporting,
			match: {
				params: {
					fromDate: from,
					toDate: to,
				},
			},
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		svc.uuiModals.show((props) => {
			return (
				<ChooseDate
					{...props}
					header={`Lock reporting for all (${projects.length}) projects`}
					checkDate={this.ReportingCheckDate}
					subheader={
						projects
							.map((prId) => {
								return projectsData[prId].name;
							})
							.join("; ")
					}
					actionTitle="Lock"
					date={to}
				/>
			);
		})
			.then((data) => {
				if (
					data
					&& data.date
				) {
					window.dataLayer.push({
						event_name: "sticky_popup",
						event_action: "reporting lock",
						event: "autoevent",
					});

					showSpinner();

					lockReporting(
						isNewPermissionSchemeEnabled,
						projects,
						data.date,
						from,
						to,
						showWithNpa,
						showWithVacation,
					)
						.then((res) => {
							hideSpinner();

							if (
								res
								&& res.error
							) {
								const message = res.errorMessage;

								displayErrorNotification(message);
							} else {
								const message = `${projects.length} project(s) locked`;

								displaySuccessNotification(message);
							}
						});
				}
			});
	};

	checkDate = (date) => {
		const res = [];
		const {
			projects,
			projectsData,
		} = this.props;
		const project = projectsData[projects[0]];

		if (!date) {
			res.push("Date is required.");
		} else {
			if (
				isDateBefore({
					date,
					dateToCompare: project.billingPastLimitDay,
				})
			) {
				res.push(`Red lock is already on ${formatChooseDate(project.billingPastLimitDay)}`);
			}

			if (isEqualOrAfter({
				date,
				dateToCompare: project.billingLimitDay,
			})) {
				res.push(`Project(s) can't be locked on or after ${formatChooseDate(project.billingLimitDay)}`);
			}
		}

		return res;
	};

	ReportingCheckDate = (date) => {
		const res = [];
		const {
			projects,
			projectsData,
		} = this.props;
		const project = projectsData[projects[0]];

		if (!date) {
			res.push("Date is required.");
		} else {
			if (
				isDateAfter({
					date: project.billingLockDay,
					dateToCompare: date,
				})
			) {
				res.push(`Project is already locked to ${formatChooseDate(project.billingLockDay)}`);
			}

			if (
				isEqualOrBefore({
					date: project.reportingLimitDay,
					dateToCompare: date,
				})
			) {
				res.push(`Project can't be locked on or after ${formatChooseDate(project.reportingLimitDay)}`);
			}
		}

		return res;
	};

	selectAll = () => {
		const {
			selectAllProjectsToLock,
		} = this.props;

		selectAllProjectsToLock();
	};

	deselectAll = () => {
		const {
			deselectProjectsToLock,
		} = this.props;

		deselectProjectsToLock();
	};

	checkPermission = () => {
		const {
			projects,
			projectsData,
		} = this.props;
		let billingLockPermission = false;
		let reportingLockPermission = false;

		projects.forEach((projId) => {
			billingLockPermission = (
				billingLockPermission
				|| (
					projectsData[projId]?.hasBillingLockPermission
					?? false
				)
			);

			reportingLockPermission = (
				reportingLockPermission
				|| (
					projectsData[projId]?.hasReportingLockPermission
					?? false
				)
			);
		});

		return {
			reportingLockPermission,
			billingLockPermission,
		};
	};

	onMarkupStatus = (action) => {
		const {
			projects,
			projectsData,
			setMarkupStatus,
			removeMarkupStatus,
			match: {
				params: {
					fromDate: dashboardFrom,
					toDate: dashboardTo,
				},
			},
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		const onMarkupStatusCallback = ({
			markupLevel,
			statusId,
			from,
			to,
		}) => {
			const epmloyeeIds = projects.reduce((prevEmployesIds, projectId) => {
				return new Set(
					[
						...prevEmployesIds.values(),
						...Object.keys(projectsData[projectId].employees),
					],
				);
			}, new Set());

			showSpinner();

			let eventActionGA;

			if (action === MARKUP_ACTION_TYPE.APPLY) {
				eventActionGA = "status lock";

				setMarkupStatus(
					isNewPermissionSchemeEnabled,
					markupLevel,
					projects,
					epmloyeeIds.values(),
					statusId,
					MARKUP_PERIOD_TYPE.PERIOD,
					from,
					to,
					dashboardFrom,
					dashboardTo,
					showWithNpa,
					showWithVacation,
				)
					.then(() => {
						hideSpinner();
					});
			} else {
				eventActionGA = "status unlock";

				removeMarkupStatus(
					isNewPermissionSchemeEnabled,
					markupLevel,
					projects,
					epmloyeeIds.values(),
					statusId,
					MARKUP_PERIOD_TYPE.PERIOD,
					from,
					to,
					dashboardFrom,
					dashboardTo,
					showWithNpa,
					showWithVacation,
				)
					.then(() => {
						hideSpinner();
					});
			}

			window.dataLayer.push({
				event_name: "sticky_popup",
				event_action: eventActionGA,
				event: "autoevent",
			});
		};

		let avalableStatuses = [];

		if (action === MARKUP_ACTION_TYPE.REMOVE) {
			const applayedStatuses = new Set();

			projects.forEach((projectId) => {
				const project = projectsData[projectId];

				Object.values(project.employees)
					.forEach((employee) => {
						if (
							employee.markedWorklogInfo
							&& employee.markedWorklogInfo.length
						) {
							employee.markedWorklogInfo.forEach((worklog) => {
								applayedStatuses.add(worklog.statusId);
							});
						}
					});
			});

			avalableStatuses = mergeMarkupStatuses(projectsData, projects)[action].reduce((statuses, status) => {
				if (applayedStatuses.has(status.id)) {
					return [
						...statuses,
						status,
					];
				}

				return statuses;
			}, []);
		} else {
			avalableStatuses = mergeMarkupStatuses(projectsData, projects)[action];
		}

		showMarkupActionsModal({
			id: projects,
			action,
			markupLevel: MARKUP_APPLIED_LEVEL.PROJECT,
			statuses: avalableStatuses,
			callback: onMarkupStatusCallback,
			filterDates: {
				from: dashboardFrom,
				to: dashboardTo,
			},
		});
	};

	render() {
		const {
			projects,
			hasStatusesForRemove,
			hasMarkupActionsForApplay,
			...resProps
		} = this.props;
		const perm = this.checkPermission();

		return (
			<MultipleChoicePanel
				arr={projects}
				className="lock-panel"
				{...resProps}
			>
				<Checkbox
					onValueChange={this.deselectAll}
					value={
						Boolean(
							projects
							&& projects.length,
						)
					}
					label={`${projects.length} project(s) selected`}
				/>

				<div
					style={{
						marginLeft: "12px",
					}}
				/>

				<LinkButton
					color="sky"
					fontSize="14"
					onClick={this.selectAll}
					caption="Select all displayed"
					cx={styles["link-button"]}
				/>

				<div
					style={{
						marginLeft: "auto",
					}}
				/>

				{
					(
						hasMarkupActionsForApplay
						|| hasStatusesForRemove
					)
						? (
							<>
								<Text cx={styles.title}>
									Worklog status:
								</Text>

								{
									hasMarkupActionsForApplay
										? (
											<LinkButton
												cx={
													hasStatusesForRemove
														? styles["link-button"]
														: styles.divider
												}
												caption="Set"
												color="sky"
												fontSize="14"
												onClick={() => {
													this.onMarkupStatus(MARKUP_ACTION_TYPE.APPLY);
												}}
												rawProps={{
													"data-name": toDataAttribute("Set worklog status button"),
												}}
											/>
										)
										: null
								}

								{
									hasStatusesForRemove
										? (
											<>
												{
													hasMarkupActionsForApplay
														? <div className={styles.divider}/>
														: null
												}

												<LinkButton
													cx={styles["markup-button"]}
													caption="Remove"
													color="sky"
													fontSize="14"
													onClick={() => {
														this.onMarkupStatus(MARKUP_ACTION_TYPE.REMOVE);
													}}
													rawProps={{
														"data-name": toDataAttribute("Remove worklog status button"),
													}}
												/>
											</>
										)
										: null
								}
							</>
						)
						: null
				}

				{
					perm.billingLockPermission
						? (
							<LinkButton
								color="sky"
								fontSize="14"
								caption="Set Billing Lock"
								onClick={this.onBillingLock}
								cx={styles["link-button"]}
								rawProps={{
									"data-name": toDataAttribute("Lock billing button"),
								}}
							/>
						)
						: null
				}

				{
					perm.reportingLockPermission
						? (
							<LinkButton
								color="sky"
								fontSize="14"
								caption="Set Reporting Lock"
								cx={styles["link-button"]}
								onClick={this.onReportingLock}
								rawProps={{
									"data-name": toDataAttribute("Lock reporting button"),
								}}
							/>
						)
						: null
				}
			</MultipleChoicePanel>
		);
	}
}

const mapStateToProps = (state) => {
	const projectsData = state.projectsDashboard.projects;
	const projects = getArray(state, MULTIPLE_DASHBOARD_PROJECTS_NAME);

	let hasStatusesForRemove = false;
	let hasMarkupActionsForApplay = false;

	if (
		projects
		&& projects.length
	) {
		projects.some((projectId) => {
			const project = projectsData[projectId];

			if (
				project
				&& project.markupStatusesActions
				&& project.markupStatusesActions[MARKUP_ACTION_TYPE.APPLY]
				&& project.markupStatusesActions[MARKUP_ACTION_TYPE.APPLY].length
			) {
				hasMarkupActionsForApplay = true;
			}

			return hasMarkupActionsForApplay;
		});

		projects.some((projectId) => {
			const project = projectsData[projectId];

			if (
				project
				&& project.markupStatusesActions
				&& project.markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE]
				&& project.markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE].length
				&& Object.values(project.employees).length
			) {
				Object.values(project.employees)
					.some((employee) => {
						if (
							employee.markedWorklogInfo
							&& employee.markedWorklogInfo.length
						) {
							hasStatusesForRemove = employee.markedWorklogInfo.some((worklog) => {
								return project.markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE].find((status) => {
									return status.id === worklog.statusId;
								});
							});
						}

						return hasStatusesForRemove;
					});
			}

			return hasStatusesForRemove;
		});
	}

	return {
		projectsData,
		projects,
		hasStatusesForRemove,
		hasMarkupActionsForApplay,
	};
};

const mapDispatchToProps = {
	deselectProjectsToLock,
	selectAllProjectsToLock,
	lockBilling,
	lockReporting,
	setMarkupStatus,
	removeMarkupStatus,
	hideSpinner,
	showSpinner,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeveralProjectsLockPanel));
