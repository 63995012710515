/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FilterControlRow,
} from "components/common-components/filter-wrapper/filter-panel/filter-control-row";
import {
	FilterWrapper,
} from "components/common-components/filter-wrapper/FilterWrapper";
import {
	taggedDropdownValueChanged,
} from "components/common-components/filter-wrapper/utils";
import {
	DEFAULT_PROJECTS_DASHBOARD_FILTER_VALUES,
	PROJECTS_DASHBOARD_FILTER_VALUES_LOCAL_STORAGE_KEY,
} from "components/projects-billing/constants";
import {
	getHasProjectsDashboardFilterBeenChanged,
	getProjectsDashboardFilterValuesFromLocalStorage,
	updateProjectsDashboardFilterValuesInLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	Combobox,
} from "pages/components/combobox/combobox";

import {
	ProjectsCommonFilter,
} from "../../projects-common-filter/projects-common-filter";

const getProjectFilterData = (selCustomers, selProjects, projects) => {
	const selProjectsIds = selProjects.map(({
		id,
	}) => {
		return id;
	});
	const selCustomersIds = selCustomers.map(({
		id,
	}) => {
		return id;
	});

	if (!selCustomersIds.length) {
		return projects;
	}

	return projects.filter(({
		id,
		customerId,
	}) => {
		return (
			selProjectsIds.includes(id)
			|| selCustomersIds.includes(customerId)
		);
	});
};

const DashboardFilter = ({
	applyFilter,
	projects,
	customers,
	projectBillingTypes,
}) => {
	return (
		<FilterWrapper
			filterChanged={getHasProjectsDashboardFilterBeenChanged}
			filterValuesDefault={DEFAULT_PROJECTS_DASHBOARD_FILTER_VALUES}
			localStorageKey={PROJECTS_DASHBOARD_FILTER_VALUES_LOCAL_STORAGE_KEY}
			applyFilter={applyFilter}
			getFilterValuesFromLocalStorage={getProjectsDashboardFilterValuesFromLocalStorage}
			updateFilterValuesInLocalStorage={updateProjectsDashboardFilterValuesInLocalStorage}
			render={(
				{
					projects: selectedProjects,
					customers: selectedCustomers,
					projectBillingTypes: selectedProjectBillingTypes,
					showWithVacation: selectedShowWithVacation,
					showWithNpa: selectedShowWithNpa,
					showWithExcludedHours: selectedShowWithExcludedHours,
				},
				setFilterProperty,
			) => {
				return (
					<>
						<FilterControlRow
							title="Customers"
						>
							<Combobox
								items={customers}
								value={selectedCustomers}
								onValueChange={
									taggedDropdownValueChanged(
										"customers",
										setFilterProperty,
									)
								}
								entityName="customer"
							/>
						</FilterControlRow>

						<FilterControlRow
							title="Projects"
						>
							<Combobox
								items={
									getProjectFilterData(
										selectedCustomers,
										selectedProjects,
										projects,
									)
								}
								value={selectedProjects}
								onValueChange={
									taggedDropdownValueChanged(
										"projects",
										setFilterProperty,
									)
								}
								entityName="project"
							/>
						</FilterControlRow>

						<FilterControlRow
							title="Project billing type"
						>
							<Combobox
								items={projectBillingTypes}
								value={selectedProjectBillingTypes}
								onValueChange={
									taggedDropdownValueChanged(
										"projectBillingTypes",
										setFilterProperty,
									)
								}
								entityName="project billing type"
							/>
						</FilterControlRow>

						<FilterControlRow
							title="Additional information"
						>
							<ProjectsCommonFilter
								setFilterProperty={setFilterProperty}
								showWithVacation={selectedShowWithVacation}
								showWithNpa={selectedShowWithNpa}
								showWithExcludedHours={selectedShowWithExcludedHours}
							/>
						</FilterControlRow>
					</>
				);
			}}
		/>
	);
};

export {
	DashboardFilter,
};
