/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Tooltip,
} from "@epam/loveship";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import map from "lodash/map";
import reduce from "lodash/reduce";
import sumBy from "lodash/sumBy";
import values from "lodash/values";
import {
	Component,
} from "react";
import {
	connect,
} from "react-redux";
import {
	Link,
	withRouter,
} from "react-router-dom";

import {
	applyProjectPositionFilter,
	getProjectDetailsData,
} from "components/projects-billing/actionCreators";
import {
	getProjectsCommonFilterValuesFromLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	showSelectedProjectsHoursTotalSelector,
} from "components/projects-billing/selectors";
import {
	getCriticality,
} from "components/tracked-components/utils";
import {
	sortMarkupStatuses,
} from "components/utils";
import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	ReactComponent as MailIcon,
} from "icons/external-link-email-24.svg";
import {
	ExternalLinksTooltipColumnInfo,
} from "pages/components/external-links-tooltip/column-info/column-info";
import {
	ExternalLinksTooltipColumnLinks,
} from "pages/components/external-links-tooltip/column-links/column-links";
import {
	ExternalLinksTooltipContainer,
} from "pages/components/external-links-tooltip/container/container";
import {
	ExternalLinkBillingService,
} from "pages/components/external-links-tooltip/external-link-billing-service/external-link-billing-service";
import {
	ExternalLinkDeliveryCentral,
} from "pages/components/external-links-tooltip/external-link-delivery-central/external-link-delivery-central";
import {
	ExternalLinkHint,
} from "pages/components/external-links-tooltip/external-link-hint/external-link-hint";
import {
	ExternalLinkPackages,
} from "pages/components/external-links-tooltip/external-link-packages/external-link-packages";
import {
	ExternalLinkProjects,
} from "pages/components/external-links-tooltip/external-link-projects/external-link-projects";
import {
	ExternalLinkStaffingProject,
} from "pages/components/external-links-tooltip/external-link-staffing-project/external-link-staffing-project";
import {
	ExternalLinkTelescopeProject,
} from "pages/components/external-links-tooltip/external-link-telescope-project/external-link-telescope-project";
import {
	ExternalLinkTMSLog,
} from "pages/components/external-links-tooltip/external-link-tsm-log/external-link-tsm-log";
import {
	ExternalLink,
} from "pages/components/external-links-tooltip/external-link/external-link";
import {
	ExternalLinksTooltipInfoTextPrimary,
} from "pages/components/external-links-tooltip/info-text-primary/info-text-primary";
import {
	ExternalLinksTooltipInfoTextSecondary,
} from "pages/components/external-links-tooltip/info-text-secondary/info-text-secondary";
import {
	ExternalLinksTooltipRowDivider,
} from "pages/components/external-links-tooltip/row-divider/row-divider";
import {
	ExternalLinksTooltipRow,
} from "pages/components/external-links-tooltip/row/row";
import {
	ExternalLinksTooltip,
} from "pages/components/external-links-tooltip/tooltip/tooltip";
import {
	MarkupsByPeriods,
} from "pages/components/markups/markups-by-periods/markups-by-periods";
import {
	Menu,
} from "pages/components/menu/menu";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	getSortedMarkups,
} from "pages/utils/get-sorted-markups";
import {
	round,
	thousandSeparator,
} from "utilities/number";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	INCORRECT_WORKLOGS_LINK,
} from "../../constants";
import Hours from "../hours/Hours";
import HoursWithTotal from "../hours/HoursWithTotal";
import {
	removeFilteredEmployeesToUnlock,
} from "../multiple-choices/actionCreators";
import ProjectCheck from "../multiple-choices/ProjectCheck";
import NoPositionIcon from "../no-position-icon/NoPositionIcon";
import SendMailMenu from "../send-mail-menu";
import BreakdownSelector from "./breakdown-selector/BreakdownSelector";
import LockedNumber from "./locked-number/LockedNumber";
import ShowMoreStatusesDropdown from "./show-more-statuses-dropdown/ShowMoreStatusesDropdown";
import ThreeDotsMenuForDashboard from "./three-dots-menu/ThreeDotsMenuForDashboard";

import styles from "./ProjectSummaryRow.module.css";
import stylesCommon from "components/projects-billing/ProjectCommon.module.css";

const MAX_STATUSES_ITEMS_SUMMARY_ROW = 2;

class ProjectSummaryRow extends Component {
	componentDidUpdate({
		project: prevProject,
	}) {
		const {
			project,
			removeFilteredEmployeesToUnlock,
		} = this.props;

		if (prevProject?.filteredEmployees?.length !== project?.filteredEmployees?.length) {
			removeFilteredEmployeesToUnlock(project.filteredEmployees);
		}
	}

	/*
		We need to disable the standard Link behavior for
		something that would not pass away when clicking on the "Show more" button.
	*/
	handleClickToProjectDetails = (e) => {
		const {
			isDetailsPage,
			project,
		} = this.props;

		if (!isDetailsPage) {
			window.dataLayer.push({
				event_name: "project_selected",
				event_text: project.name,
				event_location: "list",
				event: "autoevent",
			});
		}

		e.preventDefault();

		if (e.target.dataset.name === "notificationWarningIcon") {
			window.open(INCORRECT_WORKLOGS_LINK, "_target");

			return;
		}

		if (
			!e.target.parentNode.firstChild.textContent.includes("Show more")
			&& this.props.linkToProjectDetails
		) {
			this.props.history.push(this.props.linkToProjectDetails ?? "#");
		}
	};

	render() {
		const {
			isLast,
			project,
			isFirst,
			children,
			projectId,
			breakdownId,
			isDetailsPage,
			showTrackedMode,
			changeBreakdown,
			linkToProjectDetails,
			dashboardMarkupsByStatus,
			filterMarkupStatusValues,
			match: {
				params: {
					viewMode,
				},
			},
		} = this.props;

		if (!project) {
			return null;
		}

		const hasMarkupStatusesActions = !isEmpty(project.markupStatusesActions);

		/**
		 * `StatusId` can't be used here, because below we get `markupStatusIds` as
		 * keys of an object, which are always strings.
		 * @typedef {string} StatusIdString
		 */

		/**
		 * @typedef {StatusIdString[]} StatusIdsString
		 */

		/**
		 * @typedef {import("models/dates-and-time/types").Duration} Duration
		 */

		/** @type {Record<StatusIdString, Duration>} */
		const markupSummaries = project.markupSummaries ?? {};
		const isEmployeeDailyViewMode = (
			!isDetailsPage
			|| viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
		);
		const summary = isEmployeeDailyViewMode
			? project.employeesSummary
			: project.positionsSummary;

		const dashboardMarkupsArray = values(dashboardMarkupsByStatus);

		/** @type {import("pages/types/common").MarkupsWithDuration} */
		const markupsWithDuration = map(
			dashboardMarkupsArray,
			(markup) => {
				const {
					id: markupId,
				} = markup;

				return {
					id: markupId,
					name: markup.name,
					color: markup.color,
					duration: markupSummaries[markupId],
				};
			},
		);

		const sortedMarkupsWithDuration = getSortedMarkups(markupsWithDuration);

		const worklogsTotalDuration = summary.billable + summary.nonBillable;

		const markedWorklogsTotalDuration = sumBy(
			sortedMarkupsWithDuration,
			(markupWithDuration) => {
				return markupWithDuration.duration;
			},
		);

		const nonMarkedWorklogsDuration = (
			worklogsTotalDuration - markedWorklogsTotalDuration
		);

		/** @type {StatusIdsString} */
		const markupStatusIds = keys(markupSummaries);
		/** @type {StatusIdsString} */
		const filteredMarkupStatusIds = reduce(
			markupStatusIds,
			(statusIds, statusId) => {
				if (filterMarkupStatusValues[statusId]) {
					statusIds.push(statusId);
				}

				return statusIds;
			},
			[],
		);
		const sortedFilteredMarkupStatusIds = filteredMarkupStatusIds.sort(
			sortMarkupStatuses(dashboardMarkupsByStatus),
		);

		/**
		 * @param {number} index
		 * @returns {JSX.Element}
		 */
		const renderStatus = (index) => {
			const statusId = sortedFilteredMarkupStatusIds[index];
			const name = dashboardMarkupsByStatus[statusId].name;
			const markupDuration = markupSummaries[statusId];

			return (
				<div className={styles["status-row"]}>
					<div
						className={styles["status-name"]}
						title={name}
					>
						{name}
					</div>
					<div className={styles["status-duration"]}>
						{`${thousandSeparator(round(markupDuration))}h`}
					</div>
				</div>
			);
		};

		const reportedTotalHours = round(
			(summary?.totalWithoutNpa ?? 0)
			+ (summary?.npa ?? 0),
		);

		const {
			showWithNpa,
			showWithVacation,
			showWithExcludedHours,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		const canViewTMSLogPage = project.hasTmsLogButtonPermission;

		const canViewPackagesPage = project.hasTimePackagePermission;

		return (
			<div
				className={
					classNames(
						styles.projectRow,
						{
							[styles.isFirst]: isFirst,
							[styles.isLast]: isLast,
						},
						isDetailsPage
							? stylesCommon.detailsPage
							: stylesCommon.summaryPage,
					)
				}
			>
				<div
					className={
						classNames(
							styles["project-summary"],
							isFirst
								? styles["is-radius-top"]
								: "",
							isLast
								? styles["is-radius-bottom"]
								: "",
							linkToProjectDetails
								? styles.withHoverEffect
								: "",
						)
					}
				>
					{
						!isDetailsPage
							? (
								<ProjectCheck
									id={project.id}
									cx={styles["check-for-lock"]}
									disable={
										!(
											project.hasBillingLockPermission
											|| project.hasReportingLockPermission
											|| hasMarkupStatusesActions
										)
									}
								/>
							)
							: null
					}

					<Link
						to={linkToProjectDetails ?? "#"}
						style={{
							display: "flex",
							width: "100%",
						}}
						onClick={this.handleClickToProjectDetails}
					>
						<div
							className={
								classNames(
									styles.title,
									styles.cell,
									stylesCommon.cell,
								)
							}
						>
							<div className={styles["info-overlong"]}>
								<ExternalLinksTooltip
									content={(
										<ExternalLinksTooltipContainer>
											<ExternalLinksTooltipColumnInfo>
												<ExternalLinksTooltipInfoTextPrimary>
													{project.name}
												</ExternalLinksTooltipInfoTextPrimary>
												<ExternalLinksTooltipInfoTextSecondary>
													{project.customerName}
												</ExternalLinksTooltipInfoTextSecondary>
											</ExternalLinksTooltipColumnInfo>

											<ExternalLinksTooltipColumnLinks>
												<ExternalLinksTooltipRow>
													<ExternalLinkTelescopeProject
														projectId={projectId}
													/>
													<ExternalLinkStaffingProject
														projectId={projectId}
													/>
													<ExternalLinkProjects
														projectId={projectId}
													/>
													<ExternalLinkDeliveryCentral
														projectId={projectId}
													/>
													<ExternalLinkBillingService
														projectId={projectId}
													/>
												</ExternalLinksTooltipRow>

												<ExternalLinksTooltipRowDivider/>

												<ExternalLinksTooltipRow>
													{
														canViewTMSLogPage
															? (
																<ExternalLinkTMSLog
																	projectId={projectId}
																/>
															)
															: null
													}

													{
														canViewPackagesPage
															? (
																<ExternalLinkPackages
																	projectId={projectId}
																/>
															)
															: null
													}
													<div
														className={styles.externalLinksTooltipSendEmailDivider}
													/>
													<Menu
														placement="bottom-end"
														rawProps={{
															"data-name": toDataAttribute("Send email menu"),
														}}
														renderOptions={() => {
															return (
																<SendMailMenu
																	kpsTeam={project.kpsTeam}
																	employees={
																		project.employeesFromHeader
																		?? project.employees
																	}
																	eventLocation="3dot_tab"
																	className={styles.externalLinksTooltipSendEmailWidget}
																/>
															);
														}}
														cx={styles.externalLinksTooltipSendEmailMenu}
													>
														{(props) => {
															return (
																<ExternalLinkHint content="Create email">
																	{/*
																		This `div` is necessary for the tooltip to bind its `ref` to,
																		while the link has `ref` of the `Menu` component.
																	*/}
																	<div>
																		<ExternalLink
																			{...props}
																			color="night600"
																			icon={MailIcon}
																			rawProps={{
																				"data-name": toDataAttribute("Send email menu button"),
																			}}
																		/>
																	</div>
																</ExternalLinkHint>
															);
														}}
													</Menu>
												</ExternalLinksTooltipRow>
											</ExternalLinksTooltipColumnLinks>
										</ExternalLinksTooltipContainer>
									)}
								>
									<div>
										<div className={styles.name}>
											{project.name}
											{
												summary.hasIncorrectWorklogs
													? <NoPositionIcon/>
													: null
											}
										</div>
										<div
											className={styles["customer-name"]}
											title={project.customerName}
										>
											{project.customerName}
										</div>
									</div>
								</ExternalLinksTooltip>
							</div>
							<div className={stylesCommon["width-auto"]}/>
						</div>

						<HoursWithTotal
							className={
								classNames(
									stylesCommon.summary,
									stylesCommon.cell,
								)
							}
							hours={thousandSeparator(reportedTotalHours)}
							total={thousandSeparator(round(summary.normTotal))}
						>
							<span className="hint">
								Total time
							</span>
						</HoursWithTotal>

						{
							showTrackedMode
								? (
									<div
										className={
											classNames(
												stylesCommon.tracked,
												stylesCommon.cell,
											)
										}
									>
										<Tooltip
											content="Tracked time"
											placement="top"
											color="white"
										>
											<div
												className={
													classNames(
														project.tracked
															? stylesCommon[
																getCriticality(summary.trackedTime, reportedTotalHours)
															]
															: "",
														styles["summary-hours"],
													)
												}
											>
												<div className={styles["summary-hours"]}>
													{thousandSeparator(round(summary.trackedTime))}
													h
												</div>
											</div>
										</Tooltip>
									</div>
								)
								: null
						}

						<HoursWithTotal
							className={
								classNames(
									stylesCommon.billable,
									stylesCommon.cell,
								)
							}
							hours={thousandSeparator(round(summary.billable))}
							total={thousandSeparator(round(summary.normBillable))}
						>
							<span className="hint">
								Billable time
							</span>
						</HoursWithTotal>

						<HoursWithTotal
							className={
								classNames(
									stylesCommon["non-billable"],
									stylesCommon.cell,
								)
							}
							hours={thousandSeparator(round(summary.nonBillable))}
							total={thousandSeparator(round(summary.normNonBillable))}
						>
							<span className="hint">
								Non-billable time
							</span>
						</HoursWithTotal>

						<Hours
							className={
								classNames(
									stylesCommon.overtime,
									stylesCommon.cell,
								)
							}
							hours={thousandSeparator(round(summary.ovt))}
						>
							<span className="hint">
								Overtime
							</span>
						</Hours>

						<Hours
							className={
								classNames(
									stylesCommon.overreported,
									stylesCommon.cell,
								)
							}
							hours={thousandSeparator(round(summary.overreported))}
						>
							<span className="hint">
								Overreporting
							</span>
						</Hours>

						<Hours
							className={
								classNames(
									stylesCommon.gaps,
									stylesCommon.cell,
								)
							}
							hours={thousandSeparator(round(summary.gaps))}
						>
							<span className="hint">
								Gap time
							</span>
						</Hours>

						{
							showWithVacation
								? (
									<Hours
										className={
											classNames(
												stylesCommon.vac,
												stylesCommon.cell,
											)
										}
										hours={thousandSeparator(round(summary.vac))}
									>
										<span className="hint">
											Leave time
										</span>
									</Hours>
								)
								: null
						}

						{
							showWithNpa
								? (
									<Hours
										className={
											classNames(
												stylesCommon.npa,
												stylesCommon.cell,
											)
										}
										hours={thousandSeparator(round(summary.npa))}
									>
										<span className="hint">
											NPA time
										</span>
									</Hours>
								)
								: null
						}

						{
							showWithExcludedHours
								? (
									<div
										className={
											classNames(
												stylesCommon["excluded-hours"],
												stylesCommon.cell,
												styles.cell,
											)
										}
									>
										<Tooltip
											content="On-duty hours"
											placement="top"
											color="white"
										>
											<div>
												{thousandSeparator(round(summary.excludedHours))}
												h
											</div>
										</Tooltip>
									</div>
								)
								: null
						}

						{
							!isDetailsPage
								? (
									<div
										className={
											classNames(
												stylesCommon.markup,
												stylesCommon.cell,
											)
										}
									>
										{
											worklogsTotalDuration > 0
												? (
													<MarkupsByPeriods
														worklogsTotalDuration={worklogsTotalDuration}
														markupsWithDuration={sortedMarkupsWithDuration}
														nonMarkedWorklogsDuration={
															nonMarkedWorklogsDuration
														}
														className={styles.markupsByPeriods}
													/>
												)
												: null
										}
									</div>
								)
								: null
						}

						{
							(
								isDetailsPage
								&& viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
							)
								? (
									<div
										className={
											classNames(
												stylesCommon.markup,
												stylesCommon.cell,
												styles.markupCell,
											)
										}
									>
										{
											!isEmpty(sortedFilteredMarkupStatusIds)
												? (
													<>
														{renderStatus(0)}
														{
															(
																sortedFilteredMarkupStatusIds.length
																=== MAX_STATUSES_ITEMS_SUMMARY_ROW
															)
																? renderStatus(1)
																: null
														}
														{
															(
																sortedFilteredMarkupStatusIds.length
																> MAX_STATUSES_ITEMS_SUMMARY_ROW
															)
																? (
																	<ShowMoreStatusesDropdown
																		markupStatuses={dashboardMarkupsByStatus}
																		markupSummaries={markupSummaries}
																		markupStatusesIds={
																			sortedFilteredMarkupStatusIds.slice(
																				MAX_STATUSES_ITEMS_SUMMARY_ROW - 1,
																			)
																		}
																	/>
																)
																: null
														}
													</>
												)
												: null
										}
									</div>
								)
								: null
						}

						<div
							className={
								classNames(
									stylesCommon.team,
									stylesCommon.cell,
									styles.team,
									styles.cell,
								)
							}
						>
							<Tooltip
								content="Positions count"
								placement="top"
								color="white"
							>
								<div>
									{summary.positionCount}
								</div>
							</Tooltip>
						</div>

						{
							!isDetailsPage
								? (
									<div
										className={
											classNames(
												stylesCommon.referenceHours,
												stylesCommon.cell,
											)
										}
									>
										<Tooltip
											content="Employees with reference hours"
											placement="top"
											color="white"
										>
											<div>
												{summary.employeeCountWithReferenceHours}
											</div>
										</Tooltip>
									</div>
								)
								: null
						}

						{
							isEmployeeDailyViewMode
								? (
									<div className={stylesCommon.lock}>
										<LockedNumber
											employees={project.employees}
											hasBillingLock={summary.hasBillingLock}
											hasReportingLock={summary.hasReportingLock}
											hasProjectBillingLock={summary.hasProjectBillingLock}
											hasProjectReportingLock={summary.hasProjectReportingLock}
											billingUnlockedCount={summary.numberOfEmployeesUnlockedForBilling}
											reportingUnlockedCount={summary.numberOfEmployeesUnlockedForReporting}
										/>
									</div>
								)
								: null
						}
					</Link>

					<div
						className={
							classNames(
								styles.actions,
								stylesCommon.cell,
							)
						}
					>
						{
							isDetailsPage
							&& viewMode === PROJECT_VIEW_MODE.POSITION_BREAKDOWNS
							&& project?.breakdownsData
								? (
									<BreakdownSelector
										project={project}
										value={breakdownId}
										changeBreakdown={changeBreakdown}
									/>
								)
								: null
						}

						{
							!isDetailsPage
								? (
									<ThreeDotsMenuForDashboard
										isFirst={isFirst}
										projectId={projectId}
									/>
								)
								: null
						}
					</div>
				</div>

				{children}
			</div>
		);
	}
}

const mapStateToProps = (
	state,
	{
		projectId,
	},
) => {
	return {
		project: state.projectsDashboard.projects[projectId],
		dashboardMarkupsByStatus: state.projectsDashboard.markupStatuses[projectId],
		isDetailsPage: Boolean(state.projectsDashboard.showDetailsProjectId),
		selectedProjectsIds: state?.multipleChoiceContainer?.projects ?? [],
		filterMarkupStatusValues: state.projectsDashboard.filterMarkupStatusValues[projectId],
		showSelectedProjectsHoursTotal: showSelectedProjectsHoursTotalSelector(state),
	};
};

const mapDispatchToProps = {
	removeFilteredEmployeesToUnlock,
	getProjectDetailsData,
	// The function below is wrapped by thunk middleware and returns a function: (dispatch, getState) => {}
	applyProjectPositionFilter,
	hideSpinner,
	showSpinner,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectSummaryRow));
