/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	Checkbox,
	Dropdown,
	DropdownContainer,
	FlexRow,
	RadioInput,
	ScrollBars,
	Text,
} from "@epam/loveship";
import {
	useEffect,
	useState,
} from "react";
import {
	flushSync,
} from "react-dom";
import {
	useSelector,
} from "react-redux";
import {
	useHistory,
	useParams,
} from "react-router-dom";

import {
	PROJECT_VIEW_MODE,
	PROJECT_VIEW_MODE_TITLE,
} from "constants/projects";
import {
	generateProjectPageUrl,
} from "pages/utils/generate-project-page-url";
import {
	useDispatch,
} from "store";

import {
	toggleFilteredMarkupStatus,
} from "../../../actionCreators";

import styles from "./ViewSelector.module.css";

const ViewSelector = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		fromDate,
		toDate,
		viewMode,
		projectId,
	} = useParams();

	const markupStatuses = useSelector((state) => {
		return state.projectsDashboard.markupStatuses[projectId];
	});

	const filterMarkupStatusesInitial = useSelector((state) => {
		return state.projectsDashboard.filterMarkupStatusValues[projectId];
	});

	const [
		isViewSelectorVisible,
		setIsViewSelectorVisible,
	] = useState(false);
	const [
		filterMarkupStatuses,
		setFilterMarkupStatuses,
	] = useState(filterMarkupStatusesInitial);
	const [
		prevFilterMarkupStatuses,
		setPrevFilterMarkupStatuses,
	] = useState({});

	const statuses = markupStatuses
		? Object.values(markupStatuses)
		: [];

	const shouldDisplayStatusCheckboxes = (
		viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
		&& statuses
		&& statuses.length !== 0
	);

	useEffect(
		() => {
			setFilterMarkupStatuses(filterMarkupStatusesInitial);

			setPrevFilterMarkupStatuses(filterMarkupStatusesInitial);
		},
		[
			filterMarkupStatusesInitial,
		],
	);

	const toggleDropDown = (isDropDownOpen) => {
		setIsViewSelectorVisible(isDropDownOpen);

		if (
			viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
			&& !isDropDownOpen
			&& filterMarkupStatuses !== prevFilterMarkupStatuses
		) {
			dispatch(toggleFilteredMarkupStatus(filterMarkupStatuses, projectId));
		}
	};

	const selectViewMode = (nextViewMode) => {
		if (viewMode !== nextViewMode) {
			/*
				Without `flushSync` the menu stays visible until
				the destination page is fully loaded.
			*/
			flushSync(() => {
				setIsViewSelectorVisible(false);
			});

			const projectPageWithNewViewUrl = generateProjectPageUrl({
				projectId,
				periodStartDate: fromDate,
				periodEndDate: toDate,
				viewMode: nextViewMode,
			});

			history.push(projectPageWithNewViewUrl);

			const currentViewModeName = PROJECT_VIEW_MODE_TITLE[viewMode].toLowerCase();
			const nextViewModeName = PROJECT_VIEW_MODE_TITLE[nextViewMode].toLowerCase();

			window.dataLayer.push({
				event_name: "switch_interface",
				event_action: `${currentViewModeName} - ${nextViewModeName}`,
				event: "autoevent",
			});
		}
	};

	return (
		<Dropdown
			closeOnClickOutside={true}
			value={isViewSelectorVisible}
			onValueChange={toggleDropDown}
			renderTarget={(props) => {
				return (
					<Button
						{...props}
						onClick={() => {
							setIsViewSelectorVisible(!isViewSelectorVisible);
						}}
						caption={PROJECT_VIEW_MODE_TITLE[viewMode]}
						color="gray"
						fill="white"
					/>
				);
			}}
			renderBody={() => {
				return (
					<DropdownContainer
						focusLock={false}
						cx={styles["dropdown-container"]}
					>
						<ScrollBars>
							<FlexRow padding="12">
								<RadioInput
									value={viewMode === PROJECT_VIEW_MODE.POSITION_BREAKDOWN}
									label={PROJECT_VIEW_MODE_TITLE[PROJECT_VIEW_MODE.POSITION_BREAKDOWN]}
									onValueChange={() => {
										selectViewMode(PROJECT_VIEW_MODE.POSITION_BREAKDOWN);
									}}
								/>
							</FlexRow>
							<FlexRow padding="12">
								<RadioInput
									value={viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY}
									label={PROJECT_VIEW_MODE_TITLE[PROJECT_VIEW_MODE.EMPLOYEE_DAILY]}
									onValueChange={() => {
										selectViewMode(PROJECT_VIEW_MODE.EMPLOYEE_DAILY);
									}}
								/>
							</FlexRow>
							<FlexRow padding="12">
								<RadioInput
									value={viewMode === PROJECT_VIEW_MODE.POSITION_SUMMARY}
									label={PROJECT_VIEW_MODE_TITLE[PROJECT_VIEW_MODE.POSITION_SUMMARY]}
									onValueChange={() => {
										selectViewMode(PROJECT_VIEW_MODE.POSITION_SUMMARY);
									}}
								/>
							</FlexRow>
							<FlexRow padding="12">
								<Text cx={styles["group-title"]}>
									Position daily
								</Text>
							</FlexRow>
							<FlexRow padding="12">
								<RadioInput
									value={viewMode === PROJECT_VIEW_MODE.POSITION_DAILY}
									label={PROJECT_VIEW_MODE_TITLE[PROJECT_VIEW_MODE.POSITION_DAILY]}
									onValueChange={() => {
										selectViewMode(PROJECT_VIEW_MODE.POSITION_DAILY);
									}}
								/>
							</FlexRow>
							<FlexRow padding="12">
								<RadioInput
									value={viewMode === PROJECT_VIEW_MODE.POSITION_STRUCTURED}
									label={PROJECT_VIEW_MODE_TITLE[PROJECT_VIEW_MODE.POSITION_STRUCTURED]}
									onValueChange={() => {
										selectViewMode(PROJECT_VIEW_MODE.POSITION_STRUCTURED);
									}}
								/>
							</FlexRow>
							<FlexRow padding="12">
								<RadioInput
									value={viewMode === PROJECT_VIEW_MODE.POSITION_BREAKDOWNS}
									label={PROJECT_VIEW_MODE_TITLE[PROJECT_VIEW_MODE.POSITION_BREAKDOWNS]}
									onValueChange={() => {
										selectViewMode(PROJECT_VIEW_MODE.POSITION_BREAKDOWNS);
									}}
								/>
							</FlexRow>

							{
								shouldDisplayStatusCheckboxes
									? (
										<>
											<div className={styles.divider}/>
											<FlexRow padding="12">
												<Text cx={styles["group-title"]}>
													Status
												</Text>
											</FlexRow>

											{
												statuses.map((status) => {
													const {
														id,
														name,
														color,
													} = status;

													return (
														<FlexRow
															key={id}
															cx={styles["status-row"]}
															padding="12"
														>
															<Checkbox
																label={name}
																value={filterMarkupStatuses[id]}
																onValueChange={(value) => {
																	setFilterMarkupStatuses({
																		...filterMarkupStatuses,
																		[id]: value,
																	});
																}}
															/>
															<div
																className={styles["status-marker"]}
																style={{
																	backgroundColor: color,
																}}
															/>
														</FlexRow>
													);
												})
											}
										</>
									)
									: null
							}
						</ScrollBars>
					</DropdownContainer>
				);
			}}
		/>
	);
};

export default ViewSelector;
