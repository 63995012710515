/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	FlexRow,
	IconContainer,
	Text,
	Tooltip,
} from "@epam/loveship";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import xorBy from "lodash/xorBy";
import {
	Component,
} from "react";
import {
	connect,
} from "react-redux";
import {
	withRouter,
} from "react-router-dom";

import {
	getHasProjectsDashboardFilterBeenChanged,
	getProjectsCommonFilterValuesFromLocalStorage,
	getProjectsDashboardFilterValuesFromLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	svc,
} from "constants/services";
import {
	ReactComponent as BillableTimeIcon,
} from "icons/billable-10x18.svg";
import {
	ReactComponent as GapsIcon,
} from "icons/clock-20.svg";
import {
	ReactComponent as UnlockedEmployeesIcon,
} from "icons/lock-opened-21.svg";
import {
	ReactComponent as MarkupIcon,
} from "icons/markup-20.svg";
import {
	ReactComponent as NonBillableIcon,
} from "icons/non-billable-18.svg";
import {
	ReactComponent as NonProjectHoursIcon,
} from "icons/npa-13x16.svg";
import {
	ReactComponent as OnDutyIcon,
} from "icons/on-duty-hours-18.svg";
import {
	ReactComponent as OverReportingIcon,
} from "icons/over-report-20x19.svg";
import {
	ReactComponent as OvertimeIcon,
} from "icons/overtime-16x22.svg";
import {
	ReactComponent as ReferenceHoursIcon,
} from "icons/reference-hours-24.svg";
import {
	ReactComponent as SumSignIcon,
} from "icons/sum-12x16.svg";
import {
	ReactComponent as SumTrack,
} from "icons/sum-tracked-hours-24.svg";
import {
	ReactComponent as TeamIcon,
} from "icons/team-22x14.svg";
import trackedModeOffPng from "icons/tracked-mode-disabled-48.png";
import trackedModeOnPng from "icons/tracked-mode-enabled-48.png";
import {
	ReactComponent as VacationIcon,
} from "icons/vacation-18.svg";
import {
	formatLockDate,
} from "models/dates-and-time/utils/common";
import {
	ActionButtonsGroup,
} from "pages/components/action-panel-components/action-buttons-group/action-buttons-group";
import {
	ActionsPanel,
} from "pages/components/action-panel-components/actions-panel/actions-panel";
import {
	PageActionsButton,
} from "pages/components/action-panel-components/page-actions-button/page-actions-button";
import {
	Menu,
} from "pages/components/menu/menu";
import {
	MenuOption,
} from "pages/components/menu/option";
import {
	SubMenu,
} from "pages/components/menu/sub-menu";
import {
	PagePeriodPresetLocalStorageKey,
} from "pages/components/page-period-navigator/constants";
import {
	PagePeriodNavigator,
} from "pages/components/page-period-navigator/index";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	TooltipWithHeader,
} from "pages/components/tooltip-with-header/tooltip-with-header";
import {
	generateProjectPageUrl,
} from "pages/utils/generate-project-page-url";
import {
	generateProjectsPageUrl,
} from "pages/utils/generate-projects-page-url";
import {
	getUserName,
} from "store/slices/application-info/selectors";
import {
	round,
} from "utilities/number";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	FILTER_PROJECT_BILLING_TYPES_DATA,
} from "../../../../environment/consts";
import {
	displaySuccessNotification,
} from "../../../common-components/notification/Notifications";
import {
	billingLockAllProjects,
	filterDashboardData,
	getExcel,
} from "../../actionCreators";
import {
	DEFAULT_PROJECTS_DASHBOARD_FILTER_VALUES,
} from "../../constants";
import {
	showSelectedProjectsHoursTotalSelector,
	trackedProjectsExistsSelector,
} from "../../selectors";
import {
	processExcelExport,
} from "../../utils";
import ThreeDotsMenu from "../project-summary-row/three-dots-menu/ThreeDotsMenu";
import {
	DashboardFilter,
} from "./dashboard-filter/DashboardFilter";
import {
	LockMenu,
} from "./lock-menu/lock-menu";
import {
	LockRedBillingModalWindow,
} from "./lock-red-billing-modal-window";
import {
	ProjectDetailsFilter,
} from "./project-details-filter/project-details-filter";
import {
	ProjectNavigation,
} from "./project-navigation/project-navigation";
import StructureLevelSelector from "./structure-level-selector/StructureLevelSelector";
import ViewSelector from "./view-selector/ViewSelector";

import stylesCommon from "../../ProjectCommon.module.css";
import styles from "./Header.module.css";

const TOTAL_TIME_KEY = "TOTAL_TIME_KEY";

/**
 * @typedef {import("models/projects/types").ProjectId} ProjectId
 */

/**
 * @typedef {import("components/projects-billing/types").ProjectsDashboardSpecificFilterValues} ProjectsDashboardFilterValues
 */

/**
 * @typedef {Object} SendFilterDataGAParams
 * @property {ProjectsDashboardFilterValues} currentFilterValues
 * @property {ProjectsDashboardFilterValues} comparisonFilterValues
 * @property {"apply" | "start"} eventAction
 */

/**
 * @param {SendFilterDataGAParams} params
 */
const sendFilterDataGA = ({
	currentFilterValues,
	comparisonFilterValues,
	eventAction,
}) => {
	const customersDifference = xorBy(
		currentFilterValues.customers,
		comparisonFilterValues.customers,
		"value",
	);

	if (!isEmpty(customersDifference)) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "customers",
			event: "autoevent",
		});
	}

	const projectsDifference = xorBy(
		currentFilterValues.projects,
		comparisonFilterValues.projects,
		"value",
	);

	if (!isEmpty(projectsDifference)) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "projects",
			event: "autoevent",
		});
	}

	const projectBillingTypesDifference = xorBy(
		currentFilterValues.projectBillingTypes,
		comparisonFilterValues.projectBillingTypes,
		"value",
	);

	if (!isEmpty(projectBillingTypesDifference)) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "project billing type",
			event: "autoevent",
		});
	}

	if (currentFilterValues.showWithVacation !== comparisonFilterValues.showWithVacation) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "requested and taken leave",
			event: "autoevent",
		});
	}

	if (currentFilterValues.showWithNpa !== comparisonFilterValues.showWithNpa) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "NPA",
			event: "autoevent",
		});
	}

	if (currentFilterValues.showWithExcludedHours !== comparisonFilterValues.showWithExcludedHours) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "on-duty hours",
			event: "autoevent",
		});
	}
};

const sendReportExportDataGA = (
	/** @type {"worklog" | "project"} */
	reportType,
) => {
	window.dataLayer.push({
		event_name: "excel_export",
		event_action: reportType,
		event: "autoevent",
	});
};

/**
 * @typedef {Object} HeaderProps
 * @property {(projectId: ProjectId) => void} addProjectIdToReportingLockStatusWatcher
 */

/**
 * @typedef {Object} HeaderState
 * @property {ProjectsDashboardFilterValues} filterValues
 */

/**
 * @extends {Component<HeaderProps, HeaderState}
 */
class Header extends Component {
	constructor(props) {
		super(props);

		const filterValues = getProjectsDashboardFilterValuesFromLocalStorage();

		this.state = {
			filterValues,
		};
	}

	componentDidMount() {
		const {
			isDetailsPage,
		} = this.props;
		const {
			filterValues,
		} = this.state;

		if (!isDetailsPage) {
			sendFilterDataGA({
				currentFilterValues: filterValues,
				comparisonFilterValues: DEFAULT_PROJECTS_DASHBOARD_FILTER_VALUES,
				eventAction: "start",
			});
		}
	}

	/**
	 * @param {import("models/dates-and-time/types").WithPeriodDates} periodDates
	 * @returns {void}
	 */
	handlePeriodDatesChange = ({
		periodStartDate,
		periodEndDate,
	}) => {
		const {
			history,
			isDetailsPage,
			match: {
				params: {
					viewMode,
					projectId,
				},
			},
		} = this.props;

		if (isDetailsPage) {
			const projectPageUrl = generateProjectPageUrl({
				projectId,
				periodStartDate,
				periodEndDate,
				viewMode,
			});

			return history.push(projectPageUrl);
		}

		const projectsPageUrl = generateProjectsPageUrl({
			periodStartDate,
			periodEndDate,
		});

		return history.push(projectsPageUrl);
	};

	lockBillingAllProjects = () => {
		const {
			billingLockAllProjects,
			redLockDay,
			lastRedLimitChangerName,
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			fromDate: from,
			toDate: to,
		} = this.props.match.params;

		const {
			showWithVacation,
			showWithNpa,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		svc.uuiModals.show((props) => {
			return (
				<LockRedBillingModalWindow
					redLockDate={redLockDay}
					lastRedLimitChangerName={lastRedLimitChangerName}
					{...props}
				/>
			);
		})
			.then((data) => {
				if (
					data
					&& data.date
				) {
					showSpinner();

					billingLockAllProjects(
						isNewPermissionSchemeEnabled,
						data.date,
						from,
						to,
						showWithNpa,
						showWithVacation,
					)
						.then((res) => {
							const message = `Billing period has been successfully locked till ${formatLockDate(res.newRedLockDate)} by ${res.lastRedLimitChangerName}`;

							hideSpinner();

							displaySuccessNotification(message);
						});
				}
			});
	};

	/**
	 * @param {ProjectsDashboardFilterValues} filterValues
	 */
	applyFilter = (filterValues) => {
		const {
			filterDashboardData,
			hideSpinner,
			showSpinner,
			isDetailsPage,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			fromDate: from,
			toDate: to,
		} = this.props.match.params;
		const {
			filterValues: currentFilterValues,
		} = this.state;

		this.setState({
			filterValues,
		});

		if (!isDetailsPage) {
			if (!getHasProjectsDashboardFilterBeenChanged(filterValues)) {
				window.dataLayer.push({
					event_name: "filter",
					event_action: "reset",
					event: "autoevent",
				});
			} else {
				sendFilterDataGA({
					currentFilterValues,
					comparisonFilterValues: filterValues,
					eventAction: "apply",
				});
			}
		}

		showSpinner();

		filterDashboardData(
			isNewPermissionSchemeEnabled,
			from,
			to,
			filterValues,
		)
			.then(() => {
				hideSpinner();
			});
	};

	getSelectedProjectTotal = (totalKey) => {
		const {
			projects,
			selectedProjectsIds,
		} = this.props;

		const total = projects
			.filter(({
				id,
			}) => {
				return selectedProjectsIds.includes(id);
			})
			.reduce((acc, project) => {
				const summary = project.employeesSummary;

				if (totalKey === TOTAL_TIME_KEY) {
					return acc + summary.totalWithoutNpa + summary.npa;
				}

				return (acc + (summary[totalKey] || 0));
			}, 0);

		return round(total);
	};

	render() {
		const {
			filter,
			getExcel,
			isDetailsPage,
			structureLevel,
			showTrackedMode,
			mainUserFullName,
			setStructureLevel,
			toggleTrackedMode,
			filteredProjectsIds,
			isTrackedModeEnabled,
			trackedProjectsExists,
			hasRedLockPermission = false,
			showSelectedProjectsHoursTotal,
			projectName,
			addProjectIdToReportingLockStatusWatcher,
		} = this.props;
		const {
			fromDate: from,
			toDate: to,
			viewMode,
		} = this.props.match.params;

		const {
			showWithVacation,
			showWithNpa,
			showWithExcludedHours,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		return (
			<>
				<div className={styles.header}>
					<ProjectNavigation
						projectName={projectName}
						className={styles.projectNavigation}
					/>
					<PagePeriodNavigator
						localStorageKey={
							isDetailsPage
								? PagePeriodPresetLocalStorageKey.PROJECT_DETAILS
								: PagePeriodPresetLocalStorageKey.PROJECTS_DASHBOARD
						}
						onDatesChange={this.handlePeriodDatesChange}
					/>

					{
						isDetailsPage
							? (
								<>
									<div
										className={
											classNames(
												styles.divider,
												styles["divider-left"],
											)
										}
									/>
									<FlexRow
										spacing={null}
										columnGap="12"
									>
										<Text
											fontSize="14"
											fontWeight="600"
										>
											Select view
										</Text>
										<ViewSelector/>
									</FlexRow>

									{
										viewMode === PROJECT_VIEW_MODE.POSITION_STRUCTURED
											? (
												<>
													<Text
														cx={styles["zoom-title"]}
														fontSize="14"
														fontWeight="600"
													>
														Project zoom
													</Text>

													<StructureLevelSelector
														selectedItem={structureLevel}
														onItemSelect={setStructureLevel}
														items={[
															"1 level down",
															"2 levels down",
															"3 levels down",
															"4 levels down ",
															"5 levels down",
														]}
													/>
												</>
											)
											: null
									}
								</>
							)
							: null
					}

					<div
						style={{
							marginLeft: "auto",
						}}
					/>

					{
						(
							!isDetailsPage
							&& trackedProjectsExists
						)
							? (
								<>
									<img
										src={
											isTrackedModeEnabled
												? trackedModeOnPng
												: trackedModeOffPng
										}
										width={24}
										height={24}
										alt="tracked mode"
										onClick={toggleTrackedMode}
										className={styles["track-mode-switcher"]}
									/>

									<span className={styles.divider}/>
								</>
							)
							: null
					}

					{
						!isDetailsPage
							? (
								<>
									<div className={styles["filter-button"]}>
										<DashboardFilter
											{...filter}
											applyFilter={this.applyFilter}
											projectBillingTypes={FILTER_PROJECT_BILLING_TYPES_DATA}
										/>
									</div>

									<span className={styles.divider}/>
								</>
							)
							: null
					}

					{
						!isDetailsPage
							? (
								<Menu
									placement="bottom-end"
									rawProps={{
										"data-name": toDataAttribute("Projects actions menu"),
									}}
									renderOptions={() => {
										/** @type {JSX.Element[]} */
										const menuOptions = [
											<SubMenu
												key="Download report menu"
												rawProps={{
													"data-name": toDataAttribute("Download report menu"),
												}}
												renderOptions={() => {
													return [
														<MenuOption
															key="Worklogs report"
															caption="Worklogs report"
															onClick={
																processExcelExport({
																	fromDate: from,
																	toDate: to,
																	viewMode,
																	projects: filteredProjectsIds,
																	showPeriod: true,
																	baseUrl: "dashboard/excel/worklog",
																	additionalParams: `withVacation=${showWithVacation}&withNpa=${showWithNpa}&withExcludedHours=${Boolean(showWithExcludedHours)}`,
																	mainUserFullName,
																	getExcel,
																	onSuccess: () => {
																		if (!isDetailsPage) {
																			sendReportExportDataGA("worklog");
																		}
																	},
																})
															}
														/>,
														<MenuOption
															key="Project report"
															caption="Project report"
															onClick={
																processExcelExport({
																	fromDate: from,
																	toDate: to,
																	viewMode,
																	projects: filteredProjectsIds,
																	showPeriod: false,
																	baseUrl: "dashboard/excel/project",
																	additionalParams: "",
																	mainUserFullName,
																	getExcel,
																	onSuccess: () => {
																		if (!isDetailsPage) {
																			sendReportExportDataGA("project");
																		}
																	},
																})
															}
														/>,
													];
												}}
											>
												{(props) => {
													return (
														<MenuOption
															caption="Download report"
															isSubMenuButton={true}
															{...props}
														/>
													);
												}}
											</SubMenu>,
										];

										if (hasRedLockPermission) {
											menuOptions.push(
												<MenuOption
													key="Lock all billing"
													caption="Lock all billing"
													onClick={this.lockBillingAllProjects}
												/>,
											);
										}

										return menuOptions;
									}}
								>
									{(props) => {
										return (
											<PageActionsButton
												rawProps={{
													"data-name": toDataAttribute("Projects actions menu button"),
												}}
												{...props}
											/>
										);
									}}
								</Menu>
							)
							: null
					}

					{
						isDetailsPage
							? (
								<ActionsPanel>
									<ProjectDetailsFilter/>

									<ActionButtonsGroup>
										{
											viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
												? (
													<LockMenu
														addProjectIdToReportingLockStatusWatcher={addProjectIdToReportingLockStatusWatcher}
													/>
												)
												: null
										}
										<ThreeDotsMenu/>
									</ActionButtonsGroup>
								</ActionsPanel>
							)
							: null
					}
				</div>

				<div
					className={
						classNames(
							styles["project-table-header"],
							{
								[styles.projectHeaderShowTotalNumberOfHours]: showSelectedProjectsHoursTotal,
							},
							isDetailsPage
								? stylesCommon.detailsPage
								: stylesCommon.summaryPage,
						)
					}
				>
					<div
						className={
							classNames(
								styles["projects-count"],
								stylesCommon.cell,
							)
						}
					>
						{
							!isDetailsPage
								? (
									<>
										Projects (
										{filteredProjectsIds.length}
										)
									</>
								)
								: null
						}
					</div>

					<div
						className={
							classNames(
								stylesCommon.summary,
								stylesCommon.cell,
							)
						}
					>
						<Tooltip
							content="Total time"
							placement="top"
							color="white"
						>
							<IconContainer
								icon={SumSignIcon}
								cx={styles.totalTimeIcon}
							/>
						</Tooltip>
					</div>

					{
						showTrackedMode
							? (
								<div
									className={
										classNames(
											stylesCommon.tracked,
											stylesCommon.cell,
										)
									}
								>
									<Tooltip
										content="Tracked Time"
										placement="top"
										color="white"
									>
										<IconContainer
											icon={SumTrack}
											cx={styles.sumTrackIcon}
										/>
									</Tooltip>
								</div>
							)
							: null
					}

					<div
						className={
							classNames(
								stylesCommon.billable,
								stylesCommon.cell,
							)
						}
					>
						<Tooltip
							content="Billable time"
							placement="top"
							color="white"
						>
							<IconContainer
								icon={BillableTimeIcon}
								cx={styles.billableIcon}
							/>
						</Tooltip>
					</div>

					<div
						className={
							classNames(
								stylesCommon["non-billable"],
								stylesCommon.cell,
							)
						}
					>
						<Tooltip
							content="Non-billable time"
							placement="top"
							color="white"
						>
							<IconContainer
								icon={NonBillableIcon}
								cx={styles.nonBillableIcon}
							/>
						</Tooltip>
					</div>

					<div
						className={
							classNames(
								stylesCommon.overtime,
								stylesCommon.cell,
							)
						}
					>
						<Tooltip
							content="Overtime"
							placement="top"
							color="white"
						>
							<div className="total-icon">
								<IconContainer
									icon={OvertimeIcon}
									cx={styles.overtimeIcon}
								/>
							</div>
						</Tooltip>
					</div>

					<div
						className={
							classNames(
								stylesCommon.overreported,
								stylesCommon.cell,
							)
						}
					>
						<Tooltip
							content="Overreporting"
							placement="top"
							color="white"
						>
							<div className="total-icon">
								<IconContainer
									icon={OverReportingIcon}
									cx={styles.overReportingIcon}
								/>
							</div>
						</Tooltip>
					</div>

					<div
						className={
							classNames(
								stylesCommon.gaps,
								stylesCommon.cell,
							)
						}
					>
						<Tooltip
							content="Gap time"
							placement="top"
							color="white"
						>
							<IconContainer
								icon={GapsIcon}
								cx={styles.gapsIcon}
							/>
						</Tooltip>
					</div>

					{
						showWithVacation
							? (
								<div
									className={
										classNames(
											stylesCommon.vac,
											stylesCommon.cell,
										)
									}
								>
									<Tooltip
										content="Leave time"
										placement="top"
										color="white"
									>
										<IconContainer
											icon={VacationIcon}
											cx={styles.vacationIcon}
										/>
									</Tooltip>
								</div>
							)
							: null
					}

					{
						showWithNpa
							? (
								<div
									className={
										classNames(
											stylesCommon.npa,
											stylesCommon.cell,
										)
									}
								>
									<Tooltip
										content="NPA time"
										placement="top"
										color="white"
									>
										<IconContainer
											icon={NonProjectHoursIcon}
											cx={styles.nonProjectHoursIcon}
										/>
									</Tooltip>
								</div>
							)
							: null
					}

					{
						showWithExcludedHours
							? (
								<div
									className={
										classNames(
											stylesCommon["excluded-hours"],
											stylesCommon.cell,
										)
									}
								>
									<Tooltip
										content="On-duty"
										placement="top"
										color="white"
									>
										<IconContainer
											icon={OnDutyIcon}
											cx={styles.onDutyIcon}
										/>
									</Tooltip>
								</div>
							)
							: null
					}

					{
						(
							!isDetailsPage
							|| viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
						)
							? (
								<div
									className={
										classNames(
											stylesCommon.markup,
											stylesCommon.cell,
										)
									}
								>
									<Tooltip
										content="Status"
										placement="top"
										color="white"
									>
										<IconContainer
											icon={MarkupIcon}
											cx={styles.markupIcon}
										/>
									</Tooltip>
								</div>
							)
							: null
					}

					<div
						className={
							classNames(
								stylesCommon.team,
								stylesCommon.cell,
							)
						}
					>
						<Tooltip
							content="Positions count"
							placement="top"
							color="white"
						>
							<IconContainer
								icon={TeamIcon}
								cx={styles.teamIcon}
							/>
						</Tooltip>
					</div>

					{
						!isDetailsPage
							? (
								<div
									className={
										classNames(
											stylesCommon.cell,
											stylesCommon.referenceHours,
										)
									}
								>
									<Tooltip
										content="Employees with reference hours"
										placement="top"
										color="white"
									>
										<IconContainer
											icon={ReferenceHoursIcon}
											cx={styles.referenceHoursIcon}
										/>
									</Tooltip>
								</div>
							)
							: null
					}

					{
						(
							!isDetailsPage
							|| viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
						)
							? (
								<div
									className={
										classNames(
											stylesCommon.lock,
											stylesCommon.cell,
										)
									}
								>
									<TooltipWithHeader
										header="Unlocked employees."
										text="Count of unlocked employees in current period on a project."
										placement="top"
									>
										<IconContainer
											icon={UnlockedEmployeesIcon}
											cx={styles.unlockedEmployeesIcon}
										/>
									</TooltipWithHeader>
								</div>
							)
							: null
					}

					<div
						className={
							classNames(
								styles.actions,
								stylesCommon.cell,
							)
						}
					/>
				</div>

				{
					(
						!isDetailsPage
						&& showSelectedProjectsHoursTotal
					)
						? (
							<div
								className={styles.projectsSummaryTableHeader}
								style={{
									top: "134px",
								}}
							>
								<div className={styles["total-select"]}>
									Total selected:
								</div>

								<div
									className={
										classNames(
											stylesCommon.summary,
											stylesCommon.cell,
										)
									}
								>
									{`${this.getSelectedProjectTotal(TOTAL_TIME_KEY)}h`}
								</div>

								{
									showTrackedMode
										? (
											<div
												className={
													classNames(
														stylesCommon.tracked,
														stylesCommon.cell,
													)
												}
											>
												{`${this.getSelectedProjectTotal("trackedTime")}h`}
											</div>
										)
										: null
								}

								<div
									className={
										classNames(
											stylesCommon.billable,
											stylesCommon.cell,
										)
									}
								>
									{`${this.getSelectedProjectTotal("billable")}h`}
								</div>

								<div
									className={
										classNames(
											stylesCommon["non-billable"],
											stylesCommon.cell,
										)
									}
								>
									{`${this.getSelectedProjectTotal("nonBillable")}h`}
								</div>

								<div
									className={
										classNames(
											stylesCommon.overtime,
											stylesCommon.cell,
										)
									}
								>
									{`${this.getSelectedProjectTotal("ovt")}h`}
								</div>

								<div
									className={
										classNames(
											stylesCommon.overreported,
											stylesCommon.cell,
										)
									}
								>
									{`${this.getSelectedProjectTotal("overreported")}h`}
								</div>

								<div
									className={
										classNames(
											stylesCommon.gaps,
											stylesCommon.cell,
										)
									}
								>
									{`${this.getSelectedProjectTotal("gaps")}h`}
								</div>

								{
									showWithVacation
										? (
											<div
												className={
													classNames(
														stylesCommon.vac,
														stylesCommon.cell,
													)
												}
											>
												{`${this.getSelectedProjectTotal("vac")}h`}
											</div>
										)
										: null
								}

								{
									showWithNpa
										? (
											<div
												className={
													classNames(
														stylesCommon.npa,
														stylesCommon.cell,
													)
												}
											>
												{`${this.getSelectedProjectTotal("npa")}h`}
											</div>
										)
										: null
								}

								{
									showWithExcludedHours
										? (
											<div
												className={
													classNames(
														stylesCommon["excluded-hours"],
														stylesCommon.cell,
													)
												}
											>
												{`${this.getSelectedProjectTotal("excludedHours")}h`}
											</div>
										)
										: null
								}

								<div
									className={
										classNames(
											styles.actions,
											stylesCommon.cell,
										)
									}
								/>
							</div>
						)
						: null
				}
			</>
		);
	}
}

/**
 * @param {import("store").RootState} state
 */
const mapStateToProps = (state, props) => {
	return {
		filter: state.projectsDashboard.filter,
		mainUserFullName: getUserName(state),
		redLockDay: state.projectsDashboard.redLock.redLockDay,
		expandedProjectId: state.projectsDashboard.expandedProjectId,
		filteredProjectsIds: state.projectsDashboard.filteredProjects,
		projects: Object.values(state.projectsDashboard?.projects || {}),
		redLockDayLimit: state.projectsDashboard.redLock.redLockDayLimit,
		trackedProjectsExists: trackedProjectsExistsSelector(state),
		selectedProjectsIds: state?.multipleChoiceContainer?.projects ?? [],
		isDetailsPage: (
			props.isDetailsPage
			?? Boolean(state.projectsDashboard.showDetailsProjectId)
		),
		lastRedLimitChangerName: state.projectsDashboard.redLock.lastRedLimitChangerName,
		showSelectedProjectsHoursTotal: showSelectedProjectsHoursTotalSelector(state),
	};
};

const mapDispatchToProps = {
	getExcel,
	filterDashboardData,
	billingLockAllProjects,
	hideSpinner,
	showSpinner,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
