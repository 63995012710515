/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isNull from "lodash/isNull";

import {
	type ProjectName,
} from "models/projects/types";

import {
	TABLE_STRUCTURE_ELEMENT_ID,
} from "../constants";

const PROJECT_NAVIGATION_SCROLL = "projectScroll";

type LocalScrollTopProjects = Record<ProjectName, number>;

const setScrollTopInLocalStorage = (
	projectName: ProjectName,
): void => {
	const tableElement = document.getElementById(TABLE_STRUCTURE_ELEMENT_ID);

	if (isNull(tableElement)) {
		return;
	}

	let localScrollTopProjects: LocalScrollTopProjects = {};
	const localScrollTopProjectsString = localStorage.getItem(PROJECT_NAVIGATION_SCROLL);

	if (!isNull(localScrollTopProjectsString)) {
		try {
			localScrollTopProjects = {
				...JSON.parse(localScrollTopProjectsString) as LocalScrollTopProjects,
				[projectName]: tableElement.scrollTop,
			};

			localStorage.setItem(PROJECT_NAVIGATION_SCROLL, JSON.stringify(localScrollTopProjects));
		} catch {}
	} else {
		localScrollTopProjects = {
			[projectName]: tableElement.scrollTop,
		};

		localStorage.setItem(PROJECT_NAVIGATION_SCROLL, JSON.stringify(localScrollTopProjects));
	}
};

export {
	setScrollTopInLocalStorage,
};
