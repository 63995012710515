/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexSpacer,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	Text,
} from "@epam/loveship";
import {
	type DatePickerProps as UUIDatePickerProps,
} from "@epam/uui";
import {
	type IModal,
} from "@epam/uui-core";
import classNames from "classnames";
import {
	isBefore,
	isEqual,
	parseISO,
} from "date-fns";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import {
	type FC,
	useState,
} from "react";

import {
	type DateString,
} from "models/dates-and-time/types";
import {
	toFancyDate,
} from "models/dates-and-time/utils/to-fancy-date";
import {
	type EmployeeFullName,
} from "models/employees/types";
import {
	DatePicker,
	type DatePickerProps,
	type DatePickerValidationErrors,
} from "pages/components/date-picker/date-picker";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./lock-red-billing-modal-window.module.css";

const getAvailableBillingLockUnlockDates = (
	redLockDateString: DateString,
): UUIDatePickerProps["filter"] => {
	return (dayJsDate) => {
		const dateToCheck = dayJsDate.toDate();
		const redLockDate = parseISO(redLockDateString);

		const shouldDateBeDisabled = (
			isEqual(dateToCheck, redLockDate)
			|| isBefore(dateToCheck, redLockDate)
		);

		return !shouldDateBeDisabled;
	};
};

type LockDate = DatePickerProps["value"];

const getLockDateErrors = (
	lockDate: LockDate,
): DatePickerValidationErrors => {
	const errors: DatePickerValidationErrors = [];

	if (isNull(lockDate)) {
		errors.push("Date is required");
	}

	return errors;
};

interface LockRedBillingModalWindowOutput {
	date: DateString;
}

interface LockRedBillingModalWindowProps extends
	IModal<LockRedBillingModalWindowOutput | undefined> {
	redLockDate: DateString;
	lastRedLimitChangerName: EmployeeFullName;
}

const LockRedBillingModalWindow: FC<
	LockRedBillingModalWindowProps
> = ({
	redLockDate,
	lastRedLimitChangerName,
	...modalWindowProps
}) => {
	const [
		lockDate,
		setLockDate,
	] = useState<LockDate>(null);

	const closeModalWindow = (): void => {
		modalWindowProps.success(undefined);
	};

	const lockPeriod = (): void => {
		if (!isNull(lockDate)) {
			modalWindowProps.success({
				date: lockDate,
			});
		}
	};

	const errors = getLockDateErrors(lockDate);

	const hasErrors = !isEmpty(errors);

	const isLockButtonDisabled = hasErrors;

	return (
		<ModalBlocker
			{...modalWindowProps}
		>
			<ModalWindow
				width="600"
			>
				<ModalHeader
					rawProps={{
						"data-name": toDataAttribute("Modal window header"),
					}}
					onClose={closeModalWindow}
				>
					<Text
						fontSize="18"
						lineHeight="24"
						cx={styles.text}
						fontWeight="600"
						rawProps={{
							"data-name": toDataAttribute(
								"Modal window title",
							),
						}}
					>
						Lock all billing
					</Text>
				</ModalHeader>
				<div className={styles.modalWindowBody}>
					<Text
						cx={
							classNames(
								styles.text,
								styles.lockInformationText,
							)
						}
						rawProps={{
							"data-name": toDataAttribute(
								"Lock information text",
							),
						}}
					>
						Select a date until which
						{" "}
						<strong>all employees</strong>
						{" "}
						on
						{" "}
						<strong>all projects</strong>
						{" "}
						can’t report time.
					</Text>

					<DatePicker
						value={lockDate}
						onValueChange={setLockDate}
						placeholder="Date"
						filterDates={
							getAvailableBillingLockUnlockDates(redLockDate)
						}
						errors={errors}
					/>

					<div className={styles.billingDatesBlock}>
						<Text
							fontSize="14"
							lineHeight="18"
							cx={styles.text}
							rawProps={{
								"data-name": toDataAttribute(
									"Current red billing lock date information text",
								),
							}}
						>
							The
							{" "}
							<strong>red</strong>
							{" "}
							billing period is locked until
							{" "}
							<strong>{toFancyDate(redLockDate)}</strong>
							{" "}
							by {lastRedLimitChangerName}
						</Text>
					</div>
				</div>
				<ModalFooter
					borderTop={true}
					rawProps={{
						"data-name": toDataAttribute("Modal window footer"),
					}}
				>
					<FlexSpacer/>
					<Button
						caption="Cancel"
						color="gray"
						fill="none"
						onClick={closeModalWindow}
					/>
					<Button
						caption="Lock all"
						color="grass"
						isDisabled={isLockButtonDisabled}
						onClick={lockPeriod}
					/>
				</ModalFooter>
			</ModalWindow>
		</ModalBlocker>
	);
};

export {
	LockRedBillingModalWindow,
};
