/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import xorBy from "lodash/xorBy";
import {
	type FC,
	useEffect,
	useState,
} from "react";
import {
	useSelector,
} from "react-redux";
import {
	useParams,
} from "react-router-dom";

import {
	applyProjectPositionFilter,
	getProjectDetailsData,
} from "components/projects-billing/actionCreators";
import {
	DEFAULT_PROJECT_EMPLOYEES_FILTER_VALUES,
} from "components/projects-billing/constants";
import {
	getHasProjectEmployeesFilterBeenChanged,
	getProjectEmployeesFilterValuesFromLocalStorage,
	getProjectPositionsFilterValuesFromLocalStorage,
	getProjectsCommonFilterValuesFromLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	type ProjectEmployeesFilterValues,
	type ProjectPositionsFilterValues,
} from "components/projects-billing/types";
import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	type ProjectPageParams,
} from "pages/types/project-page";
import {
	type RootState,
	useDispatch,
} from "store";

import DashboardPositionsFilter from "../../project-summary-row/dashboard-positions-filter/DashboardPositionsFilter";
import DashboardEmployeesFilter from "../../project-summary-row/dashbord-employees-filter/DashboardEmployeesFilter";
import {
	withProjectDetails,
} from "../with-project-details/with-project-details";

interface SendFilterDataGAParams {
	currentFilterValues: ProjectEmployeesFilterValues;
	comparisonFilterValues: ProjectEmployeesFilterValues;
	eventAction: "apply" | "start";
}

const sendFilterDataGA = ({
	currentFilterValues,
	comparisonFilterValues,
	eventAction,
}: SendFilterDataGAParams): void => {
	const employeesDifference = xorBy(
		currentFilterValues.employees,
		comparisonFilterValues.employees,
		"value",
	);

	if (!isEmpty(employeesDifference)) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "employees/positions",
			event_type: "project",
			event: "autoevent",
		});
	}

	const engagementTypeDifference = xorBy(
		currentFilterValues.engagementPackages,
		comparisonFilterValues.engagementPackages,
		"value",
	);

	if (!isEmpty(engagementTypeDifference)) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "engagement type",
			event_type: "project",
			event: "autoevent",
		});
	}

	const locationDifference = xorBy(
		currentFilterValues.countries,
		comparisonFilterValues.countries,
		"value",
	);

	if (!isEmpty(locationDifference)) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "location",
			event_type: "project",
			event: "autoevent",
		});
	}

	if (currentFilterValues.gaps !== comparisonFilterValues.gaps) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "employees with gaps",
			event_type: "project",
			event: "autoevent",
		});
	}

	if (currentFilterValues.overReporting !== comparisonFilterValues.overReporting) {
		window.dataLayer.push({
			event_name: "filter",
			event_action: eventAction,
			event_text: "employees with over-reporting",
			event_type: "project",
			event: "autoevent",
		});
	}
};

interface StructureItem {
	status: string;
}

interface DeliveryStreamStatusOption {
	id: StructureItem["status"];
	value: string;
}

type DeliveryStreamStatusOptions = DeliveryStreamStatusOption[];

interface Project {
	structureData: unknown;
	filteredRootStreamIds: unknown;
	breakdownsFilterValues: unknown;
	structure?: StructureItem[];
}

const ProjectDetailsFilter: FC = () => {
	const {
		projectId,
		fromDate,
		toDate,
		viewMode,
	} = useParams<ProjectPageParams>();

	const dispatch = useDispatch();

	const isNewPermissionSchemeEnabledBase = useSelector((state: RootState) => {
		return state.applicationInfo.cpeBoard.projectsEmployeeDaily;
	});

	const isNewPermissionSchemeEnabled = (
		isNewPermissionSchemeEnabledBase
		&& viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
	);

	const [
		filterValues,
		setFilterValues,
	] = useState<ProjectEmployeesFilterValues>(() => {
		return getProjectEmployeesFilterValuesFromLocalStorage(projectId);
	});

	useEffect(
		() => {
			if (viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY) {
				sendFilterDataGA({
					currentFilterValues: filterValues,
					comparisonFilterValues: DEFAULT_PROJECT_EMPLOYEES_FILTER_VALUES,
					eventAction: "start",
				});
			} else {
				const projectPositionsFilter = getProjectPositionsFilterValuesFromLocalStorage(projectId);

				dispatch(applyProjectPositionFilter(projectId, projectPositionsFilter));
			}
		},
		// We need to do it only on mount.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const project = useSelector((state: RootState) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return state.projectsDashboard.projects[projectId] as Project;
	});

	const setEmployeesFilterValues = async (nextFilterValues: ProjectEmployeesFilterValues): Promise<void> => {
		if (isEqual(filterValues, nextFilterValues)) {
			return;
		}

		setFilterValues(nextFilterValues);

		if (!getHasProjectEmployeesFilterBeenChanged(nextFilterValues)) {
			window.dataLayer.push({
				event_name: "filter",
				event_action: "reset",
				event: "autoevent",
			});
		} else {
			sendFilterDataGA({
				currentFilterValues: filterValues,
				comparisonFilterValues: nextFilterValues,
				eventAction: "apply",
			});
		}

		const commonFilterValues = getProjectsCommonFilterValuesFromLocalStorage();

		dispatch(showSpinner());

		await dispatch(
			getProjectDetailsData(
				isNewPermissionSchemeEnabled,
				projectId,
				fromDate,
				toDate,
				commonFilterValues,
			),
		);

		dispatch(hideSpinner());
	};

	const setPositionsFilterValues = (nextFilterValues: ProjectPositionsFilterValues): void => {
		dispatch(showSpinner());

		dispatch(applyProjectPositionFilter(projectId, nextFilterValues));

		dispatch(hideSpinner());
	};

	const getDeliveryStreamStatuses = (): DeliveryStreamStatusOptions => {
		return (
			project.structure?.reduce<DeliveryStreamStatusOptions>(
				(
					currentOptions,
					{
						status,
					},
				) => {
					if (isEmpty(status)) {
						return currentOptions;
					}

					const optionWithCurrentStatus = currentOptions.find((option) => {
						return option.id === status;
					});

					if (isUndefined(optionWithCurrentStatus)) {
						const value = status.charAt(0) + status.slice(1).toLowerCase();

						currentOptions.push({
							id: status,
							value,
						});
					}

					return currentOptions;
				},
				[],
			)
			?? []
		);
	};

	if (viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY) {
		return (
			<DashboardEmployeesFilter
				isNewPermissionSchemeEnabled={isNewPermissionSchemeEnabled}
				projectId={projectId}
				applyFilter={setEmployeesFilterValues}
			/>
		);
	}

	return (
		<DashboardPositionsFilter
			isNewPermissionSchemeEnabled={isNewPermissionSchemeEnabled}
			projectId={projectId}
			structureData={project.structureData}
			applyFilter={setPositionsFilterValues}
			breakdowns={project.breakdownsFilterValues}
			deliveryStreamStatuses={getDeliveryStreamStatuses()}
		/>
	);
};

const SafeProjectDetailsFilter = withProjectDetails(ProjectDetailsFilter);

export {
	SafeProjectDetailsFilter as ProjectDetailsFilter,
};
