/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	FlexRow,
	ModalBlocker,
	ModalFooter,
	ModalHeader,
	ModalWindow,
	TextArea,
} from "@epam/loveship";
import classNames from "classnames";
import {
	Component,
} from "react";

import {
	toDataAttribute,
} from "utils/to-data-attribute";

import styles from "./RequestUnlockMessage.module.css";

class RequestUnlockMessage extends Component {
	state = {
		reason: "",
	};

	close = () => {
		this.props.success();
	};

	submit = () => {
		const {
			reason,
		} = this.state;

		this.props.success({
			reason,
		});
	};

	onReasonChange = (reason) => {
		this.setState({
			reason,
		});
	};

	render() {
		const {
			reason,
		} = this.state;

		return (
			<ModalBlocker
				{...this.props}
			>
				<ModalWindow width="600">
					<ModalHeader
						title={(
							<span data-name="mail-reason">
								Reason
							</span>
						)}
						rawProps={{
							"data-name": toDataAttribute("Modal window header"),
						}}
						onClose={this.close}
					/>
					<FlexRow
						vPadding="24"
						padding="24"
						size="24"
						cx={
							classNames(
								styles.reason,
								styles.area,
							)
						}
					>
						<TextArea
							value={reason}
							onValueChange={this.onReasonChange}
							placeholder="Please provide short reason description"
						/>
					</FlexRow>

					<ModalFooter
						borderTop={true}
						rawProps={{
							"data-name": toDataAttribute("Modal window footer"),
						}}
					>
						<div className={styles["left-auto"]}/>
						<Button
							onClick={this.close}
							caption="Cancel"
							color="white"
						/>
						<Button
							caption="Request"
							onClick={this.submit}
							color="grass"
						/>
					</ModalFooter>
				</ModalWindow>

			</ModalBlocker>
		);
	}
}

export default RequestUnlockMessage;
