/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type NavigationDataSourceState,
} from "../types";

enum PROJECT_NAVIGATION_TAB_NAME {
	STRUCTURE = "Structure",
	FAVORITES = "Favorites",
}

const PROJECT_NAVIGATION_TAB_NAME_DEFAULT = PROJECT_NAVIGATION_TAB_NAME.STRUCTURE;

const TABLE_STRUCTURE_ELEMENT_ID = "table-structure_element_id";

const SEPARATOR_UNIT_ID = "/";

const INITIAL_DATA_SOURCE_STATE: NavigationDataSourceState = {
	topIndex: 0,
	visibleCount: 1,
};

export {
	PROJECT_NAVIGATION_TAB_NAME,
	PROJECT_NAVIGATION_TAB_NAME_DEFAULT,
	SEPARATOR_UNIT_ID,
	TABLE_STRUCTURE_ELEMENT_ID,
	INITIAL_DATA_SOURCE_STATE,
};
