/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import isUndefined from "lodash/isUndefined";
import {
	type FC,
} from "react";
import {
	useSelector,
} from "react-redux";
import {
	useParams,
} from "react-router-dom";

import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	type ProjectPageParams,
} from "pages/types/project-page";
import {
	type RootState,
} from "store";

interface Project {
	employeesSummary?: unknown;
	positionsSummary?: unknown;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle, @typescript-eslint/naming-convention
const withProjectDetails = <Props,>(Component: FC<Props>): FC<Props> => {
	const ComponentWithProjectDetails: FC<Props> = (props) => {
		const {
			projectId,
			viewMode,
		} = useParams<ProjectPageParams>();

		const project = useSelector((state: RootState) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			return state.projectsDashboard.projects[projectId] as Project | undefined;
		});

		const summary = viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
			? project?.employeesSummary
			: project?.positionsSummary;

		if (
			isUndefined(project)
			/*
					Fixes the following case:
					1. Open any project view page
					2. Move from there to the Project Dashboard page
					3. Return to the project view page using "Previous" browser button

					It seems that projects from Project Dashboard and project data on the details page use the same Redux store,
					but for the project view page it is enriched with additional details. And when user performs the step #3,
					the data is not enriched yet.
				*/
			|| isUndefined(summary)
		) {
			return null;
		}

		return (
			<Component
				{...props as Props & JSX.IntrinsicAttributes}
			/>
		);
	};

	return ComponentWithProjectDetails;
};

export {
	withProjectDetails,
};
