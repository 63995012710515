/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Checkbox,
	FlexRow,
	Spinner,
	Switch,
} from "@epam/loveship";
import {
	useCallback,
	useEffect,
	useState,
} from "react";
import {
	useDispatch,
	useSelector,
} from "react-redux";
import {
	useParams,
} from "react-router-dom";

import {
	FilterControlRow,
} from "components/common-components/filter-wrapper/filter-panel/filter-control-row";
import {
	FilterWrapper,
} from "components/common-components/filter-wrapper/FilterWrapper";
import {
	setValue,
	taggedDropdownValueChanged,
} from "components/common-components/filter-wrapper/utils";
import {
	getFiltersData,
} from "components/projects-billing/actionCreators";
import {
	ACTIVE_STREAM_STATUS_ID,
	DEFAULT_PROJECT_POSITIONS_FILTER_VALUES,
} from "components/projects-billing/constants";
import {
	getHasProjectPositionsFilterBeenChanged,
	getProjectPositionsFilterValuesFromLocalStorage,
	getProjectPositionsFilterValuesLocalStorageKey,
	updateProjectPositionsFilterValuesInLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	FiltersButton,
} from "pages/components/action-panel-components/filters-button/filters-button";
import {
	Combobox,
} from "pages/components/combobox/combobox";

import {
	ProjectsCommonFilter,
} from "../../projects-common-filter/projects-common-filter";
import {
	BreakdownDropdown,
} from "./breakdown-dropdown/breakdown-dropdown";
import {
	StreamDropdown,
} from "./stream-dropdown/stream-dropdown";

import styles from "./dashboard-positions-filter.module.css";

/**
 * @typedef {import("models/projects/types").ProjectId} ProjectId
 */

/**
 * @typedef {import("models/dates-and-time/types").DateString} DateString
 */

/**
 * @typedef {import("components/projects-billing/types").ProjectPositionsFilterValues} ProjectPositionsFilterValues
 */

/**
 * @typedef {Object} PositionsFilterBodyProps
 * @property {boolean} isNewPermissionSchemeEnabled
 * @property {ProjectId} projectId
 * @property {DateString} fromDate
 * @property {DateString} toDate
 * @property {ProjectPositionsFilterValues} filterValues
 * @property {import("components/common-components/filter-wrapper/filter-panel/filters-panel").SetFilterValue<ProjectPositionsFilterValues>} setFilterValue
 */

/** @type {import("react").FC<PositionsFilterBodyProps>} */
const PositionsFilterBody = ({
	isNewPermissionSchemeEnabled,
	projectId,
	fromDate,
	toDate,
	filterValues,
	setFilterValue,
	breakdowns,
	structureData,
	deliveryStreamStatuses,
}) => {
	const dispatch = useDispatch();
	const [
		isLoading,
		setIsLoading,
	] = useState(true);
	const filterData = useSelector((state) => {
		return state.projectsDashboard.filtersData;
	});

	useEffect(
		() => {
			Promise.resolve(
				dispatch(
					getFiltersData(
						isNewPermissionSchemeEnabled,
						projectId,
						fromDate,
						toDate,
					),
				),
			)
				.then(() => {
					setIsLoading(false);
				});
		},
		[
			isNewPermissionSchemeEnabled,
			dispatch,
			fromDate,
			projectId,
			toDate,
		],
	);

	if (isLoading) {
		return (
			<Spinner
				cx={styles.spinner}
			/>
		);
	}

	return (
		<>
			<FilterControlRow
				title="Employees/Positions"
			>
				<Combobox
					items={filterData.positions}
					value={filterValues.positions}
					onValueChange={
						taggedDropdownValueChanged(
							"positions",
							setFilterValue,
						)
					}
					entityName="employees/position"
				/>
			</FilterControlRow>
			<FilterControlRow
				title="Engagement type"
			>
				<Combobox
					items={filterData.engagementPackages}
					value={filterValues.engagementPackages}
					onValueChange={
						taggedDropdownValueChanged(
							"engagementPackages",
							setFilterValue,
						)
					}
					entityName="engagement type"
				/>
			</FilterControlRow>
			<FilterControlRow
				title="Location"
			>
				<Combobox
					items={filterData.countries}
					value={filterValues.countries}
					onValueChange={
						taggedDropdownValueChanged(
							"countries",
							setFilterValue,
						)
					}
					entityName="location"
				/>
			</FilterControlRow>
			<FilterControlRow
				title="Staffing desk breakdowns"
			>
				<BreakdownDropdown
					data={breakdowns}
					selectedValues={filterValues.groups}
					onChange={
						taggedDropdownValueChanged(
							"groups",
							setFilterValue,
						)
					}
				/>
			</FilterControlRow>
			<FilterControlRow
				title="Delivery Streams"
			>
				<StreamDropdown
					data={structureData}
					selectedValues={filterValues.streams}
					onChange={
						taggedDropdownValueChanged(
							"streams",
							setFilterValue,
						)
					}
				/>
			</FilterControlRow>
			<FilterControlRow
				title="Delivery Stream Status"
			>
				<Combobox
					items={deliveryStreamStatuses}
					value={filterValues.deliveryStreamStatuses}
					onValueChange={
						taggedDropdownValueChanged(
							"deliveryStreamStatuses",
							setFilterValue,
						)
					}
					entityName="delivery stream status"
				/>
			</FilterControlRow>
			<FilterControlRow
				title="Time reporting issues"
			>
				<FlexRow
					vPadding="12"
					padding="6"
				>
					<Checkbox
						label="Show positions with gaps"
						value={filterValues.gaps}
						onValueChange={
							setValue(
								setFilterValue,
								"gaps",
								!filterValues.gaps,
							)
						}
					/>
				</FlexRow>
				<FlexRow
					vPadding="12"
					size="24"
					padding="6"
				>
					<Checkbox
						label="Show positions with over-reporting"
						value={filterValues.overReporting}
						onValueChange={
							setValue(
								setFilterValue,
								"overReporting",
								!filterValues.overReporting,
							)
						}
					/>
				</FlexRow>
			</FilterControlRow>
			<FilterControlRow
				title="Misreporting cases"
			>
				<FlexRow
					vPadding="12"
					size="24"
					padding="6"
				>
					<Switch
						label="Only not in package"
						value={filterValues.onlyNotInPackage}
						onValueChange={
							setValue(
								setFilterValue,
								"onlyNotInPackage",
								!filterValues.onlyNotInPackage,
							)
						}
					/>
				</FlexRow>
				<FlexRow
					vPadding="12"
					size="24"
					padding="6"
				>
					<Switch
						label="Only billable positions to non-billable activities"
						value={filterValues.onlyOnNonBillablePackage}
						onValueChange={
							setValue(
								setFilterValue,
								"onlyOnNonBillablePackage",
								!filterValues.onlyOnNonBillablePackage,
							)
						}
					/>
				</FlexRow>
			</FilterControlRow>

			<FilterControlRow
				title="Additional information"
			>
				<ProjectsCommonFilter
					setFilterProperty={setFilterValue}
					showWithVacation={filterValues.showWithVacation}
					showWithNpa={filterValues.showWithNpa}
					showWithExcludedHours={filterValues.showWithExcludedHours}
				/>
			</FilterControlRow>
		</>
	);
};

/**
 * @typedef {Object} DashboardPositionsFilterProps
 * @property {boolean} isNewPermissionSchemeEnabled
 * @property {ProjectId} projectId
 * @property {*} breakdowns
 * @property {import("components/common-components/filter-wrapper/filter-panel/filters-panel").ApplyFilter<ProjectPositionsFilterValues>} applyFilter
 * @property {*} structureData
 * @property {*} deliveryStreamStatuses
 */

/** @type {import("react").FC<DashboardPositionsFilterProps>} */
const DashboardPositionsFilter = ({
	isNewPermissionSchemeEnabled,
	projectId,
	breakdowns,
	applyFilter,
	structureData,
	deliveryStreamStatuses,
}) => {
	const {
		fromDate,
		toDate,
	} = useParams();

	const localStorageKey = getProjectPositionsFilterValuesLocalStorageKey(projectId);

	const activeDeliveryStream = deliveryStreamStatuses.find((deliveryStreamStatus) => {
		return deliveryStreamStatus.id === ACTIVE_STREAM_STATUS_ID;
	});

	const getFilterValuesFromLocalStorage = useCallback(
		() => {
			return getProjectPositionsFilterValuesFromLocalStorage(projectId);
		},
		[
			projectId,
		],
	);

	const updateFilterValuesInLocalStorage = useCallback(
		/**
		 * @param {ProjectPositionsFilterValues} filterValues
		 * @returns {void}
		 */
		(filterValues) => {
			return updateProjectPositionsFilterValuesInLocalStorage(
				filterValues,
				projectId,
			);
		},
		[
			projectId,
		],
	);

	useEffect(
		() => {
			const filterValues = getProjectPositionsFilterValuesFromLocalStorage(projectId);

			if (
				activeDeliveryStream
				&& !filterValues.deliveryStreamStatuses.length
			) {
				/** @type {ProjectPositionsFilterValues} */
				const updatedFilterValues = {
					...filterValues,
					deliveryStreamStatuses: [
						activeDeliveryStream,
					],
				};

				updateProjectPositionsFilterValuesInLocalStorage(
					updatedFilterValues,
					projectId,
				);

				applyFilter(updatedFilterValues);
			}
		},
		[
			projectId,
			activeDeliveryStream,
			applyFilter,
		],
	);

	return (
		<FilterWrapper
			filterChanged={getHasProjectPositionsFilterBeenChanged}
			filterValuesDefault={DEFAULT_PROJECT_POSITIONS_FILTER_VALUES}
			localStorageKey={localStorageKey}
			applyFilter={applyFilter}
			getFilterValuesFromLocalStorage={getFilterValuesFromLocalStorage}
			updateFilterValuesInLocalStorage={updateFilterValuesInLocalStorage}
			render={(filterValues, setFilterValue) => {
				return (
					<PositionsFilterBody
						isNewPermissionSchemeEnabled={isNewPermissionSchemeEnabled}
						projectId={projectId}
						fromDate={fromDate}
						toDate={toDate}
						filterValues={filterValues}
						setFilterValue={setFilterValue}
						breakdowns={breakdowns}
						structureData={structureData}
						deliveryStreamStatuses={deliveryStreamStatuses}
					/>
				);
			}}
			renderButton={FiltersButton}
		/>
	);
};

export default DashboardPositionsFilter;
