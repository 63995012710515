/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Badge,
	DataPickerRow,
	FlexRow,
	PickerInput,
	PickerItem,
	Text,
} from "@epam/loveship";
import {
	useArrayDataSource,
} from "@epam/uui-core";
import constant from "lodash/constant";
import groupBy from "lodash/groupBy";
import isUndefined from "lodash/isUndefined";
import mapValues from "lodash/mapValues";
import {
	type FC,
	useMemo,
} from "react";

import {
	EVENT_DELAY,
} from "components/consts";
import {
	DataPickerNotFoundPlaceholder,
} from "pages/components/data-picker-not-found-placeholder/data-picker-not-found-placeholder";

type BreakdownId = string;

type BreakdownName = string;

type BreakdownGroupId = string;

type BreakdownGroupIds = BreakdownGroupId[];

type BreakdownGroupName = string;

interface BreakdownGroup {
	id: BreakdownGroupId;
	name: BreakdownGroupName;
}

type BreakdownGroups = BreakdownGroup[];

interface Breakdown {
	id: BreakdownId;
	name: BreakdownName;
	groups: BreakdownGroups;
}

type Breakdowns = Breakdown[];

type BreakdownOptionId = BreakdownId | BreakdownGroupId;

interface BreakdownOption {
	id: BreakdownOptionId;
	name: BreakdownName | BreakdownGroupName;
	parentId?: BreakdownId;
}

type BreakdownOptions = BreakdownOption[];

type SelectedBreakdowns = Record<BreakdownId, BreakdownGroupIds>;

interface BreakdownDropdownProps {
	data: Breakdowns;
	selectedValues: SelectedBreakdowns;
	onChange: (nextSelectedBreakdowns: SelectedBreakdowns) => void;
}

const BreakdownDropdown: FC<BreakdownDropdownProps> = ({
	data: breakdowns,
	selectedValues: selectedBreakdowns,
	onChange,
}) => {
	const breakdownAndBreakdownGroupOptions = useMemo(
		() => {
			return breakdowns.reduce<BreakdownOptions>(
				(currentBreakdownOptions, breakdown) => {
					const {
						id,
						name,
						groups,
					} = breakdown;

					currentBreakdownOptions.push({
						id,
						name,
					});

					groups.forEach((breakdownGroup) => {
						const {
							id: breakdownGroupId,
							name: breakdownGroupName,
						} = breakdownGroup;

						currentBreakdownOptions.push({
							id: breakdownGroupId,
							name: breakdownGroupName,
							parentId: id,
						});
					});

					return currentBreakdownOptions;
				},
				[],
			);
		},
		[
			breakdowns,
		],
	);

	const breakdownOptionsData = useArrayDataSource<
		BreakdownOption,
		BreakdownOption["id"],
		unknown
	>(
		{
			items: breakdownAndBreakdownGroupOptions,
		},
		[],
	);

	const selectedBreakdownOptions = useMemo(
		() => {
			const breakdownGroupIds = Object.values(selectedBreakdowns).flat();

			return breakdownGroupIds.reduce<BreakdownOptions>(
				(currentSelectBreakdownOptions, breakdownGroupId) => {
					const breakdownGroupOption = breakdownAndBreakdownGroupOptions.find((option) => {
						return option.id === breakdownGroupId;
					});

					if (!isUndefined(breakdownGroupOption)) {
						currentSelectBreakdownOptions.push(breakdownGroupOption);
					}

					return currentSelectBreakdownOptions;
				},
				[],
			);
		},
		[
			breakdownAndBreakdownGroupOptions,
			selectedBreakdowns,
		],
	);

	const handleSelectionChange = (nextSelectedOptions: BreakdownOptions): void => {
		const nextSelectedBreakdownGroupOptions = nextSelectedOptions.filter((option) => {
			return !isUndefined(option.parentId);
		});

		const nextSelectedBreakdownGroupOptionsByBreakdownId = groupBy(
			nextSelectedBreakdownGroupOptions,
			(option) => {
				return option.parentId;
			},
		);

		const nextSelectedBreakdowns = mapValues(
			nextSelectedBreakdownGroupOptionsByBreakdownId,
			(options) => {
				return options.map((option) => {
					return option.id;
				});
			},
		);

		onChange(nextSelectedBreakdowns);
	};

	return (
		<PickerInput
			dataSource={breakdownOptionsData}
			value={selectedBreakdownOptions}
			onValueChange={handleSelectionChange}
			getName={(option) => {
				return option.name;
			}}
			selectionMode="multi"
			valueType="entity"
			maxItems={5}
			emptyValue={[]}
			// It allows to do dropdown width equal to input width.
			minBodyWidth={340}
			renderNotFound={DataPickerNotFoundPlaceholder}
			isFoldedByDefault={constant(false)}
			searchDebounceDelay={EVENT_DELAY}
			getRowOptions={(option) => {
				return {
					checkbox: {
						isVisible: !isUndefined(option.parentId),
					},
				};
			}}
			renderRow={(props, dataSourceState) => {
				const {
					value: option,
				} = props;

				if (
					!isUndefined(option)
					&& isUndefined(option.parentId)
				) {
					const {
						id,
						name,
					} = option;

					// eslint-disable-next-line no-autofix/@typescript-eslint/no-unnecessary-condition
					const selectedGroups = selectedBreakdowns[id] ?? [];
					const selectedGroupsCount = selectedGroups.length;

					return (
						<FlexRow
							key={props.rowKey}
							spacing={null}
							columnGap="6"
							padding="12"
						>
							<Text>
								{name}
							</Text>

							{
								selectedGroupsCount > 0
									? (
										<Badge
											caption={`+${selectedGroupsCount}`}
											shape="round"
											color="night300"
										/>
									)
									: null
							}
						</FlexRow>
					);
				}

				return (
					<DataPickerRow
						{...props}
						key={props.rowKey}
						indent={0}
						renderItem={(item) => {
							return (
								<PickerItem
									{...props}
									dataSourceState={dataSourceState}
									title={item.name}
								/>
							);
						}}
					/>
				);
			}}
		/>
	);
};

export {
	BreakdownDropdown,
};
