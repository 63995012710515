/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import map from "lodash/map";
import {
	nanoid,
} from "nanoid";

import {
	type ProjectStructureUnitId,
	type ProjectStructureUnits,
} from "models/project-structure/types";

import {
	SEPARATOR_UNIT_ID,
} from "../constants";

interface AddPostfixToUnitIdsParams {
	units: ProjectStructureUnits;
	parentId?: ProjectStructureUnitId | null;
}

const addPostfixToUnitIds = ({
	units,
	parentId = null,
}: AddPostfixToUnitIdsParams): ProjectStructureUnits => {
	return map(units, (unit) => {
		return {
			...unit,
			unitId: `${unit.unitId}${SEPARATOR_UNIT_ID}${nanoid()}`,
			parentId,
		};
	});
};

export {
	addPostfixToUnitIds,
};
