/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	type ProjectName,
} from "models/projects/types";

import {
	PROJECT_NAVIGATION_TAB_NAME,
} from "../../constants";
import {
	type UseNavigationDataResult,
} from "../../types";
import {
	getColumns,
} from "../../utils/get-columns";
import {
	ProjectList,
} from "../project-list/project-list";

interface ProjectNavigationStructureProps {
	navigationData: UseNavigationDataResult;
	projectName: ProjectName;
}

const ProjectNavigationStructure: FC<ProjectNavigationStructureProps> = ({
	projectName,
	navigationData,
}) => {
	const {
		view,
		dataSourceState,
		setDataSourceState,
	} = navigationData;

	return (
		<ProjectList
			projectName={projectName}
			dataSourceState={dataSourceState}
			onDataSourceStateChange={setDataSourceState}
			getRows={view.getVisibleRows}
			{...view.getListProps()}
			columns={
				getColumns({
					tabName: PROJECT_NAVIGATION_TAB_NAME.STRUCTURE,
				})
			}
		/>
	);
};

export {
	ProjectNavigationStructure,
};
