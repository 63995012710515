/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Tooltip,
} from "@epam/loveship";

import styles from "./Hours.module.css";

const HoursWithTotal = ({
	hours,
	total,
	children,
	className,
}) => {
	return (
		<div className={className}>
			<Tooltip
				content={children}
				placement="top"
				color="white"
			>
				<div className={styles.info}>
					<div className={styles["summary-hours"]}>
						{hours}
						h
					</div>
					<div className={styles.total}>
						{" "}
						of
						{" "}
						{total}
						h
					</div>
				</div>
			</Tooltip>
		</div>
	);
};

export default HoursWithTotal;
