/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Alert,
	Button,
	Checkbox,
	FlexCell,
	FlexRow,
	IconContainer,
	Tooltip,
} from "@epam/loveship";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";

import {
	displaySuccessNotification,
} from "components/common-components/notification/Notifications";
import {
	getEmployees,
} from "components/projects-billing/services";
import {
	ReactComponent as ArrowRightIcon,
} from "icons/arrow-right-24.svg";
import {
	ReactComponent as InfoIcon,
} from "icons/info-16.svg";
import {
	type EmployeeId,
	type EmployeeReduced,
} from "models/employees/types";
import {
	type KPSRoleInfo,
	type KPSTeamInfo,
} from "models/projects/types";
import {
	type WithClassName,
} from "types/common";
import {
	createEmailAndOpenNativeEmailClient,
} from "utils/email";

import CopyTextBtn from "../copy-text-btn";

import styles from "./SendMailMenu.module.css";

interface IKpsItem {
	id: string;
	email: string;
	fullName: string;
}

const MAIL_DROPDOWN_EMP_ROLES = {
	REGULAR: "regular",
	RM: "rms",
	KPS: "kps",
};

interface IGetEmailsFromServerArgs {
	ids: string[];
	convertImmediately?: boolean;
}

type EventLocation = "3dot_tab" | "sticky_popup";

interface IProps extends WithClassName {
	kpsTeam: KPSTeamInfo;
	employees?: Record<string, EmployeeReduced>;
	employeesArray: EmployeeReduced[];
	doesMenuOpenFromBulkPanel?: boolean;
	// This is used for Google Analytics events
	eventLocation: EventLocation;
}

const MAIL_TO_CHARS_LIMIT = 2048;

const SendMailMenu: FC<IProps> = ({
	kpsTeam,
	employees,
	employeesArray,
	doesMenuOpenFromBulkPanel,
	className,
	eventLocation,
}) => {
	const [
		regularTeamStatus,
		setRegularTeamStatus,
	] = useState(true);
	const [
		rmsOfRegularTeamStatus,
		setRmsOfRegularTeamStatus,
	] = useState(false);
	const [
		isRMsOfRegularTeamLoading,
		setIsRMsOfRegularTeamLoading,
	] = useState(false);
	const [
		openKpsMenu,
		setOpenKpsMenu,
	] = useState(true);
	const [
		kpsTeamStatus,
		setKpsTeamStatus,
	] = useState(false);
	const [
		kpsTeamLoading,
		setKPSTeamLoading,
	] = useState("");
	const [
		kpsTeamIndeterminateStatus,
		setKpsTeamIndeterminateStatus,
	] = useState(false);
	const [
		kpsCheckboxesValue,
		setKpsCheckboxesValue,
	] = useState<string[]>([]);

	const [
		copyingEmailsStatus,
		setCopyingEmailsStatus,
	] = useState(false);

	const [
		isLoading,
		setIsLoading,
	] = useState<boolean>(false);

	const [
		ccEmailsCache,
		setCcEmailsCache,
	] = useState({
		rmsOfRegularTeam: "",
		kpsTeam: [],
	});

	const regularTeamMembersTitle = doesMenuOpenFromBulkPanel === true
		? "Selected team members"
		: "Regular team members";
	const rmsOfRegularTeamMembersTitle = doesMenuOpenFromBulkPanel === true
		? "RMs of selected team members"
		: "RMs of regular team members";

	const employeesArr = !isUndefined(employees)
		? Object.values(employees)
		: employeesArray;

	const kpsTeamArray = Object.values(kpsTeam);

	const isCopySelectedEmailsBtnDisabled = isLoading || !(
		regularTeamStatus
		|| rmsOfRegularTeamStatus
		|| kpsTeamStatus
		|| kpsTeamIndeterminateStatus
	);

	const isKPSTeamsLoading = kpsTeamLoading === "all";

	const isClearAllButtonDisabled = isCopySelectedEmailsBtnDisabled;

	const convertEmailsForMail = (empList: EmployeeReduced[] | IKpsItem[]): string => {
		return empList
			.map<string>((emp) => {
				return emp.email.split("@").at(0) ?? "";
			})
			.join(";");
	};

	const [
		isMailToLimitExceeded,
		setIsMailToLimitExceeded,
	] = useState(() => {
		const toEmails = convertEmailsForMail(employeesArr);

		return toEmails.length >= MAIL_TO_CHARS_LIMIT;
	});

	const isSendEmailsBtnDisabled = (
		isMailToLimitExceeded
		|| isCopySelectedEmailsBtnDisabled
	);

	const getEmailsFromServer = async ({
		ids,
		convertImmediately = true,
		// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
		// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	}: IGetEmailsFromServerArgs) => {
		setIsLoading(true);

		// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const response = await getEmployees(ids);

		setIsLoading(false);

		if (convertImmediately) {
			// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			return convertEmailsForMail(response.employees);
		}

		// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
		return response.employees;
	};

	// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
	// eslint-disable-next-line consistent-return
	const checkRmsOfRegularTeamCache = async (getDataForCopyToClipboard?: boolean): Promise<string | undefined> => {
		if (ccEmailsCache.rmsOfRegularTeam.length !== 0) {
			if (getDataForCopyToClipboard === true) {
				return ccEmailsCache.rmsOfRegularTeam;
			}

			// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
			// eslint-disable-next-line consistent-return
			return;
		}

		const allRmIds = employeesArr.map((emp) => {
			return emp.managerUid;
		});
		const allUniqueRmIds = [
			...new Set(allRmIds),
		];

		setIsRMsOfRegularTeamLoading(true);

		// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const allRmsOfRegularTeam = await getEmailsFromServer({
			ids: allUniqueRmIds,
			convertImmediately: false,
		});

		const allRmsOfRegularTeamText = convertEmailsForMail(allRmsOfRegularTeam);

		setCcEmailsCache({
			...ccEmailsCache,
			rmsOfRegularTeam: allRmsOfRegularTeamText,
		});

		setIsRMsOfRegularTeamLoading(false);

		if (getDataForCopyToClipboard === true) {
			return allRmsOfRegularTeamText;
		}
	};

	interface CheckKpsTeamCacheParam {
		getDataForCopyToClipboard?: boolean;
		kpsId?: EmployeeId;
	}

	// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
	// eslint-disable-next-line consistent-return, @typescript-eslint/explicit-function-return-type
	const checkKpsTeamCache = async (param?: CheckKpsTeamCacheParam) => {
		if (ccEmailsCache.kpsTeam.length !== 0) {
			if (param?.getDataForCopyToClipboard === true) {
				return ccEmailsCache.kpsTeam;
			}

			// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
			// eslint-disable-next-line consistent-return
			return;
		}

		const allKpsIds = kpsTeamArray.map((kps) => {
			return kps.id;
		});

		setKPSTeamLoading(param?.kpsId ?? "all");

		// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const allKpsTeam = await getEmailsFromServer({
			ids: allKpsIds,
			convertImmediately: false,
		});

		setKPSTeamLoading("");

		setCcEmailsCache({
			...ccEmailsCache,
			// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			kpsTeam: allKpsTeam,
		});

		if (param?.getDataForCopyToClipboard === true) {
			// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return allKpsTeam;
		}
	};

	const onRegularTeamStatusChange = (): void => {
		setRegularTeamStatus(!regularTeamStatus);
	};

	const onRmsOfRegularTeamStatusChange = (): void => {
		// TODO: check is this Promise should be awaited.
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		checkRmsOfRegularTeamCache();

		setRmsOfRegularTeamStatus(!rmsOfRegularTeamStatus);
	};

	const onKpsTeamStatusChange = (): void => {
		let updatedKpsCheckboxesValue;

		setKpsTeamIndeterminateStatus(false);

		// TODO: check is this Promise should be awaited.
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		checkKpsTeamCache();

		if (
			kpsTeamIndeterminateStatus
			|| !kpsTeamStatus
		) {
			updatedKpsCheckboxesValue = kpsTeamArray.map((item) => {
				return item.id;
			});

			setKpsTeamStatus(true);

			setKpsCheckboxesValue(updatedKpsCheckboxesValue);

			return;
		}

		setKpsTeamStatus(false);

		setKpsCheckboxesValue([]);
	};

	const onKpsCheckboxesChange = (value: boolean, kpsId: string): void => {
		let updatedKpsCheckboxesValue;

		// TODO: check is this Promise should be awaited.
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		checkKpsTeamCache({
			kpsId,
		});

		if (value) {
			updatedKpsCheckboxesValue = [
				...kpsCheckboxesValue,
				kpsId,
			]
				.sort((element1, element2) => {
					return Number(element1) - Number(element2);
				});
		} else {
			updatedKpsCheckboxesValue = kpsCheckboxesValue.filter((checkboxValue) => {
				return checkboxValue !== kpsId;
			});
		}

		const isAllKpsChecked = updatedKpsCheckboxesValue.length === kpsTeamArray.length;
		const isAllKpsUnchecked = updatedKpsCheckboxesValue.length === 0;
		const isAnyKpsChecked = updatedKpsCheckboxesValue.length !== 0;

		if (isAllKpsChecked) {
			setKpsTeamStatus(true);

			setKpsTeamIndeterminateStatus(false);
		}

		if (isAllKpsUnchecked) {
			setKpsTeamStatus(false);

			setKpsTeamIndeterminateStatus(false);
		}

		if (
			!isAllKpsChecked
			&& isAnyKpsChecked
		) {
			setKpsTeamIndeterminateStatus(true);
		}

		setKpsCheckboxesValue(updatedKpsCheckboxesValue);
	};

	const unselectAllCheckboxes = (): void => {
		setRegularTeamStatus(false);

		setRmsOfRegularTeamStatus(false);

		setKpsTeamStatus(false);

		setKpsCheckboxesValue([]);

		setKpsTeamIndeterminateStatus(false);
	};

	const formatKpsTeamArrayToMailString = useCallback(
		(
			kpsTeamToFormat: IKpsItem[],
			filterValues: string[],
		) => {
			const allSelectedKps = kpsTeamToFormat.filter((kps: {id: string}) => {
				return filterValues.includes(kps.id);
			});

			return convertEmailsForMail(allSelectedKps);
		},
		[],
	);

	const getCcEmails = useCallback(
		() => {
			if (
				!rmsOfRegularTeamStatus
				&& !kpsTeamStatus
				&& !kpsTeamIndeterminateStatus
			) {
				return "";
			}

			let allSelectedRmsAndKpsEmails = "";

			if (rmsOfRegularTeamStatus) {
				allSelectedRmsAndKpsEmails = allSelectedRmsAndKpsEmails.concat(ccEmailsCache.rmsOfRegularTeam);
			}

			if (kpsCheckboxesValue.length !== 0) {
				const allSelectedKpsEmails = formatKpsTeamArrayToMailString(ccEmailsCache.kpsTeam, kpsCheckboxesValue);

				allSelectedRmsAndKpsEmails = allSelectedRmsAndKpsEmails.concat(`;${allSelectedKpsEmails}`);
			}

			return allSelectedRmsAndKpsEmails;
		},
		[
			ccEmailsCache.kpsTeam,
			ccEmailsCache.rmsOfRegularTeam,
			formatKpsTeamArrayToMailString,
			kpsCheckboxesValue,
			kpsTeamIndeterminateStatus,
			kpsTeamStatus,
			rmsOfRegularTeamStatus,
		],
	);

	const collectAllSelectedEmployeeIds = useCallback(
		() => {
			const toEmails = regularTeamStatus
				? convertEmailsForMail(employeesArr)
				: "";
			const ccEmails = getCcEmails();

			return {
				ccEmails,
				toEmails,
			};
		},
		[
			employeesArr,
			getCcEmails,
			regularTeamStatus,
		],
	);

	const selectedRoles = useMemo<string[]>(
		() => {
			const currentSelectedRoles = kpsCheckboxesValue.reduce<KPSRoleInfo["role"][]>(
				(currentSelectedRolesKPS, kpsId) => {
					const kps = kpsTeamArray.find(({
						id,
					}) => {
						return id === kpsId;
					});

					if (!isUndefined(kps)) {
						currentSelectedRolesKPS.push(kps.role);
					}

					return currentSelectedRolesKPS;
				},
				[],
			);

			if (regularTeamStatus) {
				currentSelectedRoles.push(regularTeamMembersTitle);
			}

			if (rmsOfRegularTeamStatus) {
				currentSelectedRoles.push(rmsOfRegularTeamMembersTitle);
			}

			return currentSelectedRoles;
		},
		[
			kpsTeamArray,
			kpsCheckboxesValue,
			regularTeamMembersTitle,
			regularTeamStatus,
			rmsOfRegularTeamMembersTitle,
			rmsOfRegularTeamStatus,
		],
	);

	const copyEmailsFromSelectedRoles = (): void => {
		const {
			toEmails,
			ccEmails,
		} = collectAllSelectedEmployeeIds();
		const allEmails = `${
			toEmails === ""
				? ""
				: `${toEmails};`
		}${ccEmails}`;

		// TODO: check is this Promise should be awaited.
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		navigator.clipboard.writeText(allEmails)
			.then(() => {
				setCopyingEmailsStatus(true);

				const SHOW_SUCCESS_STATUS_DURATION = 2000;

				setTimeout(() => {
					setCopyingEmailsStatus(false);
				}, SHOW_SUCCESS_STATUS_DURATION);
			});

		window.dataLayer.push({
			event_name: eventLocation,
			event_action: "copy selected",
			event_text: selectedRoles.join(),
			event: "autoevent",
		});
	};

	const sendEmail = (): void => {
		const {
			toEmails,
			ccEmails,
		} = collectAllSelectedEmployeeIds();

		// TODO: add new lines.
		// eslint-disable-next-line max-len
		const notificationText = "Please note that an email is created only if Outlook is set as the default email client. Otherwise, the employees' emails are just copied to the clipboard.";

		// TODO: check is this Promise should be awaited.
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		navigator.clipboard.writeText(toEmails)
			.then(() => {
				displaySuccessNotification(notificationText);
			});

		createEmailAndOpenNativeEmailClient({
			to: toEmails,
			cc: ccEmails,
		});

		window.dataLayer.push({
			event_name: eventLocation,
			event_action: "compose email",
			event_text: selectedRoles.join(),
			event: "autoevent",
		});
	};

	const copyTextToClipboard = async (text?: string): Promise<void> => {
		if (
			isUndefined(text)
			|| isEmpty(text)
		) {
			return;
		}

		await navigator.clipboard.writeText(text);
	};

	const copyEmails = async (role: string, kpsId?: string): Promise<void> => {
		let emailsInTextFormat;

		switch (role) {
			case MAIL_DROPDOWN_EMP_ROLES.REGULAR: {
				emailsInTextFormat = convertEmailsForMail(employeesArr);

				break;
			}

			case MAIL_DROPDOWN_EMP_ROLES.RM: {
				emailsInTextFormat = await checkRmsOfRegularTeamCache(true);

				break;
			}

			case MAIL_DROPDOWN_EMP_ROLES.KPS: {
				// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const kpsEmails = await checkKpsTeamCache({
					getDataForCopyToClipboard: true,
				});
				// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
				let kpsTeamIds = kpsEmails.map((kps: IKpsItem) => {
					return kps.id;
				});

				if (
					!isUndefined(kpsId)
					&& !isEmpty(kpsId)
				) {
					// Will be fixed in https://gitbud.epam.com/epm-time/epm-time-frontend/-/merge_requests/241.
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
					kpsTeamIds = kpsTeamIds.filter((id: string) => {
						return id === kpsId;
					});
				}

				emailsInTextFormat = formatKpsTeamArrayToMailString(kpsEmails, kpsTeamIds);

				break;
			}

			default: {
				break;
			}
		}

		await copyTextToClipboard(emailsInTextFormat);
	};

	useEffect(
		() => {
			const {
				toEmails,
				ccEmails,
			} = collectAllSelectedEmployeeIds();

			const mailToCharsLength = ccEmails.length + toEmails.length;

			if (mailToCharsLength < MAIL_TO_CHARS_LIMIT) {
				setIsMailToLimitExceeded(false);

				return;
			}

			setIsMailToLimitExceeded(true);
		},
		[
			collectAllSelectedEmployeeIds,
		],
	);

	return (
		<div
			className={
				classNames(
					styles.mailDropdown,
					className,
				)
			}
		>
			<div className={styles.section}>
				<div className={styles.target}>
					<span className={styles.mainTitle}>
						Send email to
					</span>
					<Tooltip
						content={(
							<span>
								Please note that an email is created only if Outlook is set as the default email client.
							</span>
						)}
						color="white"
					>
						<IconContainer
							icon={InfoIcon}
							cx={styles.tooltipIcon}
						/>
					</Tooltip>
				</div>
			</div>
			<FlexRow
				cx={styles.menuItem}
				size="36"
			>
				<Checkbox
					label={regularTeamMembersTitle}
					value={regularTeamStatus}
					onValueChange={onRegularTeamStatusChange}
					isDisabled={isLoading}
				/>
				<CopyTextBtn
					onClick={async () => {
						await copyEmails(MAIL_DROPDOWN_EMP_ROLES.REGULAR);
					}}
				/>
			</FlexRow>
			<div className={styles.section}>
				<FlexRow size="24">
					<span className={styles.sectionTitle}>
						Add to CC:
					</span>
				</FlexRow>
				<FlexRow
					cx={styles.menuItem}
					size="36"
				>
					<Checkbox
						label={rmsOfRegularTeamMembersTitle}
						value={rmsOfRegularTeamStatus}
						onValueChange={onRmsOfRegularTeamStatusChange}
						isDisabled={isLoading}
					/>
					<CopyTextBtn
						onClick={async () => {
							await copyEmails(MAIL_DROPDOWN_EMP_ROLES.RM);
						}}
						isLoading={isRMsOfRegularTeamLoading}
					/>
				</FlexRow>
				<FlexRow cx={styles.menuItemKpsWrapper}>
					<IconContainer
						icon={ArrowRightIcon}
						cx={
							classNames(
								styles.kpsDropdownTargetIcon,
								{
									[styles.kpsDropdownOpen]: openKpsMenu,
								},
							)
						}
						onClick={() => {
							setOpenKpsMenu(!openKpsMenu);
						}}
					/>
					<FlexRow
						cx={styles.menuItemKps}
						size="36"
						onClick={() => {
							setOpenKpsMenu(!openKpsMenu);
						}}
					>
						<div className={styles.kpsCheckbox}>
							<Checkbox
								value={kpsTeamStatus}
								indeterminate={kpsTeamIndeterminateStatus}
								onValueChange={onKpsTeamStatusChange}
								isDisabled={isLoading}
							/>
							<span className={styles.kpsDropdownTargetText}>
								Key project staff
							</span>
						</div>
						<CopyTextBtn
							onClick={async () => {
								await copyEmails(MAIL_DROPDOWN_EMP_ROLES.KPS);
							}}
							isLoading={isKPSTeamsLoading}
						/>

					</FlexRow>
				</FlexRow>
				{
					openKpsMenu
						? (
							<FlexCell>
								{
									kpsTeamArray.map((kps) => {
										const {
											id,
											role,
										} = kps;

										return (
											<FlexRow
												cx={styles.kpsDropdownMenuItem}
												size="36"
												key={`${id}-${role}`}
											>
												<Checkbox
													label={role}
													value={kpsCheckboxesValue.includes(id)}
													onValueChange={(value) => {
														onKpsCheckboxesChange(value, id);
													}}
													isDisabled={isLoading}
												/>
												<CopyTextBtn
													onClick={async () => {
														await copyEmails(MAIL_DROPDOWN_EMP_ROLES.KPS, id);
													}}
													isLoading={
														isKPSTeamsLoading
														|| kpsTeamLoading === id
													}
												/>
											</FlexRow>
										);
									})
								}
							</FlexCell>
						)
						: null
				}

			</div>
			{
				isMailToLimitExceeded
					? (
						<Alert
							color="sun"
							cx={styles.limitExceededTextAlert}
						>
							<div className={styles.limitExceededText}>
								Too many recipients. To send an email, reduce the number of recipients, or copy the emails and paste
								them manually.
							</div>
						</Alert>
					)
					: null
			}
			<div className={styles.dividerContainer}>
				<div className={styles.divider}/>
			</div>
			<FlexRow cx={styles.actions}>
				<Button
					caption="Clear all"
					color="sky"
					fill="white"
					size="24"
					onClick={unselectAllCheckboxes}
					isDisabled={isClearAllButtonDisabled}
				/>
				<Button
					caption={
						copyingEmailsStatus
							? "Copied"
							: "Copy selected"
					}
					color="sky"
					fill="white"
					size="24"
					cx={styles.copySelectedBtn}
					onClick={copyEmailsFromSelectedRoles}
					isDisabled={isCopySelectedEmailsBtnDisabled}
				/>
				<Button
					caption="Compose"
					color="sky"
					size="24"
					cx={styles.composeButton}
					onClick={sendEmail}
					isDisabled={isSendEmailsBtnDisabled}
				/>
			</FlexRow>
		</div>
	);
};

export default SendMailMenu;
