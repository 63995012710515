/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type PayloadAction,
	createSlice,
} from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import without from "lodash/without";

import {
	type ProjectStructureUnitId,
	type ProjectStructureUnitIds,
} from "models/project-structure/types";

import {
	removePostfixFromUnitId,
} from "../utils/remove-postfix-from-unit-id";

interface ProjectNavigationState {
	favoriteUnitIds: ProjectStructureUnitIds;
}

const initialState: ProjectNavigationState = {
	favoriteUnitIds: [],
};

const projectNavigationSlice = createSlice({
	name: "projectNavigation",
	initialState,
	reducers: {
		addUnitsToFavorites: (
			state,
			action: PayloadAction<ProjectStructureUnitIds>,
		) => {
			const newUnitIds = action.payload;
			const newUnitIdsWithoutPostfix = newUnitIds.map((unitId) => {
				return removePostfixFromUnitId(unitId);
			});
			const favoriteUnitIdsNext = state.favoriteUnitIds.concat(
				...newUnitIdsWithoutPostfix,
			);
			const uniqueFavoriteUnitIdsNext = uniq(favoriteUnitIdsNext);

			// eslint-disable-next-line no-param-reassign
			state.favoriteUnitIds = uniqueFavoriteUnitIdsNext;
		},
		toggleFavoriteUnit: (
			state,
			action: PayloadAction<ProjectStructureUnitId>,
		) => {
			const unitId = action.payload;
			const unitIdWithoutPostfix = removePostfixFromUnitId(unitId);
			const favoriteUnitIdsCurrent = state.favoriteUnitIds;
			const isFavoriteUnit = favoriteUnitIdsCurrent.includes(
				unitIdWithoutPostfix,
			);

			if (isFavoriteUnit) {
				// eslint-disable-next-line no-param-reassign
				state.favoriteUnitIds = without(
					favoriteUnitIdsCurrent,
					unitIdWithoutPostfix,
				);

				return;
			}

			const favoriteUnitIdsNext = favoriteUnitIdsCurrent.concat(
				unitIdWithoutPostfix,
			);
			const uniqueFavoriteUnitIdsNext = uniq(favoriteUnitIdsNext);

			// eslint-disable-next-line no-param-reassign
			state.favoriteUnitIds = uniqueFavoriteUnitIdsNext;
		},
	},
});

const projectNavigationReducer = projectNavigationSlice.reducer;

const {
	addUnitsToFavorites,
	toggleFavoriteUnit,
} = projectNavigationSlice.actions;

export {
	projectNavigationReducer,
	addUnitsToFavorites,
	toggleFavoriteUnit,
};
