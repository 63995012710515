/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type FC,
} from "react";

import {
	type ProjectStructureUnits,
} from "models/project-structure/types";
import {
	type ProjectName,
} from "models/projects/types";

import {
	PROJECT_NAVIGATION_TAB_NAME,
} from "../../constants";
import {
	useNavigationDataSearch,
} from "../../hooks/use-navigation-data-search";
import {
	getColumns,
} from "../../utils/get-columns";
import {
	ProjectList,
} from "../project-list/project-list";

interface ProjectNavigationSearchProps {
	units: ProjectStructureUnits;
	closeNavigationSidebar: () => void;
	projectName: ProjectName;
}

const ProjectNavigationSearch: FC<ProjectNavigationSearchProps> = ({
	units,
	closeNavigationSidebar,
	projectName,
}) => {
	const {
		view,
		dataSourceState,
		setDataSourceState,
	} = useNavigationDataSearch({
		units,
		closeNavigationSidebar,
	});

	return (
		<ProjectList
			projectName={projectName}
			dataSourceState={dataSourceState}
			onDataSourceStateChange={setDataSourceState}
			getRows={view.getVisibleRows}
			{...view.getListProps()}
			columns={
				getColumns({
					tabName: PROJECT_NAVIGATION_TAB_NAME.STRUCTURE,
				})
			}
		/>
	);
};

export {
	ProjectNavigationSearch,
};
