/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as CopyIcon,
} from "@epam/assets/icons/common/action-copy_content-24.svg";
import {
	ReactComponent as CheckIcon,
} from "@epam/assets/icons/common/notification-check-fill-24.svg";
import {
	ReactComponent as CircleLoader,
} from "@epam/assets/icons/loaders/circle_loader_30.svg";
import {
	FlexRow,
	IconButton,
	IconContainer,
	Tooltip,
} from "@epam/loveship";
import {
	type FC,
	useState,
} from "react";

import styles from "./CopyTextBtn.module.css";

interface CopyTextBtnProps {
	onClick: () => void;
	isLoading?: boolean;
}

const CopyTextBtn: FC<CopyTextBtnProps> = ({
	onClick,
	isLoading = false,
}) => {
	const [
		showSuccessStatus,
		setShowSuccessStatus,
	] = useState(false);

	const SHOW_SUCCESS_STATUS_DURATION = 1000;

	const copyText = (): void => {
		if (showSuccessStatus) {
			return;
		}

		setShowSuccessStatus(true);

		onClick();

		setTimeout(
			() => {
				setShowSuccessStatus(false);
			},
			SHOW_SUCCESS_STATUS_DURATION,
		);
	};

	if (isLoading) {
		return (
			<CircleLoader/>
		);
	}

	return (
		<FlexRow>
			{
				showSuccessStatus
					? (
						<IconContainer
							icon={CheckIcon}
							cx={styles.copySuccessIcon}
						/>
					)
					: (
						<Tooltip
							color="white"
							placement="top"
							content="Copy emails to clipboard"
						>
							<IconButton
								icon={CopyIcon}
								color="night500"
								onClick={copyText}
								cx={styles.copyIcon}
							/>
						</Tooltip>
					)
			}
		</FlexRow>
	);
};

export default CopyTextBtn;
