/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
*/
import {
	ProjectCell,
} from "../components/project-list/components/project-cell/project-cell";
import {
	type PROJECT_NAVIGATION_TAB_NAME,
} from "../constants";
import {
	type NavigationDataColumns,
} from "../types";

interface GetColumnsParams {
	tabName: PROJECT_NAVIGATION_TAB_NAME;
	shouldColorizeUnfavoriteUnits?: boolean;
}

const getColumns = ({
	tabName,
	shouldColorizeUnfavoriteUnits,
}: GetColumnsParams): NavigationDataColumns => {
	return [
		{
			key: "name",
			caption: "",
			render: (props) => {
				return (
					<ProjectCell
						shouldColorizeUnfavoriteUnits={shouldColorizeUnfavoriteUnits}
						tabName={tabName}
						unitData={props}
					/>
				);
			},
			minWidth: 400,
			width: 0,
			grow: 1,
		},
	];
};

export {
	getColumns,
};
