/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Checkbox,
	Dropdown,
	LinkButton,
	Text,
} from "@epam/loveship";
import {
	Component,
} from "react";
import {
	connect,
} from "react-redux";
import {
	withRouter,
} from "react-router-dom";

import {
	getProjectsCommonFilterValuesFromLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	svc,
} from "constants/services";
import {
	formatLockDate,
	getMaxDate,
	isDateAfter,
	isDateBefore,
	isEqualOrAfter,
	isEqualOrBefore,
} from "models/dates-and-time/utils/common";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	getUserName,
} from "store/slices/application-info/selectors";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	PROJECT_BILLING_TYPE_IDS,
} from "../../../../environment/consts";
import ChooseDate from "../../../common-components/choose-date-period/ChooseDate";
import ChoosePeriod from "../../../common-components/choose-date-period/ChoosePeriod";
import Confirm from "../../../common-components/confirm/Confirm";
import {
	showMarkupActionsModal,
} from "../../../common-components/markups-actions/showMarkupActionsModal";
import MultipleChoicePanel from "../../../common-components/multiple-choice/MultipleChoicePanel";
import {
	getArray,
} from "../../../common-components/multiple-choice/utils";
import {
	displayErrorNotification,
	displaySuccessNotification,
} from "../../../common-components/notification/Notifications";
import {
	MARKUP_ACTION_TYPE,
	MARKUP_APPLIED_LEVEL,
	MARKUP_PERIOD_TYPE,
} from "../../../consts";
import {
	employeeReportingLock,
	employeeReportingUnlock,
	processPeriod,
} from "../../../utils";
import {
	lockBillingByEmployee,
	lockReportingPeriod,
	removeMarkupStatus,
	requestBillingUnlock,
	setMarkupStatus,
	unlockBillingPeriod,
	unlockReportingPeriod,
	updateProjects,
} from "../../actionCreators";
import {
	MULTIPLE_DASHBOARD_EMPLOYEES_NAME,
} from "../../constants";
import {
	mergeMarkupStatuses,
} from "../../utils";
import {
	deselectAllEmployeesToUnlock,
	selectAllEmployeesToUnlock,
} from "../multiple-choices/actionCreators";
import RequestUnlockMessage from "../request-unlock-message/RequestUnlockMessage";
import SendMailMenu from "../send-mail-menu";

import styles from "./LockPanel.module.css";

/**
 * @typedef {Object} SeveralEmployeesLockPanelProps
 * @property {boolean} isNewPermissionSchemeEnabled
 */

/**
 * @extends {Component<SeveralEmployeesLockPanelProps, Object>}
 */
class SeveralEmployeesLockPanel extends Component {
	onReportingUnLock = () => {
		const {
			project,
			employees,
			updateProjects,
			unlockReportingPeriod,
			match: {
				params: {
					fromDate: from,
					toDate: to,
				},
			},
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		employeeReportingUnlock(
			isNewPermissionSchemeEnabled,
			from,
			to,
			employees,
			`${employees.length} employee(s)`,
			project,
			unlockReportingPeriod,
			showSpinner,
		)
			.then((res) => {
				if (res) {
					if (!res.error) {
						updateProjects(
							isNewPermissionSchemeEnabled,
							from,
							to,
							[
								project.id,
							],
							showWithNpa,
							showWithVacation,
						)
							.then(() => {
								const message = `${
									res.unlockedEmployees
								} employee(s) unlocked in ${project.name} from ${res.from} till ${res.to}`;

								window.dataLayer.push({
									event_name: "sticky_popup",
									event_action: "unlock reporting",
									event: "autoevent",
								});

								hideSpinner();

								displaySuccessNotification(message);
							});
					} else {
						const message = res.errorMessage;

						hideSpinner();

						displaySuccessNotification(message);
					}
				}
			});
	};

	checkCorrectWorklogs = (from, to) => {
		const {
			employees,
			project,
		} = this.props;
		let onlyCorrectWorklogs = true;

		employees.forEach((empId) => {
			const employee = project.employees[empId];

			processPeriod(from, to, (date) => {
				const daySummary = employee.days[date];

				if (
					daySummary
					&& !daySummary.hasPosition
					&& (
						daySummary.billable
						|| daySummary.nonBillable
					)
				) {
					onlyCorrectWorklogs = false;
				}
			});
		});

		return onlyCorrectWorklogs;
	};

	onReportingLock = () => {
		const {
			project,
			employees,
			updateProjects,
			lockReportingPeriod,
			match: {
				params: {
					fromDate: from,
					toDate: to,
				},
			},
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		employeeReportingLock(
			isNewPermissionSchemeEnabled,
			from,
			to,
			employees,
			`${employees.length} employee(s)`,
			project,
			this.checkCorrectWorklogs,
			lockReportingPeriod,
			showSpinner,
		)
			.then((res) => {
				if (res) {
					if (!res.error) {
						updateProjects(
							isNewPermissionSchemeEnabled,
							from,
							to,
							[
								project.id,
							],
							showWithNpa,
							showWithVacation,
						)
							.then(() => {
								const message = `${
									res.unlockedEmployees
								} employee(s) locked in ${project.name} from ${res.from} till ${res.to}`;

								window.dataLayer.push({
									event_name: "sticky_popup",
									event_action: "lock reporting",
									event: "autoevent",
								});

								hideSpinner();

								displaySuccessNotification(message);
							});
					} else {
						const message = res.errorMessage;

						hideSpinner();

						displayErrorNotification(message);
					}
				}
			});
	};

	onUnlock = () => {
		const {
			project,
			employees,
			unlockBillingPeriod,
			match: {
				params: {
					fromDate: from,
					toDate: to,
				},
			},
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		svc.uuiModals.show((props) => {
			return (
				<ChoosePeriod
					{...props}
					header="Unlock billing period"
					subheader={`for ${employees.length} employee(s) from ${project.name}`}
					actionTitle="Unlock"
					fromDate={from}
					toDate={to}
					checkDateErrors={this.checkDateErrors}
					checkDateWarnings={this.checkDateWarnings}
				/>
			);
		})
			.then((data) => {
				if (data) {
					showSpinner();

					unlockBillingPeriod(
						isNewPermissionSchemeEnabled,
						data.fromDate,
						data.toDate,
						project.id,
						employees,
						from,
						to,
						showWithNpa,
						showWithVacation,
					)
						.then((res) => {
							const message = `${res.unlockedEmployees} employee(s) unlocked in ${project.name}`;

							hideSpinner();

							window.dataLayer.push({
								event_name: "sticky_popup",
								event_action: "unlock billing",
								event: "autoevent",
							});

							displaySuccessNotification(message);
						});
				}
			});
	};

	checkDateForBillingLock = (date) => {
		const res = [];
		const {
			project,
		} = this.props;

		if (!date) {
			res.push("Date is required.");
		} else {
			if (
				isDateAfter({
					date: project.billingPastLimitDay,
					dateToCompare: date,
				})
			) {
				res.push(`Red lock is already on ${formatLockDate(project.billingPastLimitDay)}`);
			}

			if (
				isEqualOrBefore({
					date: project.billingLimitDay,
					dateToCompare: date,
				})
			) {
				res.push(`Employee(s) can't be locked on or after ${formatLockDate(project.reportingLimitDay)}`);
			}
		}

		return res;
	};

	onLock = () => {
		const {
			employees,
			project,
			lockBillingByEmployee,
			match: {
				params: {
					fromDate: from,
					toDate: to,
				},
			},
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		const subheader = `for ${employees.length} employee(s) from ${project.name}`;

		svc.uuiModals.show((props) => {
			return (
				<ChooseDate
					{...props}
					header="Lock billing period"
					subheader={subheader}
					actionTitle="Lock"
					date={
						formatLockDate(
							getMaxDate([
								to,
								project.billingLockDay,
							]),
						)
					}
					checkDate={this.checkDateForBillingLock}
					tips={[
						`Project lock is set for ${formatLockDate(project.billingLockDay)}`,
						`Red lock is set for ${formatLockDate(project.billingPastLimitDay)}`,
					]}
				/>
			);
		})
			.then((data) => {
				if (data) {
					if (this.checkCorrectWorklogs(from, data.date)) {
						showSpinner();

						lockBillingByEmployee(
							isNewPermissionSchemeEnabled,
							data.date,
							project.id,
							employees,
							from,
							to,
							showWithNpa,
							showWithVacation,
						)
							.then((res) => {
								hideSpinner();

								if (
									res
									&& !res.error
								) {
									const message = `${res.lockedEmployees} employee(s) locked in ${project.name}`;

									window.dataLayer.push({
										event_name: "sticky_popup",
										event_action: "lock billing",
										event: "autoevent",
									});

									displaySuccessNotification(message);
								} else {
									displayErrorNotification(res.errorMessage);
								}
							});
					} else {
						svc.uuiModals.show((props) => {
							return (
								<Confirm
									{...props}
									title=""
									corfirmTitle="Close"
									message={(
										<span>
											There are worklogs that do not meet Time requirements anymore. Please correct the issues using Manage worklogs option.
											{" "}
											<a
												className="link"
												target="_blank"
												rel="noopener noreferrer"
												href="https://kb.epam.com/pages/viewpage.action?pageId=632884240#Release1.17.3(10July2018)-Incorrectworklogs"
											>
												Learn more...
											</a>
										</span>
									)}
								/>
							);
						});
					}
				}
			});
	};

	checkDateErrors = (fromDate, toDate) => {
		const res = {
			fromDateHasError: false,
			toDateHasError: false,
			descriptions: [],
		};

		if (!fromDate) {
			res.descriptions.push("Date from is required.");

			res.fromDateHasError = true;
		}

		if (!toDate) {
			res.descriptions.push("Date to is required.");

			res.toDateHasError = true;
		}

		if (
			fromDate
			&& toDate
			&& isDateAfter({
				date: fromDate,
				dateToCompare: toDate,
			})
		) {
			res.descriptions.push("'To' date must be later than the 'From' date");

			res.fromDateHasError = true;

			res.toDateHasError = true;
		}

		return res;
	};

	checkDateWarnings = (fromDate, toDate) => {
		const {
			project,
		} = this.props;
		const res = [];

		if (
			fromDate
			&& isDateBefore({
				date: fromDate,
				dateToCompare: project.billingPastLimitDay,
			})
		) {
			res.push(`Unlocked periods from ${formatLockDate(fromDate)} to ${
				isDateBefore({
					date: toDate,
					dateToCompare: project.billingPastLimitDay,
				})
					? formatLockDate(toDate)
					: formatLockDate(project.billingPastLimitDay)
			} are automatically locked in 24 hrs`);
		}

		return res;
	};

	requestBillingUnlock = () => {
		const {
			project,
			employees,
			requestBillingUnlock,
			match: {
				params: {
					fromDate: from,
					toDate: to,
				},
			},
			isNewPermissionSchemeEnabled,
		} = this.props;

		const isEpamCustomer = (
			Number(project.billingTypeId) === PROJECT_BILLING_TYPE_IDS.NON_BILLABLE
			|| Number(project.billingTypeId) === PROJECT_BILLING_TYPE_IDS.INTERNAL_PROJECT
		);

		const recipient = project.billingUnlockRequestRecipient
			? project.billingUnlockRequestRecipient
			: isEpamCustomer
				? "WFAPLAnnotations@epam.com"
				: "WFAOTCBusinessPartnering@epam.com";

		svc.uuiModals.show((props) => {
			return (
				<RequestUnlockMessage {...props}/>
			);
		})
			.then((data) => {
				if (data) {
					requestBillingUnlock({
						isNewPermissionSchemeEnabled,
						projectName: project.name,
						reason: data.reason,
						from,
						to,
						billingUnlockRequestRecipient: recipient,
						toUnlockEmployeeUids: [
							...employees,
						],
					})
						.then((res) => {
							if (res) {
								window.dataLayer.push({
									event_name: "sticky_popup",
									event_action: "request billing unlock",
									event: "autoevent",
								});

								displaySuccessNotification("Request has been sent");
							} else {
								displayErrorNotification("Something went wrong. Try again later.");
							}
						});
				}
			});
	};

	selectAll = () => {
		const {
			project,
			filteredEmployees,
			selectAllEmployeesToUnlock,
			match: {
				params: {
					viewMode,
				},
			},
		} = this.props;

		const isEmployeeDailyView = viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY;

		selectAllEmployeesToUnlock({
			projectId: project.id,
			filteredEmployees,
			isEmployeeDailyView,
		});
	};

	deselectAll = () => {
		const {
			deselectAllEmployeesToUnlock,
		} = this.props;

		deselectAllEmployeesToUnlock();
	};

	onMarkupEmployees = (action) => {
		const {
			project,
			employees,
			setMarkupStatus,
			removeMarkupStatus,
			match: {
				params: {
					fromDate: dashboardFrom,
					toDate: dashboardTo,
				},
			},
			hideSpinner,
			showSpinner,
			isNewPermissionSchemeEnabled,
		} = this.props;
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		const onMarkupCallback = (data) => {
			const {
				markupLevel,
				statusId,
				from,
				to,
			} = data;

			showSpinner();

			if (action === MARKUP_ACTION_TYPE.APPLY) {
				setMarkupStatus(
					isNewPermissionSchemeEnabled,
					markupLevel,
					[
						project.id,
					],
					employees,
					statusId,
					MARKUP_PERIOD_TYPE.PERIOD,
					from,
					to,
					dashboardFrom,
					dashboardTo,
					showWithNpa,
					showWithVacation,
				)
					.then(() => {
						window.dataLayer.push({
							event_name: "sticky_popup",
							event_action: "set markup",
							event: "autoevent",
						});

						hideSpinner();
					});
			} else {
				removeMarkupStatus(
					isNewPermissionSchemeEnabled,
					markupLevel,
					[
						project.id,
					],
					employees,
					statusId,
					MARKUP_PERIOD_TYPE.PERIOD,
					from,
					to,
					dashboardFrom,
					dashboardTo,
					showWithNpa,
					showWithVacation,
				)
					.then(() => {
						window.dataLayer.push({
							event_name: "sticky_popup",
							event_action: "remove markup",
							event: "autoevent",
						});

						hideSpinner();
					});
			}
		};

		let availableStatuses;

		if (action === MARKUP_ACTION_TYPE.REMOVE) {
			const appliedStatuses = new Set();

			employees.forEach((empId) => {
				const employee = project.employees[empId];

				if (
					employee.markedWorklogInfo
					&& employee.markedWorklogInfo.length
				) {
					employee.markedWorklogInfo.forEach((worklog) => {
						appliedStatuses.add(worklog.statusId);
					});
				}
			});

			availableStatuses = mergeMarkupStatuses(project.employees, employees)[action]
				.reduce((statuses, status) => {
					if (appliedStatuses.has(status.id)) {
						return [
							...statuses,
							status,
						];
					}

					return statuses;
				}, []);
		} else {
			availableStatuses = mergeMarkupStatuses(project.employees, employees)[action];
		}

		showMarkupActionsModal({
			id: employees,
			action,
			markupLevel: MARKUP_APPLIED_LEVEL.EMPLOYEE,
			statuses: availableStatuses,
			callback: onMarkupCallback,
			filterDates: {
				from: dashboardFrom,
				to: dashboardTo,
			},
		});
	};

	render() {
		const {
			project,
			employees,
			hasStatusesForRemove,
			hasMarkupActionsForApply,
			...resProps
		} = this.props;
		const {
			viewMode,
			fromDate: from,
		} = this.props.match.params;
		const isBillingLocked = (
			project
			&& isEqualOrAfter({
				date: project.billingLockDay,
				dateToCompare: from,
			})
		);

		const selectedEmployees = project?.employeesFromHeader?.filter((emp) => {
			return employees.includes(emp.uid);
		});

		return (
			<MultipleChoicePanel
				arr={employees}
				className="lock-panel"
				{...resProps}
			>
				<Checkbox
					value={
						Boolean(
							employees
							&& employees.length,
						)
					}
					onValueChange={this.deselectAll}
					label={`${employees.length} employee(s) selected`}
				/>

				<div
					style={{
						marginLeft: "12px",
					}}
				/>

				<LinkButton
					cx={styles["link-button"]}
					onClick={this.selectAll}
					caption="Select all employees"
				/>

				<div
					style={{
						marginLeft: "auto",
					}}
				/>

				<Dropdown
					openOnClick={true}
					renderTarget={(props) => {
						return (
							<LinkButton
								{...props}
								cx={styles["link-button"]}
								caption="Create mail"
								rawProps={{
									"data-name": toDataAttribute("Create email button"),
								}}
							/>
						);
					}}
					renderBody={() => {
						return (
							<SendMailMenu
								kpsTeam={project.kpsTeam}
								employees={selectedEmployees}
								doesMenuOpenFromBulkPanel={true}
								eventLocation="sticky_popup"
							/>
						);
					}}
				/>

				{
					(
						viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
						&& (
							hasMarkupActionsForApply
							|| hasStatusesForRemove
						)
					)
						? (
							<>
								<Text cx={styles.title}>
									Worklog status:
								</Text>

								{
									hasMarkupActionsForApply
										? (
											<LinkButton
												cx={
													hasStatusesForRemove
														? styles["link-button"]
														: styles["markup-button"]
												}
												onClick={() => {
													this.onMarkupEmployees(MARKUP_ACTION_TYPE.APPLY);
												}}
												caption="Set"
												rawProps={{
													"data-name": toDataAttribute("Set worklog status button"),
												}}
											/>
										)
										: null
								}

								{
									hasStatusesForRemove
										? (
											<>
												<div className={styles.divider}/>
												<LinkButton
													cx={styles["markup-button"]}
													onClick={() => {
														this.onMarkupEmployees(MARKUP_ACTION_TYPE.REMOVE);
													}}
													caption="Remove"
													rawProps={{
														"data-name": toDataAttribute("Remove worklog status button"),
													}}
												/>
											</>
										)
										: null
								}
							</>
						)
						: null
				}

				{
					(
						project
						&& project.hasReportingLockPermission
					)
						? (
							<>
								<Text cx={styles.title}>
									Reporting:
								</Text>

								<LinkButton
									cx={styles["link-button"]}
									onClick={this.onReportingLock}
									caption="Lock"
									rawProps={{
										"data-name": toDataAttribute("Lock reporting button"),
									}}
								/>

								<div className={styles.divider}/>

								<LinkButton
									cx={styles["link-button"]}
									onClick={this.onReportingUnLock}
									caption="Unlock"
									rawProps={{
										"data-name": toDataAttribute("Unlock reporting button"),
									}}
								/>
							</>
						)
						: null
				}

				{
					(
						project
						&& (
							project.hasBillingLockPermission
							|| (
								project.hasReportingLockPermission
								&& isBillingLocked
							)
						)
					)
						? (
							<>
								<Text cx={styles.title}>
									Billing:
								</Text>

								{
									project.hasBillingLockPermission
										? (
											<>
												<LinkButton
													cx={styles["link-button"]}
													onClick={this.onLock}
													caption="Lock"
													rawProps={{
														"data-name": toDataAttribute("Lock billing button"),
													}}
												/>

												<div className={styles.divider}/>

												<LinkButton
													cx={styles["link-button"]}
													onClick={this.onUnlock}
													caption="Unlock"
													rawProps={{
														"data-name": toDataAttribute("Unlock billing button"),
													}}
												/>
											</>
										)
										: null
								}

								{
									(
										project.hasReportingLockPermission
										&& isBillingLocked
										&& !project.hasBillingLockPermission
									)
										? (
											<LinkButton
												caption="Request unlock"
												cx={styles["link-button"]}
												onClick={this.requestBillingUnlock}
												rawProps={{
													"data-name": toDataAttribute("Request billing unlock button"),
												}}
											/>
										)
										: null
								}
							</>
						)
						: null
				}
			</MultipleChoicePanel>
		);
	}
}

/**
 * @param {import("store").RootState} state
 */
const mapStateToProps = (state, ownProps) => {
	let hasStatusesForRemove = false;
	let hasMarkupActionsForApply = false;
	const {
		viewMode,
	} = ownProps.match.params;
	const project = state.projectsDashboard.projects[state.projectsDashboard.showDetailsProjectId];
	const employeesMultipleChoice = getArray(state, MULTIPLE_DASHBOARD_EMPLOYEES_NAME);

	const filteredEmployeesIdsArray = Object.keys(ownProps.filteredEmployees);
	const employees = employeesMultipleChoice.filter((employeeId) => {
		return filteredEmployeesIdsArray.includes(employeeId);
	});

	if (
		viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
		&& employees
		&& employees.length
		&& project
		&& project.employees
	) {
		employees.some((employeeId) => {
			const employee = project.employees[employeeId];

			if (
				employee
				&& employee.markupStatusesActions
				&& employee.markupStatusesActions[MARKUP_ACTION_TYPE.APPLY]
				&& employee.markupStatusesActions[MARKUP_ACTION_TYPE.APPLY].length
			) {
				hasMarkupActionsForApply = true;
			}

			return hasMarkupActionsForApply;
		});

		employees.some((employeeId) => {
			const employee = project.employees[employeeId];

			if (
				employee
				&& employee.markupStatusesActions
				&& employee.markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE]
				&& employee.markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE].length
				&& employee.markedWorklogInfo
				&& employee.markedWorklogInfo.length
			) {
				hasStatusesForRemove = employee.markedWorklogInfo.some((worklog) => {
					return employee.markupStatusesActions[MARKUP_ACTION_TYPE.REMOVE]
						.find((status) => {
							return status.id === worklog.statusId;
						});
				});
			}

			return hasStatusesForRemove;
		});
	}

	return {
		employees,
		project,
		hasStatusesForRemove,
		hasMarkupActionsForApply,
		mainUserFullName: getUserName(state),
	};
};

const mapDispatchToProps = {
	selectAllEmployeesToUnlock,
	deselectAllEmployeesToUnlock,
	updateProjects,
	setMarkupStatus,
	removeMarkupStatus,
	unlockBillingPeriod,
	lockReportingPeriod,
	requestBillingUnlock,
	unlockReportingPeriod,
	lockBillingByEmployee,
	hideSpinner,
	showSpinner,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeveralEmployeesLockPanel));
