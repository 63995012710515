/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	useLazyDataSource,
} from "@epam/uui-core";
import isNil from "lodash/isNil";
import isNull from "lodash/isNull";
import {
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	useParams,
} from "react-router-dom";

import {
	projectStructureApi,
} from "models/project-structure/api";
import {
	ProjectStructureUnitType,
} from "models/project-structure/constants";
import {
	type ProjectStructureUnit,
	type ProjectStructureUnitId,
} from "models/project-structure/types";
import {
	useDispatch,
} from "store";

import {
	INITIAL_DATA_SOURCE_STATE,
} from "../constants";
import {
	addUnitsToFavorites,
} from "../store/reducer";
import {
	type Filter,
	type NavigationDataSourceState,
	type UseNavigationDataParams,
	type UseNavigationDataResult,
	type UseProjectNavigationParams,
} from "../types";
import {
	addPostfixToUnitIds,
} from "../utils/add-postfix-to-unit-ids";
import {
	getFavoriteUnitIds,
} from "../utils/get-favorite-unit-ids";
import {
	removePostfixFromUnitId,
} from "../utils/remove-postfix-from-unit-id";

const useNavigationDataSearch = ({
	units,
	closeNavigationSidebar,
}: UseNavigationDataParams): UseNavigationDataResult => {
	const rootUnits = useMemo(
		() => {
			return addPostfixToUnitIds({
				units,
			});
		},
		[
			units,
		],
	);

	const {
		fromDate: periodStartDate,
		toDate: periodEndDate,
	} = useParams<UseProjectNavigationParams>();

	const [
		dataSourceState,
		setDataSourceState,
	] = useState<NavigationDataSourceState>(INITIAL_DATA_SOURCE_STATE);

	useEffect(
		() => {
			setDataSourceState(INITIAL_DATA_SOURCE_STATE);
		},
		[
			periodStartDate,
			periodEndDate,
		],
	);

	const dispatch = useDispatch();

	const dataSource = useLazyDataSource<
		ProjectStructureUnit,
		ProjectStructureUnitId,
		Filter
	>(
		{
			api: async (request, context) => {
				const parentId = context?.parentId;

				try {
					if (!isNil(parentId)) {
						const getProjectStructureChildUnitsPromise = dispatch(
							projectStructureApi.endpoints.getProjectStructureChildUnits.initiate(
								{
									parentId: removePostfixFromUnitId(parentId),
									periodStartDate,
									periodEndDate,
								},
							),
						);

						const childUnits = await getProjectStructureChildUnitsPromise.unwrap();

						getProjectStructureChildUnitsPromise.unsubscribe();

						const modifiedChildUnits = addPostfixToUnitIds({
							units: childUnits,
							parentId,
						});

						const favoriteUnitIds = getFavoriteUnitIds(modifiedChildUnits);

						dispatch(addUnitsToFavorites(favoriteUnitIds));

						return {
							items: modifiedChildUnits,
							count: modifiedChildUnits.length,
						};
					}

					const favoriteUnitIds = getFavoriteUnitIds(rootUnits);

					dispatch(addUnitsToFavorites(favoriteUnitIds));

					return {
						items: rootUnits,
						count: rootUnits.length,
					};
				} catch {
					return {
						items: [],
						count: 0,
					};
				}
			},
			getChildCount: (unit) => {
				/*
					Projects don't have children,
					so there shouldn't be the expanding/collapsing functionality.
				*/
				return unit.type === ProjectStructureUnitType.PROJECT
					? 0
					: 1;
			},
			getId: (unit) => {
				return unit.unitId;
			},
			getParentId: (item) => {
				const {
					parentId,
				} = item;

				if (isNull(parentId)) {
					return undefined;
				}

				return parentId;
			},
		},
		[
			rootUnits,
			dispatch,
		],
	);

	useEffect(
		() => {
			return () => {
				dataSource.unsubscribeView(setDataSourceState);
			};
		},
		[
			dataSource,
		],
	);

	const view = dataSource.useView(
		dataSourceState,
		setDataSourceState,
		{
			getRowOptions: () => {
				return {
					onClick: (rowProps) => {
						if (rowProps.value?.type === ProjectStructureUnitType.PROJECT) {
							closeNavigationSidebar();
						}
					},
					checkbox: {
						isVisible: true,
					},
				};
			},
		},
	);

	return {
		view,
		dataSourceState,
		setDataSourceState,
	};
};

export {
	useNavigationDataSearch,
};
