/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	Button,
	Dropdown,
	DropdownContainer,
} from "@epam/loveship";

import SliderItem from "./slider-item/SliderItem";

import styles from "./StructureLevelSelector.module.css";

const StructureLevelSelector = ({
	items,
	onItemSelect,
	selectedItem,
}) => {
	return (
		<Dropdown
			renderTarget={(props) => {
				return (
					<Button
						{...props}
						color="night700"
						fill="white"
						cx={styles["level-selector"]}
						caption={
							selectedItem === 0
								? "1 level"
								: `1 to ${selectedItem + 1} levels`
						}
					/>
				);
			}}
			renderBody={(props) => {
				return (
					<DropdownContainer>
						{
							items.map((item, index) => {
								return (
									<SliderItem
										key={index}
										selectedIndex={selectedItem}
										caption={item}
										isLast={(items.length - 1) === index}
										onItemClick={onItemSelect}
										index={index}
										close={props.onClose}
									/>
								);
							})
						}
					</DropdownContainer>
				);
			}}
		/>
	);
};

export default StructureLevelSelector;
