/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	type DatePickerProps,
} from "@epam/uui";
import {
	endOfMonth,
	isAfter,
	isBefore,
	isEqual,
	parseISO,
	startOfMonth,
} from "date-fns";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import {
	type FC,
	useCallback,
	useState,
} from "react";

import {
	type DateString,
} from "models/dates-and-time/types";
import {
	toDateString,
} from "models/dates-and-time/utils/to-date-string";
import {
	toFancyDate,
} from "models/dates-and-time/utils/to-fancy-date";
import {
	PeriodPicker,
	PeriodPickerField,
	type PeriodPickerProps,
	type PeriodPickerValidationErrors,
} from "pages/components/period-picker/period-picker";

type PeriodToLock = PeriodPickerProps["value"];

interface GetLockPeriodErrorsParams {
	periodToLock: PeriodToLock;
	billingLockDate: Date;
	reportingLockDate: Date;
}

const getLockPeriodErrors = ({
	periodToLock,
	billingLockDate,
	reportingLockDate,
}: GetLockPeriodErrorsParams): PeriodPickerValidationErrors => {
	const {
		from: lockFromDateString,
		to: lockToDateString,
	} = periodToLock;
	const errors: PeriodPickerValidationErrors = [];

	if (isNull(lockFromDateString)) {
		errors.push({
			field: PeriodPickerField.FROM,
			message: "Date is required",
		});
	}

	if (isNull(lockToDateString)) {
		errors.push({
			field: PeriodPickerField.TO,
			message: "Date is required",
		});
	}

	if (
		!isNull(lockFromDateString)
		&& !isNull(lockToDateString)
	) {
		const lockToDate = parseISO(lockToDateString);

		if (isBefore(lockToDate, billingLockDate)) {
			errors.push({
				field: PeriodPickerField.TO,
				message: `Project is already locked to ${toFancyDate(billingLockDate)}`,
			});
		}

		if (
			isEqual(lockToDate, reportingLockDate)
			|| isAfter(lockToDate, reportingLockDate)
		) {
			errors.push({
				field: PeriodPickerField.TO,
				message: `Project can't be locked on or after ${toFancyDate(reportingLockDate)}`,
			});
		}
	}

	return errors;
};

const getPeriodPickerPlaceholder = (field: PeriodPickerField): string => {
	if (field === PeriodPickerField.FROM) {
		return "Start date";
	}

	return "End date";
};

interface UsePeriodPickerDataParams {
	selectedPeriodStartDateString: DateString;
	billingLockDate: Date;
	reportingLockDate: Date;
}

interface UsePeriodPickerDataResult {
	periodToLock: PeriodToLock;
	Component: FC;
	resetPeriodToLock: () => void;
	hasErrors: boolean;
}

const usePeriodPickerData = ({
	selectedPeriodStartDateString,
	billingLockDate,
	reportingLockDate,
}: UsePeriodPickerDataParams): UsePeriodPickerDataResult => {
	const selectedPeriodStartDate = parseISO(selectedPeriodStartDateString);
	const periodStartDate = startOfMonth(selectedPeriodStartDate);
	const periodEndDate = endOfMonth(selectedPeriodStartDate);
	const periodStartDateString = toDateString(periodStartDate);
	const periodEndDateString = toDateString(periodEndDate);

	const getPeriodToLock = useCallback(
		(): PeriodToLock => {
			return {
				from: periodStartDateString,
				to: periodEndDateString,
			};
		},
		[
			periodStartDateString,
			periodEndDateString,
		],
	);

	const [
		periodToLock,
		setPeriodToLock,
	] = useState<PeriodToLock>(() => {
		return getPeriodToLock();
	});

	const resetPeriodToLock = useCallback(
		() => {
			setPeriodToLock(getPeriodToLock());
		},
		[
			getPeriodToLock,
		],
	);

	const filterPeriodPickerDates = useCallback<NonNullable<DatePickerProps["filter"]>>(
		(dayJsDate) => {
			const dateToCheck = dayJsDate.toDate();

			return (
				!isBefore(dateToCheck, periodStartDate)
				&& !isAfter(dateToCheck, periodEndDate)
			);
		},
		[
			periodStartDate,
			periodEndDate,
		],
	);

	const errors = getLockPeriodErrors({
		periodToLock,
		billingLockDate,
		reportingLockDate,
	});

	const PeriodPickerComponent = useCallback<FC>(
		() => {
			return (
				<PeriodPicker
					value={periodToLock}
					onValueChange={setPeriodToLock}
					getPlaceholder={getPeriodPickerPlaceholder}
					filterDates={filterPeriodPickerDates}
					errors={errors}
				/>
			);
		},
		[
			periodToLock,
			filterPeriodPickerDates,
			errors,
		],
	);

	return {
		periodToLock,
		Component: PeriodPickerComponent,
		resetPeriodToLock,
		hasErrors: !isEmpty(errors),
	};
};

export {
	usePeriodPickerData,
};
