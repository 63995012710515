/*
 * Copyright © 2024 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	ReactComponent as LockIcon,
} from "@epam/assets/icons/common/notification-done_bold-18.svg";
import {
	useUuiContext,
} from "@epam/uui-core";
import {
	parseISO,
} from "date-fns";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import {
	type FC,
	useCallback,
} from "react";
import {
	useSelector,
} from "react-redux";
import {
	useParams,
} from "react-router-dom";

import {
	displayInfoNotification,
	displaySuccessNotification,
} from "components/common-components/notification/Notifications";
import {
	lockBilling,
	unlockBillingPeriod,
	unlockReportingPeriod,
	updateProjects,
} from "components/projects-billing/actionCreators";
import {
	checkOutPositionWorklogs,
} from "components/utils";
import {
	type DateString,
} from "models/dates-and-time/types";
import {
	toFancyDate,
} from "models/dates-and-time/utils/to-fancy-date";
import {
	type ProjectId,
	type ProjectName,
} from "models/projects/types";
import {
	PageActionsButton,
} from "pages/components/action-panel-components/page-actions-button/page-actions-button";
import {
	Menu,
} from "pages/components/menu/menu";
import {
	MenuSeparator,
} from "pages/components/menu/menu-separator";
import {
	MenuOption,
} from "pages/components/menu/option";
import {
	hideSpinner,
	showSpinner,
} from "pages/components/spinner/store/reducer";
import {
	type ProjectPageParams,
} from "pages/types/project-page";
import {
	type RootState,
	useDispatch,
} from "store";
import {
	useUserName,
} from "store/slices/application-info/selectors";
import {
	toDataAttribute,
} from "utils/to-data-attribute";

import {
	LockReportingModalWindow,
	type LockReportingModalWindowSuccessResult,
	LockType,
} from "../../project-summary-row/lock-reporting-modal-window/lock-reporting-modal-window";
import {
	createRequestBillingUnlock,
	onBillingLock,
	onBillingUnlock,
	onReportingUnlock,
} from "../../project-summary-row/three-dots-menu/utils";
import {
	withProjectDetails,
} from "../with-project-details/with-project-details";

const SELECTOR = "lock-menu";

interface EmployeesSummary {
	hasIncorrectWorklogs: unknown;
}

interface Project {
	name: ProjectName;
	hasReportingLockPermission: boolean;
	hasReportingPeriodUnlockPermission: boolean;
	hasBillingLockPermission: boolean;
	hasBillingUnlockPermission: boolean;
	hasRequestBillingUnlockPermission: boolean;
	employeesSummary: EmployeesSummary;
	reportingLimitDay: DateString;
	billingLockDay: DateString;
}

interface LockMenuProps {
	addProjectIdToReportingLockStatusWatcher: (projectId: ProjectId) => void;
}

const LockMenu: FC<LockMenuProps> = ({
	addProjectIdToReportingLockStatusWatcher,
}) => {
	const {
		projectId,
		fromDate,
		toDate,
	} = useParams<ProjectPageParams>();

	const {
		uuiModals,
	} = useUuiContext();

	const dispatch = useDispatch();

	const userName = useUserName();

	const isNewPermissionSchemeEnabled = useSelector((state: RootState) => {
		return state.applicationInfo.cpeBoard.projectsEmployeeDaily;
	});

	const project = useSelector((state: RootState) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		return state.projectsDashboard.projects[projectId] as Project;
	});

	const {
		hasReportingLockPermission,
		hasReportingPeriodUnlockPermission,
		hasBillingLockPermission,
		hasBillingUnlockPermission,
		hasRequestBillingUnlockPermission,
		employeesSummary,
		reportingLimitDay,
		billingLockDay,
	} = project;

	const setIsLoading = useCallback(
		(value: boolean) => {
			if (value) {
				dispatch(showSpinner());
			} else {
				dispatch(hideSpinner());
			}
		},
		[
			dispatch,
		],
	);

	const handleLockReporting = (): void => {
		checkOutPositionWorklogs(
			employeesSummary.hasIncorrectWorklogs,
			async () => {
				try {
					const {
						lockType,
						lockDate: lockDateString,
					} = await uuiModals.show<LockReportingModalWindowSuccessResult>((modalWindowProps) => {
						return (
							<LockReportingModalWindow
								projectId={projectId}
								periodStartDate={fromDate}
								periodEndDate={toDate}
								billingLockDate={billingLockDay}
								reportingLockDate={reportingLimitDay}
								isNewPermissionSchemeEnabled={isNewPermissionSchemeEnabled}
								{...modalWindowProps}
							/>
						);
					});

					if (lockType === LockType.AUTOMATED) {
						addProjectIdToReportingLockStatusWatcher(projectId);

						displayInfoNotification({
							message: "Validation process is being applied. Do not close the page.",
							notificationParams: {
								duration: "forever",
							},
						});
					} else if (!isUndefined(lockDateString)) {
						const lockDate = parseISO(lockDateString);
						const formattedLockDate = toFancyDate(lockDate);

						displaySuccessNotification(`The reporting lock has been put for all employees till ${formattedLockDate}.`);
					}

					window.dataLayer.push({
						event_name: "3dot_tab",
						event_action: "lock reporting",
						event: "autoevent",
					});
				} catch {}
			},
		);
	};

	const menuOptions: JSX.Element[] = [];

	if (hasReportingLockPermission) {
		menuOptions.push(
			<MenuOption
				key="Validate and lock"
				caption="Validate and lock"
				onClick={handleLockReporting}
			/>,
		);
	}

	if (hasReportingPeriodUnlockPermission) {
		menuOptions.push(
			<MenuOption
				key="Unlock reporting"
				caption="Unlock reporting"
				onClick={
					onReportingUnlock({
						project,
						fromDate,
						toDate,
						setIsLoading,
						// eslint-disable-next-line @typescript-eslint/promise-function-async
						unlockReportingPeriodAction: (...props: unknown[]) => {
							return dispatch(
								unlockReportingPeriod(
									isNewPermissionSchemeEnabled,
									// @ts-expect-error TODO: refine types.
									...props,
								),
							);
						},
						// eslint-disable-next-line @typescript-eslint/promise-function-async
						updateProjectsAction: (...props: unknown[]) => {
							return dispatch(
								updateProjects(
									isNewPermissionSchemeEnabled,
									// @ts-expect-error TODO: refine types.
									...props,
								),
							);
						},
						sendDataGA: () => {
							window.dataLayer.push({
								event_name: "3dot_tab",
								event_action: "unlock reporting",
								event: "autoevent",
							});
						},
					})
				}
			/>,
		);
	}

	if (
		hasBillingLockPermission
		|| hasBillingUnlockPermission
		|| hasRequestBillingUnlockPermission
	) {
		menuOptions.push(
			<MenuSeparator key="actions-separator"/>,
		);
	}

	if (hasBillingLockPermission) {
		menuOptions.push(
			<MenuOption
				key="Lock billing"
				caption="Lock billing"
				onClick={
					onBillingLock({
						project,
						summary: employeesSummary,
						fromDate,
						toDate,
						setIsLoading,
						lockBillingAction: (...props: unknown[]) => {
							return dispatch(
								lockBilling(
									isNewPermissionSchemeEnabled,
									...props,
								),
							);
						},
						sendDataGA: () => {
							window.dataLayer.push({
								event_name: "3dot_tab",
								event_action: "lock billing",
								event: "autoevent",
							});
						},
					})
				}
			/>,
		);
	}

	if (hasBillingUnlockPermission) {
		menuOptions.push(
			<MenuOption
				key="Unlock billing"
				caption="Unlock billing"
				onClick={
					onBillingUnlock({
						project,
						fromDate,
						toDate,
						setIsLoading,
						// eslint-disable-next-line @typescript-eslint/promise-function-async
						unlockBillingPeriodAction: (...props: unknown[]) => {
							return dispatch(
								unlockBillingPeriod(
									isNewPermissionSchemeEnabled,
									// @ts-expect-error TODO: refine types.
									...props,
								),
							);
						},
						sendDataGA: () => {
							window.dataLayer.push({
								event_name: "3dot_tab",
								event_action: "unlock billing",
								event: "autoevent",
							});
						},
					})
				}
			/>,
		);
	}

	if (hasRequestBillingUnlockPermission) {
		menuOptions.push(
			<MenuOption
				key="Request billing unlock"
				caption="Request billing unlock"
				onClick={
					createRequestBillingUnlock(
						project,
						userName,
						fromDate,
						toDate,
					)
				}
			/>,
		);
	}

	if (isEmpty(menuOptions)) {
		return null;
	}

	return (
		<Menu
			placement="bottom-end"
			rawProps={{
				"data-name": SELECTOR,
			}}
			renderOptions={() => {
				return menuOptions;
			}}
		>
			{(props) => {
				return (
					<PageActionsButton
						{...props}
						icon={LockIcon}
						rawProps={{
							"data-name": toDataAttribute(`${SELECTOR} button`),
						}}
					/>
				);
			}}
		</Menu>
	);
};

const LockMenuWithProjectDetails = withProjectDetails(LockMenu);

export {
	LockMenuWithProjectDetails as LockMenu,
};
