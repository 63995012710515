/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	getHasProjectEmployeesFilterBeenChanged,
	getHasProjectPositionsFilterBeenChanged,
	getProjectEmployeesFilterValuesFromLocalStorage,
	getProjectPositionsFilterValuesFromLocalStorage,
	getProjectsCommonFilterValuesFromLocalStorage,
} from "components/projects-billing/filter-utils";
import {
	API_PREFIX,
} from "constants/api";
import {
	PROJECT_VIEW_MODE,
} from "constants/projects";
import {
	svc,
} from "constants/services";
import {
	formatChooseDate,
	formatLockDate,
	getMaxDate,
	isDateAfter,
	isDateBefore,
	isEqualOrAfter,
	isEqualOrBefore,
} from "models/dates-and-time/utils/common";
import {
	getArrayForQuery,
} from "utils/api";
import {
	createEmailAndOpenNativeEmailClient,
	generateEmailFromFullName,
} from "utils/email";

import {
	PROJECT_BILLING_TYPE_IDS,
} from "../../../../../environment/consts";
import ChooseDate from "../../../../common-components/choose-date-period/ChooseDate";
import ChoosePeriod from "../../../../common-components/choose-date-period/ChoosePeriod";
import {
	showMarkupActionsModal,
} from "../../../../common-components/markups-actions/showMarkupActionsModal";
import {
	displayErrorNotification,
	displaySuccessNotification,
} from "../../../../common-components/notification/Notifications";
import {
	MARKUP_APPLIED_LEVEL,
	MARKUP_PERIOD_TYPE,
} from "../../../../consts";
import {
	checkOutPositionWorklogs,
} from "../../../../utils";
import {
	checkDateErrors,
} from "../../../utils";
import ExcelParamModals from "../../excel-params-modal/ExcelParamModals";
import {
	LOCK_TYPE,
} from "./constants";

const generateExcel = ({
	baseUrl,
	showPeriod,
	project,
	fromDate,
	toDate,
	viewMode,
	sendDataGA,
}) => {
	return () => {
		const {
			id: projectId,
		} = project;

		const {
			showWithNpa,
			showWithVacation,
			showWithExcludedHours,
		} = getProjectsCommonFilterValuesFromLocalStorage();
		const isEmployeeDailyView = (
			viewMode === PROJECT_VIEW_MODE.EMPLOYEE_DAILY
			|| viewMode === PROJECT_VIEW_MODE.POSITION_BREAKDOWN
		);

		let hasFilterBeenChanged = false;

		if (isEmployeeDailyView) {
			const filterValues = getProjectEmployeesFilterValuesFromLocalStorage(projectId);

			hasFilterBeenChanged = getHasProjectEmployeesFilterBeenChanged(filterValues);
		} else {
			const filterValues = getProjectPositionsFilterValuesFromLocalStorage(projectId);

			hasFilterBeenChanged = getHasProjectPositionsFilterBeenChanged(filterValues);
		}

		svc.uuiModals.show((props) => {
			return (
				<ExcelParamModals
					{...props}
					toDate={toDate}
					viewMode={viewMode}
					fromDate={fromDate}
					actionTitle="Export"
					showPeriod={showPeriod}
					filterVariantsExixts={true}
					filterChanged={hasFilterBeenChanged}
					header="Export data to Excel"
					checkDateWarnings={() => {
						return [];
					}}
					checkDateErrors={checkDateErrors}
					subheader={`for project ${project.name} `}
				/>
			);
		})
			.then((data) => {
				if (data) {
					let withFilter;
					let params;

					if (
						!hasFilterBeenChanged
						|| !data.withFilter
						|| isDateBefore({
							date: data.fromDate,
							dateToCompare: fromDate,
						})
						|| isDateAfter({
							date: data.fromDate,
							dateToCompare: toDate,
						})
						|| isDateBefore({
							date: data.toDate,
							dateToCompare: fromDate,
						})
						|| isDateAfter({
							date: data.toDate,
							dateToCompare: toDate,
						})
					) {
						withFilter = false;

						params = [];
					} else {
						withFilter = true;

						params = isEmployeeDailyView
							? project.filteredEmployees
							: project.filteredPositions;
					}

					const paramName = isEmployeeDailyView
						? "employeeUid"
						: "positionId";

					const optionalParam = params.length
						? `${paramName}=${getArrayForQuery(params)}`
						: "";

					const url = `${API_PREFIX}${baseUrl}?${optionalParam
					}&from=${data.fromDate}&to=${data.toDate}&withVacation=${showWithVacation}&withNpa=${showWithNpa
					}&withFilter=${withFilter}&withExcludedHours=${Boolean(showWithExcludedHours)}`;

					window.open(url, "_target");

					sendDataGA();
				}
			});
	};
};

const onMarkupStatusProject = (action, project, fromDate, toDate, setIsLoading, actionCallback) => {
	return () => {
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		return showMarkupActionsModal({
			action,
			id: project.id,
			markupLevel: MARKUP_APPLIED_LEVEL.PROJECT,
			filterDates: {
				from: fromDate,
				to: toDate,
			},
			statuses: project.markupStatusesActions[action],
			callback: ({
				statusId,
				from,
				to,
				id,
			}) => {
				return actionCallback(
					MARKUP_APPLIED_LEVEL.PROJECT,
					[
						id,
					],
					[],
					statusId,
					MARKUP_PERIOD_TYPE.PERIOD,
					from,
					to,
					fromDate,
					toDate,
					showWithNpa,
					showWithVacation,
				);
			},
		});
	};
};

const getReportingAndBillingLocksType = (summary) => {
	let billingLockType = LOCK_TYPE.UNLOCKED;
	let reportingLockType = LOCK_TYPE.UNLOCKED;

	if (summary.hasFullBillingLock) {
		billingLockType = LOCK_TYPE.LOCKED;
	} else if (summary.hasBillingLock) {
		billingLockType = LOCK_TYPE.PARTIALY_LOCKED;
	}

	if (summary.hasFullReportingLock) {
		reportingLockType = LOCK_TYPE.LOCKED;
	} else if (summary.hasReportingLock) {
		reportingLockType = LOCK_TYPE.PARTIALY_LOCKED;
	}

	return {
		billingLockType,
		reportingLockType,
	};
};

const checkDateErrorsReporting = (project) => {
	return (fromDate, toDate) => {
		const res = {
			fromDateHasError: false,
			toDateHasError: false,
			descriptions: [],
		};

		if (!fromDate) {
			res.descriptions.push("Date from is required.");

			res.fromDateHasError = true;
		} else if (
			isEqualOrBefore({
				date: fromDate,
				dateToCompare: project.billingLockDay,
			})
		) {
			res.fromDateHasError = true;

			res.descriptions.push(`Date from should be greater than billing lock date ${formatLockDate(project.billingLockDay)}`);
		}

		if (!toDate) {
			res.toDateHasError = true;

			res.descriptions.push("Date to is required.");
		} else if (
			isEqualOrBefore({
				date: toDate,
				dateToCompare: project.billingLockDay,
			})
		) {
			res.toDateHasError = true;

			res.descriptions.push(`Date to should be greater than billing lock date ${formatLockDate(project.billingLockDay)}`);
		}

		if (
			fromDate
			&& toDate
			&& isDateAfter({
				date: fromDate,
				dateToCompare: toDate,
			})
		) {
			res.toDateHasError = true;

			res.fromDateHasError = true;

			res.descriptions.push("'To' date must be later than the 'From' date");
		}

		return res;
	};
};

const reportingCheckDate = (project) => {
	return (date) => {
		const res = [];

		if (!date) {
			res.push("Date is required.");
		} else {
			if (
				isDateAfter({
					date: project.billingLockDay,
					dateToCompare: date,
				})
			) {
				res.push(`Project is already locked to ${formatChooseDate(project.billingLockDay)}`);
			}

			if (
				isEqualOrBefore({
					date: project.reportingLimitDay,
					dateToCompare: date,
				})
			) {
				res.push(`Project can't be locked on or after ${formatChooseDate(project.reportingLimitDay)}`);
			}
		}

		return res;
	};
};

const onReportingLock = ({
	project,
	summary,
	fromDate,
	toDate,
	setIsLoading,
	lockReportingAction,
	sendDataGA,
}) => {
	return () => {
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		checkOutPositionWorklogs(summary.hasIncorrectWorklogs, () => {
			svc.uuiModals.show((props) => {
				return (
					<ChooseDate
						{...props}
						date={toDate}
						actionTitle="Lock"
						header="Lock reporting period"
						checkDate={reportingCheckDate(project)}
						subheader={`for all members in ${project.name}`}
					/>
				);
			})
				.then((data) => {
					if (
						data
						&& data.date
					) {
						setIsLoading(true);

						lockReportingAction(
							[
								project.id,
							],
							data.date,
							fromDate,
							toDate,
							showWithNpa,
							showWithVacation,
						)
							.then((res) => {
								setIsLoading(false);

								if (
									res
									&& res.error
								) {
									displayErrorNotification(res.errorMessage);

									return;
								}

								displaySuccessNotification(`Project ${project.name} locked`);

								sendDataGA();
							});
					}
				});
		});
	};
};

const onReportingUnlock = ({
	project,
	fromDate,
	toDate,
	setIsLoading,
	unlockReportingPeriodAction,
	updateProjectsAction,
	sendDataGA,
}) => {
	return () => {
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		return svc.uuiModals.show((props) => {
			return (
				<ChoosePeriod
					{...props}
					toDate={toDate}
					fromDate={fromDate}
					actionTitle="Unlock"
					checkDateWarnings={() => {
						return [];
					}}
					header="Unlock reporting period"
					subheader={`for all employees in ${project.name}`}
					checkDateErrors={checkDateErrorsReporting(project)}
				/>
			);
		})
			.then((data) => {
				if (data) {
					setIsLoading(true);

					return unlockReportingPeriodAction(data.fromDate, data.toDate, project.id, Object.keys(project.employees));
				}

				return Promise.resolve();
			})
			.then((res) => {
				if (
					res
					&& !res.error
				) {
					updateProjectsAction(
						fromDate,
						toDate,
						[
							project.id,
						],
						showWithNpa,
						showWithVacation,
					)
						.then(() => {
							const message = `Period is unlocked for all employees from ${res.from} till ${res.to}`;

							setIsLoading(false);

							displaySuccessNotification(message);

							sendDataGA();
						});
				} else if (
					res
					&& res.error
				) {
					const message = res.errorMessage;

					setIsLoading(false);

					displayErrorNotification(message);
				}
			});
	};
};

const checkDateForBillingLock = (date, project) => {
	const res = [];

	if (!date) {
		res.push("Date is required.");
	} else {
		if (
			isDateAfter({
				date: project.billingPastLimitDay,
				dateToCompare: date,
			})
		) {
			res.push(`Red lock is already on ${formatLockDate(project.billingPastLimitDay)}`);
		}

		if (
			isEqualOrBefore({
				date: project.billingLimitDay,
				dateToCompare: date,
			})
		) {
			res.push(`Project can't be locked on or after ${formatLockDate(project.reportingLimitDay)}`);
		}
	}

	return res;
};

const onBillingLock = ({
	project,
	summary,
	fromDate,
	toDate,
	setIsLoading,
	lockBillingAction,
	sendDataGA,
}) => {
	return () => {
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		checkOutPositionWorklogs(summary.hasIncorrectWorklogs, () => {
			svc.uuiModals.show((props) => {
				return (
					<ChooseDate
						{...props}
						actionTitle="Lock"
						header="Lock billing period"
						checkDate={(date) => {
							return checkDateForBillingLock(date, project);
						}}
						date={
							formatLockDate(
								getMaxDate([
									toDate,
									project.billingLockDay,
								]),
							)
						}
						subheader={`for all members in ${project.name}`}
						tips={[
							`Project lock is set for ${formatLockDate(project.billingLockDay)}`,
							`Red lock is set for ${formatLockDate(project.billingPastLimitDay)}`,
						]}
					/>
				);
			})
				.then((data) => {
					if (
						data
						&& data.date
					) {
						setIsLoading(true);

						lockBillingAction(
							[
								project.id,
							],
							data.date,
							fromDate,
							toDate,
							showWithNpa,
							showWithVacation,
						)
							.then((res) => {
								setIsLoading(false);

								if (
									res
									&& res.error
								) {
									const message = res.errorMessage;

									displayErrorNotification(message);
								} else {
									const message = `Project ${project.name} locked`;

									displaySuccessNotification(message);

									sendDataGA();
								}
							});
					}
				});
		});
	};
};

const checkBillingDateErrors = (fromDate, toDate, project) => {
	const res = {
		fromDateHasError: false,
		toDateHasError: false,
		descriptions: [],
	};

	if (!fromDate) {
		res.descriptions.push("Date from is required.");

		res.fromDateHasError = true;
	} else if (
		isDateAfter({
			date: fromDate,
			dateToCompare: project.billingLockDay,
		})
	) {
		res.descriptions.push(`Date from can't be greater than billing lock date ${formatLockDate(project.billingLockDay)}`);

		res.fromDateHasError = true;
	}

	if (!toDate) {
		res.descriptions.push("Date to is required.");

		res.toDateHasError = true;
	} else if (
		isDateAfter({
			date: toDate,
			dateToCompare: project.billingLockDay,
		})
	) {
		res.descriptions.push(`Date to can't be greater than billing lock date ${formatLockDate(project.billingLockDay)}`);

		res.toDateHasError = true;
	}

	if (
		fromDate
		&& toDate
		&& isDateAfter({
			date: fromDate,
			dateToCompare: toDate,
		})
	) {
		res.descriptions.push("'To' date must be later than the 'From' date");

		res.fromDateHasError = true;

		res.toDateHasError = true;
	}

	return res;
};

const checkBillingDateWarnings = (fromDate, toDate, project) => {
	const res = [];

	if (
		fromDate
		&& isDateBefore({
			date: fromDate,
			dateToCompare: project.billingPastLimitDay,
		})
	) {
		res.push(`Unlocked periods from ${formatLockDate(fromDate)} to ${
			isDateBefore({
				date: toDate,
				dateToCompare: project.billingPastLimitDay,
			})
				? formatLockDate(toDate)
				: formatLockDate(project.billingPastLimitDay)
		} are automatically locked in 24 hrs`);
	}

	return res;
};

const onBillingUnlock = ({
	project,
	fromDate,
	toDate,
	setIsLoading,
	unlockBillingPeriodAction,
	sendDataGA,
}) => {
	return () => {
		const {
			showWithNpa,
			showWithVacation,
		} = getProjectsCommonFilterValuesFromLocalStorage();

		svc.uuiModals.show((props) => {
			return (
				<ChoosePeriod
					{...props}
					toDate={toDate}
					fromDate={fromDate}
					actionTitle="Unlock"
					header="Unlock billing period"
					checkDateErrors={(fromDate, toDate) => {
						return checkBillingDateErrors(fromDate, toDate, project);
					}}
					subheader={`for all members in ${project.name}`}
					checkDateWarnings={(fromDate, toDate) => {
						return checkBillingDateWarnings(fromDate, toDate, project);
					}}
				/>
			);
		})
			.then((data) => {
				if (data) {
					setIsLoading(true);

					unlockBillingPeriodAction(
						data.fromDate,
						data.toDate,
						project.id,
						null,
						fromDate,
						toDate,
						showWithNpa,
						showWithVacation,
					)
						.then((res) => {
							const message = `${res.unlockedEmployees} employee(s) unlocked in ${project.name}`;

							setIsLoading(false);

							displaySuccessNotification(message);

							sendDataGA();
						});
				}
			});
	};
};

const createRequestBillingUnlock = (project, mainUserFullName, fromDate, toDate) => {
	return () => {
		const isEpamCustomer = (
			Number(project.billingTypeId) === PROJECT_BILLING_TYPE_IDS.NON_BILLABLE
			|| Number(project.billingTypeId) === PROJECT_BILLING_TYPE_IDS.INTERNAL_PROJECT
		);

		const addressee = (
			isEpamCustomer
				? "BPS group"
				: "Business Partnering Service"
		);
		const tillDate = isEqualOrAfter({
			date: project.billingLockDay,
			dateToCompare: toDate,
		})
			? toDate
			: project.billingLockDay;

		createEmailAndOpenNativeEmailClient({
			to: project.billingUnlockRequestRecipient
				? project.billingUnlockRequestRecipient
				: isEpamCustomer
					? "WFAPLAnnotations@epam.com"
					: "WFAOTCBusinessPartnering@epam.com",
			cc: generateEmailFromFullName(mainUserFullName),
			subject: `Billing unlock required for ${project.name} project. Requested by ${mainUserFullName}`,
			body: (
				`Dear ${addressee}%2C%0D`
				+ "%0D"
				+ `Please unlock billing period for all employees on project ${project.name}.%0D`
				+ "%0D"
				+ "Unlock period:%0D"
				+ `From: ${fromDate}%0D`
				+ `To: ${tillDate}%0D`
				+ "Employees to be unlocked: all%0D"
				+ "%0D"
				+ "Reason: [Please provide short reason description]%0D"
				+ `Requested by: ${mainUserFullName}%0D`
				+ "%0D"
				+ "Thank you in advance!%0D"
			),
		});

		window.dataLayer.push({
			event_name: "3dot_tab",
			event_action: "request billing unlock",
			event: "autoevent",
		});
	};
};

export {
	generateExcel,
	onMarkupStatusProject,
	getReportingAndBillingLocksType,
	onReportingLock,
	onReportingUnlock,
	onBillingLock,
	onBillingUnlock,
	createRequestBillingUnlock,
};
