/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	connect,
} from "react-redux";

import Checker from "../../../common-components/multiple-choice/Checker";
import {
	getArray,
} from "../../../common-components/multiple-choice/utils";
import {
	MULTIPLE_DASHBOARD_EMPLOYEES_NAME,
} from "../../constants";
import {
	addEmployeeToUnlock,
	removeEmployeeToUnlock,
} from "./actionCreators";

const mapStateToProps = (state) => {
	return {
		arr: getArray(state, MULTIPLE_DASHBOARD_EMPLOYEES_NAME),
	};
};

const mapDispatchToProps = {
	add: addEmployeeToUnlock,
	remove: removeEmployeeToUnlock,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checker);
