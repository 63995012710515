/*
 * Copyright © 2022 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import split from "lodash/split";

import {
	type ProjectStructureUnitId,
} from "models/project-structure/types";

import {
	SEPARATOR_UNIT_ID,
} from "../constants";

const removePostfixFromUnitId = (
	unitIdWithPostfix: string,
): ProjectStructureUnitId => {
	const [
		unitId,
	] = split(unitIdWithPostfix, SEPARATOR_UNIT_ID);

	return unitId;
};

export {
	removePostfixFromUnitId,
};
