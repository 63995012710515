/*
 * Copyright © 2023 EPAM Systems, Inc. All Rights Reserved. All information contained herein is, and remains the
 * property of EPAM Systems, Inc. and/or its suppliers and is protected by international intellectual
 * property law. Dissemination of this information or reproduction of this material is strictly forbidden,
 * unless prior written permission is obtained from EPAM Systems, Inc
 */
import {
	IconContainer,
} from "@epam/loveship";

import {
	ReactComponent as BillingLockIcon,
} from "icons/billing-lock-16x21.svg";
import {
	ReactComponent as BillingUnlockIcon,
} from "icons/billing-unlock-21.svg";
import {
	ReactComponent as ReportingLockIcon,
} from "icons/reporting-lock-16x21.svg";
import {
	ReactComponent as ReportingUnlockIcon,
} from "icons/reporting-unlock-21.svg";

import styles from "./LockedNumber.module.css";

const getBillingStatus = ({
	employeesCount,
	hasBillingLock,
	hasProjectBillingLock,
	billingUnlockedCount,
}) => {
	const hasProjectAnyBillingLock = (
		hasBillingLock
		|| hasProjectBillingLock
	);

	if (
		employeesCount === billingUnlockedCount
		&& !hasProjectAnyBillingLock
	) {
		return;
	}

	return (
		<div
			data-name="billing-lock"
			className={styles.lockWrapper}
		>
			{
				billingUnlockedCount === 0
					? (
						<IconContainer
							icon={BillingLockIcon}
							cx={styles.lockIcon}
						/>
					)
					: (
						<>
							<span className={styles.lockText}>
								{billingUnlockedCount}
							</span>
							<IconContainer
								icon={BillingUnlockIcon}
								cx={styles.lockIcon}
							/>
						</>
					)
			}
		</div>
	);
};

const getReportingStatus = ({
	employeesCount,
	hasReportingLock,
	hasProjectReportingLock,
	reportingUnlockedCount,
}) => {
	const hasProjectAnyProjectReportingLock = (
		hasReportingLock
		|| hasProjectReportingLock
	);

	if (
		employeesCount === reportingUnlockedCount
		&& !hasProjectAnyProjectReportingLock
	) {
		return;
	}

	return (
		<div
			data-name="reporting-lock"
			className={styles.lockWrapper}
		>
			{
				reportingUnlockedCount === 0
					? (
						<IconContainer
							icon={ReportingLockIcon}
							cx={styles.lockIcon}
						/>
					)
					: (
						<>
							<span className={styles.lockText}>
								{reportingUnlockedCount}
							</span>
							<IconContainer
								icon={ReportingUnlockIcon}
								cx={styles.lockIcon}
							/>
						</>
					)
			}
		</div>
	);
};

const LockedNumber = ({
	employees,
	hasBillingLock,
	hasReportingLock,
	billingUnlockedCount,
	reportingUnlockedCount,
	hasProjectBillingLock,
	hasProjectReportingLock,
}) => {
	const employeesCount = Object.keys(employees)?.length;

	return (
		<div className={styles.lockedNumber}>
			{
				getBillingStatus({
					employeesCount,
					hasBillingLock,
					hasProjectBillingLock,
					billingUnlockedCount,
				})
			}
			{
				getReportingStatus({
					employeesCount,
					hasReportingLock,
					hasProjectReportingLock,
					reportingUnlockedCount,
				})
			}
		</div>
	);
};

export default LockedNumber;
